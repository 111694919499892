import axios from "../../service/api";
import { GET_ISSUE_LIST, GET_ISSUE_THERMOGRAPHY } from "@/store/action-types";
import { GET_ISSUE_LIST_LOCATIONS } from "@/store/action-types";
import { getMaxInterval } from "../../helpers/table/timeIntervalHelper";

import {
  FILTER_ISSUE,
  SET_ISSUES,
  SET_ISSUES_THERMOGRAPHY,
  SET_ISSUE_STATUS,
  SET_FILTER_ISSUES_LOCATIONS,
} from "../mutation-types";
import store from "@/store";
import * as parser from "../../helpers/IssueParser";
export default {
  namespaced: true,
  state: {
    initialIssuesNoAction: null,
    initialIssuesCompleted: null,
    issuesNoAction: null,
    issuesCompleted: null,
    issuesThermographyNoAction: null,
    issuesThermographyCompleted: null,
    status: null,
  },
  getters: {
    issuesNoAction: (state) => state.issuesNoAction,
    issuesCompleted: (state) => state.issuesCompleted,
    issuesThermographyNoAction: (state) => state.issuesThermographyNoAction,
    issuesThermographyCompleted: (state) => state.issuesThermographyCompleted,
    issueStatus: (state) => state.status,
  },
  actions: {
    [GET_ISSUE_THERMOGRAPHY]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      let timeInterval = getMaxInterval();
      let taskState = state.status;
      switch (state.status) {
        case "DASHBOARD":
          {
            taskState = "active";
          }
          break;
      }

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/location/${activeLocation.code}/ThermographyIssue/${timeInterval.dateFrom}/${timeInterval.dateTo}/${taskState}`
        )
        .then((data) => {
          data.data = parser.ParseThermographyIssue(data.data);
          commit(SET_ISSUES_THERMOGRAPHY, data.data);
        });
    },
    [GET_ISSUE_LIST]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];

      let taskState = state.status;
      let startTime = "";
      let endTime = "";
      switch (state.status) {
        case "DASHBOARD":
          {
            taskState = "NoAction";
            // let timeInterval = getDashboardIntervals("urgentIssues");
            let timeInterval = getMaxInterval();
            startTime = timeInterval.dateFrom;
            endTime = timeInterval.dateTo;
          }
          break;

        default: {
          let timeInterval = getMaxInterval();
          startTime = timeInterval.dateFrom;
          endTime = timeInterval.dateTo;
        }
      }
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/location/${activeLocation.code}/issues/${startTime}/${endTime}/${taskState}`
        )
        .then(({ data }) => {
          commit(SET_ISSUES, {
            data: data,
            status: state.status,
          });
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [GET_ISSUE_LIST_LOCATIONS]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];

      let taskState = state.status;
      let startTime = "";
      let endTime = "";
      switch (state.status) {
        case "DASHBOARD":
          {
            taskState = "NoAction";
            // let timeInterval = getDashboardIntervals("urgentIssues");
            let timeInterval = getMaxInterval();
            startTime = timeInterval.dateFrom;
            endTime = timeInterval.dateTo;
          }
          break;

        default: {
          let timeInterval = getMaxInterval();
          startTime = timeInterval.dateFrom;
          endTime = timeInterval.dateTo;
        }
      }
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/issues/${startTime}/${endTime}/${taskState}`,
          {
            locations: state.locations.map(({ code }) => code),
          },
          {} // config
        )
        .then(({ data }) => {
          commit(SET_ISSUES, {
            data: data,
            status: state.status,
          });
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
  },
  mutations: {
    [FILTER_ISSUE](state, payload) {
      if (state.status === "NoAction") {
        if (payload.filter === "all") {
          state.issuesNoAction = state.initialIssuesNoAction;
        }

        if (payload.filter === "thermo") {
          state.issuesNoAction = state.initialIssuesNoAction.filter((issue) => {
            return issue.IsThermography;
          });
        }
      }

      if (state.status === "Completed") {
        if (payload.filter === "all") {
          state.issuesCompleted = state.initialIssuesCompleted;
        }

        if (payload.filter === "thermo") {
          state.issuesCompleted = state.initialIssuesCompleted.filter(
            (issue) => {
              return issue.IsThermography;
            }
          );
        }
      }
    },
    [SET_ISSUES](state, payload) {
      if (payload.status === "NoAction" || payload.status === "DASHBOARD") {
        state.issuesNoAction = payload.data;
        state.initialIssuesNoAction = payload.data;
      }

      if (payload.status === "Completed") {
        state.issuesCompleted = payload.data;
        state.initialIssuesCompleted = payload.data;
      }
      state.status = payload.status;
    },
    [SET_ISSUES_THERMOGRAPHY](state, payload) {
      if (state.status === "NoAction" || state.status === "DASHBOARD") {
        state.issuesThermographyNoAction = payload;
      }

      if (state.status === "Completed") {
        state.issuesThermographyCompleted = payload;
      }
    },
    [SET_ISSUE_STATUS](state, payload) {
      state.status = payload;
    },
    [SET_FILTER_ISSUES_LOCATIONS](state, payload) {
      state.locations = payload;
    },
  },
};
