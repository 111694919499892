<template>
  <div class="navigation-footer-menu">
    <div class="navigation-footer-menu__overlay"></div>
    <div class="navigation-footer-menu__logo-container">
      <div class="navigation-footer-menu__logo"><Logo /></div>
    </div>
    <ul class="navigation-footer-menu__top-bar">
      <li class="navigation-footer-menu__select-language">
        <Select
          :defaultSelected="{ text: 'Dansk', value: 'da-dk' }"
          :secondary="true"
          :options="languageSelections"
          @input="handleSelectInput"
        />
      </li>
      <li><LabelLocation /></li>
    </ul>
    <NavigationSidebar :is-mobile="true" />
  </div>
</template>

<script>
import { languageOptions } from "../store/modules/dictionary";
import NavigationSidebar from "./NavigationSidebar.vue";
import Select from "./Select.vue";
import LabelLocation from "./LabelLocation.vue";
import Logo from "./Logo.vue";
import { createNamespacedHelpers } from "vuex";
const dictionary = createNamespacedHelpers("dictionary");

export default {
  name: "NavigationMenu",
  components: {
    NavigationSidebar,
    Select,
    LabelLocation,
    Logo,
  },
  computed: {
    languageSelections: function () {
      return languageOptions;
    },
  },
  methods: {
    ...dictionary.mapActions(["GET_DICTIONARY"]),
    handleSelectInput(option) {
      this.GET_DICTIONARY(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-footer-menu__overlay {
  background-color: var(--color-black);
}

.navigation-footer-menu {
  background-color: white;
  width: 100vw;
  max-height: calc(100vh - var(--footer--height));
  overflow-y: auto;
}

.navigation-footer-menu__logo-container {
  display: flex;
  justify-content: center;
  padding: 3.6rem 0 3.2rem 0;
}

.navigation-footer-menu__logo {
  width: 18.3rem;
}

.navigation-footer-menu__top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 4rem;
  background-color: var(--color-grey--light);
}

.navigation-footer-menu__select-language {
  width: 8rem;
}
</style>
