<template>
  <div>
    <!-- TABLE CONTAINER FOOTER DASHBOARD -->
    <div
      class="table-container__footer table-container__footer--dashboard"
      v-if="isDashboard"
    >
      <ModalDownloadReports
        v-if="tableType === 'ISSUE'"
        :tableType="tableType"
      />
      <div class="table-container__footer-right">
        <LabelPagination :array="tableData" :tablePageSize="tablePageSize" />
        <Link :arrow="true" :url="seeAllLink">{{
          getDictionaryEntry("tableContainer.viewAllLink")
        }}</Link>
      </div>
    </div>
    <!-- TABLE CONTAINER FOOTER NOT DASHBOARD -->
    <div class="table-container__footer" v-else>
      <div v-if="tableType != 'TASK'">
        <div class="table-container__footer-row">
          <div class="table-container__checkbox-container">
            <div class="table-container__checkbox">
              <Checkbox
                @change="handleSelectAll"
                v-model="selectAll"
                :checkboxId="'select-all-issues'"
              />
            </div>
            <div>{{ getDictionaryEntry("tableContainer.selectAll") }}</div>
          </div>
        </div>
        <div class="table-container__download">
          <div class="table-container__quantity">
            {{ selectedRows }}
            {{ getDictionaryEntry("tableContainer.installationsSelected") }}
          </div>
          <ModalDownloadReports
            :tableType="tableType"
            @resetEvent="resetControls"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDownloadReports from "./ModalDownloadReports.vue";
import Link from "./Link.vue";
import LabelPagination from "./LabelPagination.vue";
import Checkbox from "./Checkbox.vue";

export default {
  name: "TableContainerFooter",
  components: {
    ModalDownloadReports,
    LabelPagination,
    Link,
    Checkbox,
  },
  data() {
    return {
      selectAll: false,
      tmp: 0,
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
    tablePageSize: {
      type: Number,
      default: null,
    },
    isDashboard: {
      type: Boolean,
      default: true,
    },
    seeAllLink: {
      type: String,
      default: "\/",
    },
    selectedRows: {
      type: Number,
      default: null,
    },
  },
  methods: {
    handleSelectAll(e) {
      this.$emit("selectAll", e);
    },
    resetControls(variable) {
      this.selectAll = false;
      this.$emit("setRowcount", variable);
      this.$emit("de-SelectAll");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.table-container__footer {
  min-height: 8.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 var(--spacing-table--padding-x);
  border-top: var(--border--sm) solid var(--color-grey);

  &--dashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @include tablet-portrait {
    }
  }
}

.table-container__footer-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.table-container__footer-right {
  display: flex;

  @include tablet-portrait {
    display: none;
  }
}

.table-container__footer-right {
  & > *:first-child {
    margin-right: 2rem;
  }
}

.table-container__checkbox-container {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

.table-container__checkbox {
  margin-right: 1rem;
}

.table-container__download {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  @include tablet-portrait {
    justify-content: unset;
  }
}

.table-container__quantity {
  margin-right: 2rem;
}

.table-container__issues-total {
  font-weight: var(--font-weight--semibold);
}
</style>
