export const dateFrom = lastYear(new Date());

export const dateTo = nextYear(new Date());

function lastYear(date) {
  const d = date;
  d.setFullYear(d.getFullYear() - 2);

  return d;
}

function nextYear(date) {
  const d = date;
  d.setFullYear(d.getFullYear() + 1);

  return d;
}
