<template>
  <div v-if="issue.content" class="report-issue">
    <div class="report-issue__manchet">
      <div>
        <strong
          >{{ getDictionaryEntry("reportIssue.labelInfo.updatedBy") }}:</strong
        >
        {{ issue.content.updatedBy }}
      </div>
      <div>
        <strong
          >{{
            getDictionaryEntry("reportIssue.labelInfo.dateUpdated")
          }}:</strong
        >
        {{ formatDate(issue.content.updated) }}
      </div>
    </div>
    <div class="report-issue__info-list">
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.dateCreated")
        }}</template>
        <template #text>{{ formatDate(issue.content.created) }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.person")
        }}</template>
        <template #text>{{ issue.content.responsible }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueClass")
        }}</template>
        <template #text>{{ issue.content.type }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.tabletNumber")
        }}</template>
        <template #text>{{ issue.content.number }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.tabletType")
        }}</template>
        <template #text>{{ issue.content.type }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueType")
        }}</template>
        <template #text>{{ issue.content.errorType }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueID")
        }}</template>
        <template #text>{{ issue.content.issueId }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.reference")
        }}</template>
        <template #text>{{ issue.content.reference }}</template>
      </LabelInfo>
    </div>
    <span style="margin: 1%"></span>
    <div>
      <LinkDownload
        :files="files"
        :delete-file-button="true"
        @delete-file="handleDeleteFile"
      />
    </div>
    <span style="margin: 1%"></span>
    <div>
      <Button v-on:click="getReport" ref="GetReportBtn">
        {{ getDictionaryEntry("reportService.buttonDownloadReports") }}
      </Button>
    </div>
    <div v-if="issue.content.notes" class="report-issue__note-list">
      <strong>{{ getDictionaryEntry("reportIssue.labelNotes") }}:</strong>
      <div
        v-for="(note, index) in issue.content.notes"
        :key="index"
        class="report-issue__note"
      >
        {{ note.value }}
      </div>
    </div>

    <div class="report-issue__status">
      <div class="report-issue__status-label">
        <strong>{{ getDictionaryEntry("reportIssue.labelStatus") }}:</strong>
      </div>

      <div class="report-issue__status-buttons">
        <div class="report-issue__status-select">
          <Select
            :default-selected="{
              text: issue.content.status,
              id: 'NoAction',
            }"
            :options="changeStatusOptions"
            @input="handleSelectedStatus"
          />
        </div>
        <ModalUploadFiles :is-report-issue="true" />
      </div>
    </div>

    <div class="report-issue__comment">
      <Textarea
        label="Kommentar/udbedret af"
        @handle-input="handleTextareaInput"
      />
    </div>
    <div class="report-issue__form-buttons">
      <div class="report-issue__submit">
        <Button :disabled="submitDisabled" @click="submitStatusUpdate">{{
          getDictionaryEntry("reportIssue.buttonStatusUpdate")
        }}</Button>
      </div>
      <Button :secondary="true" @click="toggleOverlay">{{
        getDictionaryEntry("reportIssue.buttonCancel")
      }}</Button>
    </div>
    <br /><br />
    <div
      v-if="issue.content.transactions"
      class="report-issue__transaction-list"
    >
      <h2 class="report-issue__history_header">
        {{ getDictionaryEntry("text.history") }}
      </h2>
      <div
        v-for="(transaction, index) in issue.content.transactions"
        :key="'transaction_' + index"
        class="report-issue__transaction-item"
      >
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.date") }}
          </div>
          <div class="report-issue__transaction-item-text">
            {{ formatDate(new Date(transaction.date)) }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.person") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.person }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.status") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.statusPhrase }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.comment") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import LabelInfo from "./LabelInfo.vue";
import LinkDownload from "./LinkDownload.vue";
import Select from "./Select.vue";
import ModalUploadFiles from "./ModalUploadFiles.vue";
import { dateDanishFormat } from "@/helpers/dateFormatter";
import Textarea from "./Textarea.vue";
import { createNamespacedHelpers } from "vuex";
const report = createNamespacedHelpers("report");
const overlay = createNamespacedHelpers("overlay");
const issue = createNamespacedHelpers("issue");

export default {
  name: "ReportIssue",
  components: {
    Button,
    LabelInfo,
    LinkDownload,
    ModalUploadFiles,
    Select,
    Textarea,
  },
  data() {
    return {
      comment: "",
      selectedStatus: null,
      updating: false,
    };
  },
  computed: {
    ...overlay.mapGetters({ issue: "activeOverlay" }),
    ...issue.mapGetters(["issueStatus"]),
    files: function () {
      return this.issue.content.files;
    },
    submitDisabled: function () {
      if (this.comment.length < 15) return true;
      if (this.updating === true) return true;

      return this.comment === ""; // or upload underway
    },
    changeStatusOptions: function () {
      return [
        {
          text: this.getDictionaryEntry(
            "reportIssue.selectStatus.errorIncident"
          ),
          id: "Error",
        },
        {
          text: this.getDictionaryEntry("reportIssue.selectStatus.noIncident"),
          id: "NoAction",
        },
        {
          text: this.getDictionaryEntry("reportIssue.selectStatus.completed"),
          id: "Completed",
        },
      ];
    },
  },
  mounted() {
    this.defaultStatus = this.issue.content.status;
    var id = "NoAction";
    switch (this.issue.content.status) {
      case "Fejl":
        id = "Error";
        break;
      case "Afluttet":
        id = "Completed";
        break;
      default:
        id = "NoAction";
    }
    this.selectedStatus.id = id;
  },
  methods: {
    ...report.mapActions([
      "UPDATE_ISSUE_REPORT_STATUS",
      "GET_ISSUE_REPORT",
      "GET_ISSUE_REPORT_PDF",
      "DELETE_ISSUE_REPORT_FILE",
    ]),
    ...issue.mapActions(["GET_ISSUE_LIST"]),
    formatDate(date) {
      return dateDanishFormat(date);
    },
    async getReport() {
      this.btnText = "W";
      try {
        await this.GET_ISSUE_REPORT_PDF();
      } catch (err) {
        console.error(err);
      }
      this.btnText = "Done";
      this.tt = "DAC";
    },
    handleDeleteFile(file, index) {
      this.files.splice(index, 1);

      this.DELETE_ISSUE_REPORT_FILE({
        file: file,
        issueId: this.issue.content.issueId,
      }).then(() => {
        this.GET_ISSUE_REPORT({
          issueId: this.issue.content.issueId,
          installationReferenceCode:
            this.issue.content.installationReferenceCode,
          type: "issue",
        });
      });
    },
    handleTextareaInput(input) {
      this.comment = input;
    },
    handleSelectedStatus(status) {
      this.selectedStatus = status;
    },
    submitStatusUpdate() {
      this.updating = true;
      this.UPDATE_ISSUE_REPORT_STATUS({
        issueId: this.issue.content.issueId,
        installationReferenceCode: this.issue.content.installationReferencecode,
        comment: this.comment,
        status: this.selectedStatus.id,
      })
        .then(() => {
          this.GET_ISSUE_LIST();
        })
        .then(() => {
          this.GET_ISSUE_REPORT({
            issueId: this.issue.content.issueId,
            installationReferenceCode:
              this.issue.content.installationReferenceCode,
            type: "issue",
          });
          this.updating = false;
        });
    },
    toggleOverlay() {
      this.files = null;
      this.$store.dispatch("toggleOverlay");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.report-issue {
  display: flex;
  flex-direction: column;
}

.report-issue__manchet {
  display: flex;
  font-size: 1.3rem;
  margin-top: 1rem;

  & > *:first-child {
    margin-right: 1rem;
  }
}

.report-issue__unit {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.report-issue__info-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 3rem;
  padding: 3rem 0;
  border-top: var(--border--sm) solid var(--color-grey);
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__note-list {
  padding: 1.6rem 0;
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__status {
  padding: 3rem 0;
}

.report-issue__status-label {
  margin-bottom: 1.2rem;
}

.report-issue__status-buttons {
  display: flex;
  flex-direction: column;

  @include tablet-portrait {
    flex-direction: row;
  }
}

.report-issue__status-select {
  margin-right: 0.8rem;
  min-width: 16rem;
  margin-bottom: 2rem;

  @include tablet-portrait {
    margin-bottom: 0;
  }
}

.report-issue__uploaded-files {
  padding-bottom: 3rem;
}

.report-issue_uploaded-file-list {
  text-decoration: none;
}

.report-issue_uploaded-file {
  list-style: none;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.report-issue__transaction-list {
  width: 100%;
  display: none;
  flex-direction: column;
  border-bottom: var(--border-divider);

  @include tablet-portrait {
    display: flex;
  }
}

.report-issue__transaction-item {
  display: block;
  flex-direction: row;
  padding: 1.6rem 0;
}

.report-issue__item-field {
  display: flex;
  margin-right: 1.6rem;

  &:last-child {
    margin-right: 0;
  }
}

.report-issue__transaction-item-label {
  font-weight: var(--font-weight--semibold);
  margin-right: 0.2rem;
}

.report-issue__comment {
  padding-bottom: 3rem;
}
.report-issue__history_header {
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__form-buttons {
  display: flex;
  margin-top: 3rem;
}

.report-issue__submit {
  margin-right: 0.7rem;
}
</style>
