import { SET_TIME_INTERVAL } from "../../mutation-types";

export default {
  namespaced: true,
  state: {
    timeInterval: null,
    isTimeFilterSet: false,
  },
  getters: {
    getTimeInterval: (state) => {
      if (state.timeInterval == null || state.isTimeFilterSet == false) {
        let endDate = new Date();
        let startDate = new Date("1900-01-01");

        endDate.setMonth(endDate.getMonth() + 1);
        return {
          dateFrom: startDate.toISOString().slice(0, 10),
          dateTo: endDate.toISOString().slice(0, 10),
        };
      }
      return state.timeInterval;
    },
    isTimeFilterSet: (state) => state.isTimeFilterSet,
  },
  mutations: {
    [SET_TIME_INTERVAL](state, payload) {
      state.timeInterval = payload;
      state.isTimeFilterSet = true;
    },
  },
};
