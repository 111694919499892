import axios from "../../../service/api";
import store from "@/store";

export const installationRowModel = {
  rowModelType: "infinite",
  datasource: {
    rowCount: undefined,
    getRows: (params) => {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const searchState = store.getters["table/searchField"];

      var sortCol = "";
      var sortDir = "";
      var searchStr = "";
      var typeStr = store.getters["table/selectFilter"];
      if (searchState !== null) {
        searchStr = searchState;
      }

      if (params.sortModel.length > 0) {
        sortCol = params.sortModel[0].colId;
        sortDir = params.sortModel[0].sort;
      }

      var take = params.endRow - params.startRow;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/installation/${customerId}/location/${activeLocation.code}/installations/query?skip=${params.startRow}&take=${take}&sortCol=${sortCol}&sortDir=${sortDir}&nameSearch=${searchStr}&typeFilter=${typeStr}`
        )
        .then((response) => {
          store.commit("table/SET_FILTER_COLLECTION", response.data.types);
          var lastRowIndex = response.data.total;
          params.successCallback(response.data.installations, lastRowIndex);
        })
        .catch((error) => {
          console.log(error);
          params.failCallback();
        });
    },
  },
};
