<template>
  <div class="link" @click="openNews">
    {{ getDictionaryEntry("table.newsFeed.read") }}
    <div class="link__arrow">
      <IconArrow />
    </div>
  </div>
</template>

<script>
import IconArrow from "../icons/IconArrow.vue";
import { createNamespacedHelpers } from "vuex";

const overlay = createNamespacedHelpers("overlay");
const news = createNamespacedHelpers("newsfeed");

export default {
  name: "GoToNewsButton",
  components: {
    IconArrow,
  },
  data() {
    return {
      newsId: this.params.data.id,
      body: this.params.data.body,
    };
  },
  methods: {
    ...overlay.mapMutations(["SET_ACTIVE_OVERLAY"]),
    ...news.mapMutations(["SET_ACTIVE_NEWSFEED_ID"]),

    openNews() {
      // todo: open slider
      this.SET_ACTIVE_NEWSFEED_ID(this.newsId);
      this.$store.dispatch("toggleOverlay", "news");
      this.SET_ACTIVE_OVERLAY({
        type: "news",
        content: this.body,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-cbre-green);
  font-weight: var(--font-weight--semibold);
  text-decoration: none;

  &:hover {
    color: var(--color-cbre-green);
    cursor: pointer;
  }

  &--arrow {
    padding-right: 2.2rem;
  }
}

.link__arrow {
  position: absolute;
  right: 0;
  width: 1.6rem;
  transition: right 0.5s ease-out;

  .link:hover & {
    right: -0.3rem;
  }
}
</style>
