<template>
  <div class="report-control" v-if="control.content">
    <div class="report-control__unit"></div>
    <div class="report-control__info-list">
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportControl.labelInfo.type")
        }}</template>
        <template v-slot:text>{{ control.content.installationType }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.installationNumber")
        }}</template>
        <template v-slot:text>{{ control.content.installationId }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.Amount")
        }}</template>
        <template v-slot:text>{{ control.content.items.length }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.name")
        }}</template>
        <template v-slot:text>{{ control.content.installationName }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportControl.labelInfo.company")
        }}</template>
        <template v-slot:text>{{ control.content.company }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportControl.labelInfo.department")
        }}</template>
        <template v-slot:text>{{ control.content.department }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportControl.labelInfo.technician")
        }}</template>
        <template v-slot:text>{{ control.content.responsible }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportControl.labelInfo.date")
        }}</template>
        <template v-slot:text>{{ date }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.reference")
        }}</template>
        <template #text>{{ control.content.locationName }}</template>
      </LabelInfo>
    </div>

    <div class="report-service__report-button">
      <div class="download__spinner" style="border 1px solid">
        <Spinner :size="5" v-if="btnText === 'W'" />
      </div>
      <Button v-on:click="getControlReport" ref="GetReportBtn"
        >{{ getDictionaryEntry("reportService.buttonDownloadReports") }}
      </Button>
    </div>

    <div class="report-control__table">
      <TableSimple
        :tableData="control.content.items"
        :tableType="'REPORT_CONTROL_CHECKLIST'"
      />
    </div>
    <div class="report-service__report-button">
      <div class="download__spinner" style="border 1px solid;">
        <Spinner :size="5" v-if="btnText === 'W'" />
      </div>

      <Button
        v-on:click="getControlReport"
        ref="GetReportBtn"
        style="margin-top: 1rem"
        >{{ getDictionaryEntry("reportService.buttonDownloadReports") }}
      </Button>
    </div>
  </div>
</template>

<script>
import LabelInfo from "./LabelInfo.vue";
import TableSimple from "./TableSimple.vue";
//import CheckList from "./CheckList.vue";
import { dateDanishFormat } from "../helpers/dateFormatter";
import Button from "./Button.vue";
import Spinner from "./Spinner.vue";
import { createNamespacedHelpers } from "vuex";
const report = createNamespacedHelpers("report");
const overlay = createNamespacedHelpers("overlay");

export default {
  name: "ReportControl",
  components: {
    LabelInfo,
    TableSimple,
    Button,
    //CheckList,
    Spinner,
  },
  data() {
    return {
      btnText: "",
    };
  },
  computed: {
    ...overlay.mapGetters({ control: "activeOverlay" }),
    date: function () {
      return dateDanishFormat(this.control.content.date);
    },
  },
  methods: {
    ...report.mapActions(["GET_CONTROL_REPORT_FILES"]),
    async getControlReport() {
      this.btnText = "W";
      await this.GET_CONTROL_REPORT_FILES({
        installationReferenceCode:
          this.control.content.installationReferenceCode,
        controlSchemaFile: this.control.content.reportFile,
        installationName: this.control.content.installationName,
        locationName: this.control.content.locationName,
        installationType: this.control.content.installationType,
      });
      this.btnText = "Done";
    },
  },
};
</script>

<style lang="scss" scoped>
.report-control {
  display: flex;
  flex-direction: column;
}

.report-control__unit {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.report-control__info-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 3rem;
  padding: 3rem 0;
  border-top: var(--border--sm) solid var(--color-grey);
}

.report-control__table {
  margin-top: 3rem;

  & div {
    font-size: 1.3rem;
  }
}
</style>
