import axios from "axios";

// errorComposer will compose a handleGlobally function
const errorComposer = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 404) {
      console.error(
        "The requested resource does not exist or has been deleted"
      );
    } else if (statusCode === 401) {
      console.error(error);
      //window.location.href = "https://intego.dk/kundeportal/";
    } else {
      console.error(error);
    }
  };
};

axios.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorComposer(error);

  return Promise.reject(error);
});

export default axios;
