<template>
  <div class="page-top">
    <TransitionFade>
      <TableContainer
        v-if="tasksUrgent"
        :table-data="tasksUrgent"
        :table-type="'TASK'"
        :table-name="getDictionaryEntry('currentTasks.table.headerText')"
        :is-dashboard="false"
        :table-page-size="10"
      />
    </TransitionFade>
  </div>
</template>

<script>
import TableContainer from "../../components/TableContainer.vue";
import TransitionFade from "../../components/TransitionFade.vue";
import { createNamespacedHelpers } from "vuex";
const task = createNamespacedHelpers("task");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");

export default {
  name: "AktuelleFejl",
  components: {
    TableContainer,
    TransitionFade,
  },
  computed: {
    ...task.mapGetters(["tasksUrgent"]),
    ...location.mapGetters(["activeLocation"]),
    ...table.mapGetters(["getTimeInterval"]),
  },
  watch: {
    activeLocation: function () {
      this.SET_TASK_STATUS("URGENT");
      this.GET_TASK_LIST();
    },
    getTimeInterval: function () {
      this.GET_TASK_LIST();
    },
  },
  created() {
    if (this.activeLocation) {
      this.SET_TASK_STATUS("URGENT");
      this.GET_TASK_LIST();
    }
  },
  methods: {
    ...task.mapActions(["GET_TASK_LIST"]),
    ...task.mapMutations(["SET_TASK_STATUS"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.page-top {
  @include tablet-portrait {
    margin-top: 3rem;
  }
}
</style>
