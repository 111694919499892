import axios from "../../service/api";
import {
  GET_LOCATION_LIST,
  UPDATE_ACTIVE_LOCATION,
  GET_ACTIVE_LOCATION_DETAILS,
  GET_ACTIVE_LOCATION_FILES,
  DELETE_LOCATION_FILES,
  UPLOAD_ACTIVE_LOCATION_FILES,
} from "@/store/action-types";
import {
  SET_ACTIVE_LOCATION,
  SET_LOCATIONS,
  SET_ACTIVE_LOCATION_DETAILS,
  SET_ACTIVE_LOCATION_FILES,
} from "@/store/mutation-types";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    locations: null,
    activeLocation: null,
    activeLocationDetails: null,
    activeLocationFiles: null,
  },
  getters: {
    locations: (state) => state.locations,
    activeLocation: (state) => state.activeLocation,
    activeLocationDetails: (state) => state.activeLocationDetails,
    activeLocationFiles: (state) => state.activeLocationFiles,
  },
  actions: {
    [GET_LOCATION_LIST]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      return new Promise((resolve, reject) => {
        return axios
          .get(
            `${process.env.VUE_APP_API_URL}/location/${customerId}/locations`
          )
          .then(({ data }) => {
            if (localStorage.getItem("location") !== null) {
              let obj = data.find(
                (obj) => obj.code == localStorage.getItem("location")
              );
              if (obj === null || obj === undefined) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].children) {
                    for (var c = 0; c < data[i].children.length; c++) {
                      if (
                        data[i].children[c].code ==
                        localStorage.getItem("location")
                      ) {
                        obj = data[i].children[c];
                      }
                    }
                  }
                }
              }

              if (obj !== null && obj !== undefined) {
                commit(SET_ACTIVE_LOCATION, obj);
                state.activeLocation = obj;
              } else {
                commit(SET_ACTIVE_LOCATION, data[0]);
                state.activeLocation = data[0];
              }
            } else {
              commit(SET_ACTIVE_LOCATION, data[0]);
              state.activeLocation = data[0];
            }

            commit(SET_LOCATIONS, data);
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    [GET_ACTIVE_LOCATION_DETAILS]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];

      return new Promise((resolve, reject) => {
        return axios
          .get(
            `${process.env.VUE_APP_API_URL}/location/${customerId}/location/${state.activeLocation.code}`
          )
          .then(({ data }) => {
            commit(SET_ACTIVE_LOCATION_DETAILS, data);
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    [GET_ACTIVE_LOCATION_FILES]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/location/${customerId}/location/${state.activeLocation.code}/files`
        )
        .then(({ data }) => {
          commit(SET_ACTIVE_LOCATION_FILES, data);
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [DELETE_LOCATION_FILES]({ state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      return axios
        .delete(
          `${process.env.VUE_APP_API_URL}/location/${customerId}/location/${state.activeLocation.code}/deletefile/${payload.fileName}`
        )
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [UPLOAD_ACTIVE_LOCATION_FILES]({ commit, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const description = payload.comment;
      const fileList = payload.files;
      let formData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        formData.append("files", file, file.name);
      }
      if (description !== null) {
        let commentBlob = new Blob([description], {
          type: "text/plain",
        });
        formData.append("commentFile", commentBlob);
      }

      return axios
        .put(
          `${process.env.VUE_APP_API_URL}/location/${customerId}/location/${state.activeLocation.code}/uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(({ data }) => {
          commit(SET_ACTIVE_LOCATION_FILES, data);
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [UPDATE_ACTIVE_LOCATION]({ commit }, payload) {
      commit(SET_ACTIVE_LOCATION, payload);
    },
  },
  mutations: {
    [SET_ACTIVE_LOCATION](state, location) {
      state.activeLocation = location;
    },
    [SET_ACTIVE_LOCATION_DETAILS](state, details) {
      state.activeLocationDetails = details;
    },
    [SET_ACTIVE_LOCATION_FILES](state, files) {
      state.activeLocationFiles = files;
    },
    [SET_LOCATIONS](state, locations) {
      state.locations = locations;
    },
  },
};
