/* eslint-disable */

// Takes an array of thermographyissues and par
export function ParseThermographyIssue(issueArr) {
  var tablearray = [];
  for (var i = 0; i < issueArr.length; i++) {
    var entry = {};
    entry.created = issueArr[i].created.substring(0, 10); // dateformat becomes yyyy/mm/dd
    entry.issueId = issueArr[i].issueId;
    entry.responsible = issueArr[i].responsible;
    entry.responsibleInitials = issueArr[i].personInitials; // todo: make info mark that can be hovered, showing the full name
    entry.status = issueArr[i].status; // todo: run this through a dictionary.
    entry.note = issueArr[i].comment; // todo: this should be displayed by some kind of modal
    entry.fileUrl = issueArr[i].fileUrl;
    entry.fileName = issueArr[i].fileDisplayname;
    // customfields varies in length, so a for-loop is utilized.
    for (var j = 0; j < issueArr[i].customFields.length; j++) {
      if (issueArr[i].customFields[j].type === "Fejlklasse") {
        issueArr[i].customFields[j].value = issueArr[i].customFields[
          j
        ].value.replace(/\D/g, ""); // Remove the "fejlklasse" part in the value string
      }
      entry[issueArr[i].customFields[j].type.replace(/\s|\./g, "")] =
        issueArr[i].customFields[j].value;
    }

    tablearray.push(entry);
  }
  return tablearray;
}
