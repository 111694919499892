<template>
  <div
    class="table-simple border-fat"
    :class="{ 'table-simple__header-secondary': simpleHeader }"
  >
    <TableOverlaySwipe
      :class="{ 'table-overlay-swipe--disabled': overlaySwipeDisabled }"
    />
    <AgGridVue
      style="width: 100%"
      class="ag-theme-alpine"
      :grid-api="gridApi"
      :dom-layout="domLayout"
      :row-data="rowData"
      :default-col-def="defaultColDef"
      :column-defs="computedColumnDefs"
      :tooltip-show-delay="tooltipShowDelay"
      :suppress-row-click-selection="true"
      :row-selection="rowSelection"
      :suppress-scroll-on-new-data="true"
      :getRowHeight="getRowHeight"
      @body-scroll="handleScroll"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
    >
    </AgGridVue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import CheckList from "./CheckList.vue";
import CheckListService from "./CheckListService.vue";
import CustomToolTip from "./CustomToolTip.vue";
import LinkInstallationIssueCompleted from "./LinkInstallationIssueCompleted.vue";
import LinkInstallationIssueNoAction from "./LinkInstallationIssueNoAction.vue";
import LinkInstallationTaskCompleted from "./LinkInstallationTaskCompleted.vue";
import LinkInstallationTaskPlanned from "./LinkInstallationTaskPlanned.vue";
import TableOverlaySwipe from "./TableOverlaySwipe.vue";

export default {
  name: "TableSimple",
  components: {
    AgGridVue,
    TableOverlaySwipe,
    /* eslint-disable vue/no-unused-components */
    CheckList,
    CheckListService,
    CustomToolTip,
    LinkInstallationIssueCompleted,
    LinkInstallationIssueNoAction,
    LinkInstallationTaskCompleted,
    LinkInstallationTaskPlanned,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    columnDefs: {
      type: Array,
      default: () => [],
    },
    preSelectAll: {
      type: Boolean,
      default: false,
    },
    simpleHeader: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      domLayout: null,
      defaultColDef: {
        sortable: true,
        cellStyle: { fontSize: "13px" },
        wrapText: true,
        autoHeight: true,
      },
      rowData: null,
      gridApi: null,
      tooltipShowDelay: null,
      overlaySwipeDisabled: true,
      rowSelection: null,
    };
  },
  computed: {
    computedColumnDefs: function () {
      // Denifined in columnDefinitions.js
      return this[this.tableType];
    },
  },
  watch: {
    tableSearchQuery: function (query) {
      this.gridApi.setQuickFilter(query);
    },
    tableData: function (data) {
      this.rowData = data;
      this.gridApi.redrawRows();
    },
  },
  mounted() {
    const tableFullWidth = document.querySelector(
      ".ag-center-cols-container"
    ).clientWidth;
    const tableViewWidth = document.querySelector(
      ".ag-center-cols-viewport"
    ).clientWidth;

    if (tableFullWidth > tableViewWidth) {
      this.overlaySwipeDisabled = false;
    }
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.rowData = this.tableData;
    this.tooltipShowDelay = 500;
    this.rowSelection = "multiple";
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // returnvalue sets the row-height
    getRowHeight(params) {
      if (params.data.checklist !== undefined && params.data.checklist !== null)
        return 27 * params.data.checklist.length + 17; // 27 = rowheight for CheckList, 17 = padding top

      return params.node.rowHeight;
    },
    onSelectionChanged() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);

      this.$emit("setSelectedRows", selectedData);
    },
    valueGetterToolTip(params) {
      if (params.value) {
        return { value: params.value };
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;

      if (this.preSelectAll) {
        this.gridApi.forEachNode((node) => {
          node.setSelected(true);
        });
      }
    },
    handleScroll(e) {
      setTimeout(() => {
        if (e.direction === "horizontal" && e.left > 10) {
          this.overlaySwipeDisabled = true;
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.table-simple {
  width: 100%;
  position: relative;
  border-bottom: var(--border--sm) solid var(--color-grey);
}

@import "~/src/styles/custom-table.scss";
</style>
