<template>
  <div class="label-pagination">
    <span>{{ pageSize }}</span> {{ getDictionaryEntry("labelPagination") }}
    <span>{{ array.length }}</span>
  </div>
</template>

<script>
export default {
  name: "LabelPagination",
  props: {
    array: {
      type: Array,
      default: () => [],
    },
    tablePageSize: {
      type: Number,
      default: null,
    },
  },
  computed: {
    pageSize: function () {
      if (this.array.length < this.tablePageSize) {
        return this.array.length;
      } else {
        return this.tablePageSize;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
