<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 23 27.667"
  >
    <g transform="translate(-2)">
      <path
        d="M24,11.5c0,8.167-10.5,15.167-10.5,15.167S3,19.667,3,11.5a10.5,10.5,0,0,1,21,0Z"
        transform="translate(0 0)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <circle
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(10 8)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLocation",
};
</script>
