<template>
  <div class="table-container">
    <!-- TABLE CONTAINER HEADER -->
    <TableContainerHeader
      v-if="!isDashboard"
      :isSimpleTable="isSimpleTable"
      :table-data="tableData"
      :table-name="tableName"
      :table-type="tableType"
      :show-any-filters="showAnyFilters"
      @click-tab="handleClick"
    />

    <TableContainerHeaderDashboard
      v-if="isDashboard"
      :table-data="tableData"
      :table-name="tableName"
      :table-page-size="tablePageSize"
      :see-all-link="seeAllLink"
      :table-type="currentTableType"
      @click-tab="handleClick"
    />
    <!-- TABLE CONTAINER BODY -->
    <div class="table-container__body">
      <div v-if="tableData">
        <Table
          :is-dashboard="isDashboard"
          :table-data="tableData"
          :table-type="currentTableType"
          :table-page-size="tablePageSize"
          :table-name="tableName"
          ref="tableRef"
          @set-selected-rows="setSelectedRows"
          @reset-select-all="resetSelectAll"
        />
      </div>
      <div v-else>
        {{ getDictionaryEntry("tableContainer.missingData") }}
        {{ tableNameLowerCase }}
      </div>
    </div>
    <!-- TABLE CONTAINER FOOTER -->
    <div>
      <TableContainerFooter
        :table-data="tableData"
        :table-page-size="tablePageSize"
        :is-dashboard="isDashboard"
        :see-all-link="seeAllLink"
        :table-type="tableType"
        :selected-rows="selectedRows"
        @select-all="handleSelectAll"
        @setRowcount="setSelectedRows"
        @de-SelectAll="deSelectAll"
      />
    </div>
  </div>
</template>

<script>
import TableContainerHeader from "./TableContainerHeader";
import TableContainerHeaderDashboard from "./TableContainerHeaderDashboard";
import TableContainerFooter from "./TableContainerFooter.vue";
import Table from "./Table.vue";
import {
  ISSUE,
  ISSUE_THERMOGRAPHY,
  TASK,
} from "../mixins/columnDefinitions.js";

export default {
  name: "TableContainer",
  components: {
    TableContainerHeader,
    TableContainerHeaderDashboard,
    Table,
    TableContainerFooter,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
    tableName: {
      type: String,
      default: null,
    },
    tablePageSize: {
      type: Number,
      default: null,
    },
    isDashboard: {
      type: Boolean,
      default: true,
    },
    isSimpleTable: {
      type: Boolean,
      default: false,
    },
    seeAllLink: {
      type: String,
      default: "\/",
    },
    tableContainerFooter: {
      type: Boolean,
      default: true,
    },
    showAnyFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedRows: 0,
      currentTableType: this.tableType,
    };
  },
  computed: {
    tableNameLowerCase() {
      return this.tableName.toLowerCase();
    },
  },
  methods: {
    deSelectAll() {
      this.$refs.tableRef.deSelectAll();
    },
    handleSelectAll(e) {
      if (e.target.checked) {
        this.$refs.tableRef.selectAll();
      } else {
        this.$refs.tableRef.deSelectAll();
      }
    },
    handleClick(option) {
      if (option.id === "issue-tab-thermography") {
        this.currentTableType = ISSUE_THERMOGRAPHY;
      }

      if (option.id === "issue-tab-all") {
        this.currentTableType = ISSUE;
      }

      if (option.id === "task-tab-all") {
        this.currentTableType = TASK;
      }

      if (option.id === "task-tab-coming") {
        this.currentTableType = TASK;
      }

      if (option.id === "task-tab-overdue") {
        this.currentTableType = TASK;
      }
    },
    setSelectedRows(rows) {
      this.selectedRows = rows;
    },
    resetSelectAll() {
      this.selectAll = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.table-container {
  max-width: 135rem;
  background-color: var(--color-white);
}

.table-container__body {
  overflow: hidden;
  padding: 2.9rem var(--spacing-table--padding-x) 4rem;
}
</style>
