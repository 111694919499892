<template>
  <header class="header">
    <div class="header__logo" @click="$router.push('/')"><Logo /></div>
    <NavigationHeader />
  </header>
</template>

<script>
import Logo from "./Logo.vue";
import NavigationHeader from "./NavigationHeader.vue";

export default {
  name: "Header",
  components: {
    Logo,
    NavigationHeader,
  },
};
</script>

<style lang="css" scoped>
.header {
  height: var(--header--height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.8rem 0 2.8rem;
}

.header__logo:hover {
  cursor: pointer;
}
</style>
