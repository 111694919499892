<template>
  <div v-if="activeLocationDetails" class="location">
    <ModalDeleteFileLocation />
    <div class="location__info">
      <BlockSimple>
        <template #header>
          <h2 class="location__info-header-text">
            <span class="location__info-header-text--semibold">
              {{ getDictionaryEntry("location.locationInfo.header") }}
            </span>
            -
            {{ activeLocationDetails.name }}
          </h2>
        </template>
        <template #body>
          <ul>
            <li
              v-if="activeLocationDetails.streetName"
              class="location__info-item"
            >
              <div class="location__info-item-key">
                {{ getDictionaryEntry("location.locationInfo.address") }}:
              </div>
              <div class="location__info-item-value">
                {{ activeLocationDetails.streetName }}
              </div>
            </li>
            <li
              v-if="activeLocationDetails.zipCode"
              class="location__info-item"
            >
              <div class="location__info-item-key">
                {{ getDictionaryEntry("location.locationInfo.zipCode") }}:
              </div>
              <div class="location__info-item-value">
                {{ activeLocationDetails.zipCode }}
              </div>
            </li>
            <li v-if="activeLocationDetails.city" class="location__info-item">
              <div class="location__info-item-key">
                {{ getDictionaryEntry("location.locationInfo.city") }}:
              </div>
              <div class="location__info-item-value">
                {{ activeLocationDetails.city }}
              </div>
            </li>
            <li
              v-if="activeLocationDetails.contact"
              class="location__info-item"
            >
              <div class="location__info-item-key">
                {{ getDictionaryEntry("location.locationInfo.contact") }}:
              </div>
              <div class="location__info-item-value">
                {{ activeLocationDetails.contact }}
              </div>
            </li>
            <li
              v-if="activeLocationDetails.phoneNumber"
              class="location__info-item"
            >
              <div class="location__info-item-key">
                {{ getDictionaryEntry("location.locationInfo.phoneNumber") }}:
              </div>
              <div class="location__info-item-value">
                {{ activeLocationDetails.phoneNumber }}
              </div>
            </li>
          </ul>
        </template>
      </BlockSimple>
    </div>

    <div class="location__document">
      <BlockSimple>
        <template #header>
          <div class="location__document-header">
            <h2
              class="
                location__document-header-text
                location__document-header-text--semibold
              "
            >
              {{ getDictionaryEntry("location.locationDocuments.header") }}
            </h2>
            <ModalUploadFiles />
          </div>
        </template>
        <template #body>
          <div v-if="activeLocationFiles" class="location__document-files">
            <LocationFiles
              v-if="activeLocationFiles.length > 0"
              :files="activeLocationFiles"
            />
          </div>
        </template>
      </BlockSimple>
    </div>
  </div>
</template>

<script>
import ModalDeleteFileLocation from "../components/modals/confirms/ModalDeleteFileLocation.vue";
import BlockSimple from "../components/BlockSimple.vue";
import ModalUploadFiles from "../components/ModalUploadFiles.vue";
import LocationFiles from "../components/LocationFiles.vue";
import { createNamespacedHelpers } from "vuex";
const location = createNamespacedHelpers("location");

export default {
  name: "Lokation",
  components: {
    BlockSimple,
    LocationFiles,
    ModalUploadFiles,
    ModalDeleteFileLocation,
  },
  computed: {
    ...location.mapGetters([
      "activeLocation",
      "activeLocationDetails",
      "activeLocationFiles",
    ]),
  },
  watch: {
    activeLocation: function () {
      this.GET_ACTIVE_LOCATION_FILES();
      this.GET_ACTIVE_LOCATION_DETAILS();
    },
  },
  beforeMount() {
    if (this.activeLocation) {
      this.GET_ACTIVE_LOCATION_FILES();
      this.GET_ACTIVE_LOCATION_DETAILS();
    }
  },
  methods: {
    ...location.mapActions([
      "GET_ACTIVE_LOCATION_FILES",
      "GET_ACTIVE_LOCATION_DETAILS",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.location {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.location__document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location__info-header-text,
.location__document-header-text {
  font-size: --font-size--normal;
  font-weight: var(--font-weight--normal);

  &--semibold {
    font-weight: var(--font-weight--semibold);
  }
}

.location__info-list {
  padding: 3.3rem 2.5rem 10rem;
  background-color: var(--color-white);
}

.location__info-item {
  display: flex;
}

.location__info-item-key {
  width: 13rem;
  margin-right: 3.5rem;
  font-weight: var(--font-weight--semibold);
}

.location__info-item-key,
.location__info-item-value {
  padding: 1rem 0;
  font-size: --font-size--normal;
}

.location__document {
  margin-top: 3rem;
}

.location__document-files {
  background-color: var(--color-white);
  padding: 3rem 2.6rem;
}
</style>
