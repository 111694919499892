<template>
  <div class="customer-info">
    <h1 class="customer-info__name" v-if="activeLocationDetails">
      {{ getDictionaryEntry("customerInfo.title") }}
      {{ activeCustomer.name }}
    </h1>
    <transition name="fade">
      <p class="customer-info__org" v-if="activeLocation">
        {{ getDictionaryEntry("customerInfo.subTitle") }}
        <span>{{ activeLocation.name }} ({{ activeCustomer.xalId }})</span>
      </p>
    </transition>
    <p v-if="!activeLocation" class="customer-info__placeholder">
      {{ getDictionaryEntry("customerInfo.subTitle") }}
    </p>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authentication = createNamespacedHelpers("authentication");
const location = createNamespacedHelpers("location");

export default {
  name: "CustomerInfo",
  computed: {
    ...authentication.mapGetters(["activeCustomer"]),
    ...location.mapGetters(["activeLocation", "activeLocationDetails"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.customer-info {
  padding: 0 var(--spacing-mobile-padding-x);
  text-align: center;

  @include tablet-portrait {
    text-align: left;
    padding: 0;
  }
}

.customer-info__name {
  font-weight: var(--font-weight--semibold);
  margin-bottom: 0.6rem;
}

.customer-info__org {
  color: var(--font-secondary);
}

.customer-info__placeholder {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
