<template>
  <Modal :max-height="48" ref="modalRef">
    <template v-slot:header>
      <div>
        <Input
          :name="'search'"
          :placeholder="getDictionaryEntry('searchField.placeholder')"
          @input="handleSearchInput"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="location__list" v-if="locations">
        <div
          v-for="(location, index) in filteredLocations"
          v-bind:key="'location_' + index"
          class="location"
        >
          <div v-if="location.children?.length > 0">
            <Accordion>
              <AccordionItemLocation
                @location-click="handleLocationClick"
                :location="location"
              />
            </Accordion>
          </div>
          <div v-else>
            <LocationBlock
              :location="location"
              @location-click="handleLocationClick"
            />
          </div>
        </div>
      </div>
      <div class="location__spinner" v-else>
        <Spinner />
      </div>
    </template>
    <template v-slot:trigger>
      <slot name="trigger" />
    </template>
  </Modal>
</template>

<script>
import Accordion from "./Accordion.vue";
import AccordionItemLocation from "./AccordionItemLocation.vue";
import Modal from "./Modal.vue";
import Spinner from "./Spinner.vue";
import Input from "./Input.vue";
import LocationBlock from "../components/LocationBlock.vue";
import { createNamespacedHelpers } from "vuex";
const location = createNamespacedHelpers("location");
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "ModalLocation",
  components: {
    Accordion,
    AccordionItemLocation,
    Modal,
    LocationBlock,
    Spinner,
    Input,
  },
  data() {
    return {
      searchQuery: null,
    };
  },
  watch: {
    customerId: function (newId) {
      if (newId) {
        this.GET_LOCATION_LIST().then(() => {
          this.GET_ACTIVE_LOCATION_DETAILS();
        });
      }
    },
  },
  computed: {
    ...authentication.mapGetters(["customerId"]),
    ...location.mapGetters(["locations"]),
    filteredLocations: function () {
      let filteredList;
      if (this.searchQuery) {
        filteredList = this.locations.filter((location) => {
          return location.name
            .toLowerCase()
            .match(this.searchQuery.toLowerCase());
        });
      } else {
        filteredList = this.locations;
      }

      return filteredList;
    },
  },
  methods: {
    ...location.mapActions([
      "GET_LOCATION_LIST",
      "GET_ACTIVE_LOCATION_DETAILS",
      "UPDATE_ACTIVE_LOCATION",
    ]),
    handleLocationClick(location) {
      this.UPDATE_ACTIVE_LOCATION(location);
      localStorage.setItem("location", location.code);
      this.$refs.modalRef.toggleModal();
      this.$router.push({ name: "Dashboard" });
    },
    handleSearchInput(searchQuery) {
      this.searchQuery = searchQuery.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.location__spinner {
  position: relative;
  height: 20rem;
}

.location {
  width: 100%;
  margin-bottom: 1.5rem;
}
</style>
