<template>
  <div>
    <TransitionFade>
      <TableContainer
        v-if="newsFeedCollection"
        :table-data="newsFeedCollection"
        :table-type="'NEWSFEED'"
        :table-name="'Nyheder'"
        :is-dashboard="false"
        :table-page-size="10"
        :table-container-footer="false"
        :is-simple-table="true"
        :show-any-filters="false"
      />
    </TransitionFade>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import TableContainer from "../components/TableContainer.vue";
import TransitionFade from "../components/TransitionFade.vue";

const newsFeed = createNamespacedHelpers("newsfeed");

export default {
  name: "NewsFeed",
  components: {
    TableContainer,
    TransitionFade,
  },
  computed: {
    ...newsFeed.mapGetters({ newsFeedCollection: "newsFeedCollection" }),
  },
  watch: {},
  created() {
    this.GET_NEWSFEEDS();
  },
  methods: {
    ...newsFeed.mapActions(["GET_NEWSFEEDS"]),
  },
};
</script>
