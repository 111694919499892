<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 9.987 7.802"
    fill="currentColor"
  >
    <g transform="translate(-756.431 -7.838)">
      <path
        d="M11,5.784,6.316,10.466,4.131,8.281l-1.56,1.56,3.745,3.745,6.242-6.242Z"
        transform="translate(753.86 2.054)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconCheckMark",
};
</script>
