<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 25.414 23.85"
  >
    <g transform="translate(-2 -2)">
      <path
        d="M9,12.05l3.45,3.45L23.95,4"
        transform="translate(2.05 0.15)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M24.85,13.925v8.5a2.428,2.428,0,0,1-2.428,2.428H5.428A2.428,2.428,0,0,1,3,22.422V5.428A2.428,2.428,0,0,1,5.428,3H18.781"
        transform="translate(0 0)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTask",
};
</script>
