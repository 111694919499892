<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 13.042 13"
  >
    <g transform="translate(0.5 0.5)">
      <path
        d="M16.542,22.5v2.667A1.336,1.336,0,0,1,15.2,26.5H5.838A1.336,1.336,0,0,1,4.5,25.167V22.5"
        transform="translate(-4.5 -14.5)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M17.19,7.833,13.845,4.5,10.5,7.833"
        transform="translate(-7.824 -4.5)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M18,4.5v8"
        transform="translate(-11.979 -4.5)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconUpload",
};
</script>
