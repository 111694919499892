<template>
  <div class="table-container-header">
    <div class="table-container-header__top">
      <h2 class="table-container-header__table-name">
        {{ tableName }}
      </h2>

      <div
        v-if="tableData && showAnyFilters"
        class="table-container-header__button-filter"
      >
        <Button
          @button-click="handleButtonClick"
          :close="showFilters"
          :filter="!showFilters"
          >{{
            getDictionaryEntry("tableContainerHeader.filterButton.text")
          }}</Button
        >
      </div>
    </div>

    <div
      v-if="tableData && showAnyFilters"
      class="table-container-header__bottom"
    >
      <div class="table-container-header__filters-desktop">
        <FilterInputs
          :table-type="tableType"
          :table-data="tableData"
          @click-tab="handleFilterTabClick"
        />
      </div>
      <transition name="expand">
        <div class="table-container-header__filters-mobile" v-if="showFilters">
          <FilterInputs
            :table-type="tableType"
            :table-data="tableData"
            @click-tab="handleFilterTabClick"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import FilterInputs from "./FilterInputs.vue";
import Button from "./Button.vue";
import { createNamespacedHelpers } from "vuex";
const table = createNamespacedHelpers("table");

export default {
  name: "TableContainerHeader",
  components: {
    FilterInputs,
    Button,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
    tableName: {
      type: String,
      default: null,
    },
    isSimpleTable: {
      type: Boolean,
      default: false,
    },
    showAnyFilters: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showFilters: false,
    };
  },
  beforeMount() {
    if (this.$route.query.tableSearchQuery) {
      this.showFilters = true;
    }
  },
  computed: {
    ...table.mapGetters(["tableSearchQuery"]),
  },
  methods: {
    handleButtonClick() {
      this.showFilters = !this.showFilters;
    },
    handleFilterTabClick(option) {
      this.$emit("click-tab", option); // emitting because event doesn't bubble
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.table-container-header {
  display: flex;
  flex-direction: column;
  padding: 3.3rem calc(var(--spacing-table--padding-x) + 0.4rem);
  border-bottom: var(--border--lg) solid var(--color-grey--light);

  @media screen and (min-width: 1500px) {
    height: 8.6rem;
    padding: 0 calc(var(--spacing-table--padding-x) + 0.4rem);
    flex-direction: row;
    align-items: center;
  }
}

.table-container-header__button-filter {
  @media screen and (min-width: 1500px) {
    display: none;
  }
}

.table-container-header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container-header__bottom {
  width: 100%;
}

.table-container-header__table-name {
  width: max-content;
  font-weight: var(--font-weight--semibold);
}

.table-container-header__filters-desktop {
  display: none;

  @media screen and (min-width: 1500px) {
    display: block;
  }
}

.table-container-header__filters-mobile {
  @media screen and (min-width: 1500px) {
    display: none;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s ease;
  max-height: 25rem;
  opacity: 1;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
