<!-- prettier-ignore -->
<template>
  <div>
    <h1>Indhold</h1>
    <ul style="list-style-type: disc;">
      <li><button @click="tableOfContentsClicked('header1')">Sådan bruger du CBRE Teknisk servicepartners
          Kundeportal</button>
        <ul>
          <li>
            <button class="sub" @click="tableOfContentsClicked('header2')">Sådan logger du på kundeportalen</button>
          </li>
          <li>
            <button class="sub" @click="tableOfContentsClicked('header3')">Gennemgang af startside</button>
          </li>
        </ul>
      </li>
      <li><button @click="tableOfContentsClicked('header4')">Få overblik over dine lovpligtige
          eftersyn</button>
        <ul>
          <li>
            <button class="sub" @click="tableOfContentsClicked('header5')">Vejledning til lovpligtige eftersyn indenfor
              brand</button>
          </li>
        </ul>
      </li>
      <li>
        <button @click="tableOfContentsClicked('header6')">Menupunkter: Fejl, eftersyn, enheder og
          sted</button>
      </li>
      <li>
        <button @click="tableOfContentsClicked('header7')">Eftersyn</button>
      </li>
      <li>
        <button @click="tableOfContentsClicked('header8')">Enheder</button>
      </li>
    </ul>
    <br>
    <h1 id="header1">Sådan bruger du CBRE Teknisk servicepartners Kundeportal</h1>
    <p>
      I denne brugervejledning bliver funktionerne i vores kundeportalen gennemgået.
      Formålet med vores kundeportal er at give dig et samlet overblik over din
      virksomheds enheder og anlæg samt fejl og mangler. I kundeportalen kan du se,
      om der er forfaldne eftersyn i din virksomhed, og hvornår det er tid til næste
      lovpligtige eftersyn. Al dokumentation for udførte lovpligtige eftersyn bliver
      gemt i kundeportalen.
    </p>
    <br />
    <p>
      Som kunde er det dit ansvar at registrere og ændre status på de enheder og
      anlæg, der har fejl og mangler, medmindre du har indgået en aftale med os om,
      at vi er ansvarlige for registreringen.
    </p>
    <br />
    <p>
      I guiden gennemgår vi bl.a., hvordan du selv kan ændre status på enheder og
      anlæg. Guiden indeholder følgende punkter:
    </p>
    <ol>
      <li>Sådan logger du på kundeportalen</li>
      <li>Gennemgang af startsiden</li>
      <li>
        Få overblik over dine lovpligtige eftersyn
        <ul>
          <li>Menupunkter: Fejl, eftersyn, enheder og sted</li>
        </ul>
      </li>
    </ol>
    <h2 id="header2">Sådan logger du på kundeportalen</h2>
    <p>
      Du finder kundeportalen på vores hjemmeside
      <a href="https://cbre-tekniskservicepartner.dk/kundeportal/">(cbre-tekniskservicepartner.dk)</a>
      øverst i højre hjørne:<br /><br />
    </p>
    <img class="img-guide" src="Guide/KundeportalLokation.png" />
    <br /><br />
    <p>Herefter får du denne loginside:</p>
    <br />
    <img class="img-guide" src="Guide/KunderportalLoginSide.png" />
    <br /><br />
    <h3 id="header3">Gennemgang af startsiden</h3>
    <p>
      Startsiden indeholder ni kasser. Du åbner f.eks. den lovpligtige portal ved at
      klikke på ”Lovpligtige eftersyn”. I kassen ”Guide til kundeportalen” finder du
      vejledning om, hvordan du skifter din adgangskode samt opretter nye brugere og
      opdaterer eksisterende brugere som administrator.
    </p>
    <img class="img-guide" src="Guide\KundeportalLokationStartpage.png" />
    <p>
      Du har også mulighed for at se dine oplysninger, skifte adgangskode og læse om
      vores generelle betingelser for webbaserede data under ”Min profil”. Under din
      profil har du som administrator også mulighed for at opdatere og slette
      brugere i kundeportalen:
    </p>
    <img class="img-guide" src="\Guide\KunderportalProfil.png" />
    <br /><br />
    <h2 id="header4">Få overblik over dine lovpligtige eftersyn</h2>
    <p>
      I det følgende gennemgår vi funktionerne under ”Lovpligtige eftersyn”, hvor vi
      anvender en demo-virksomhed ved navn MB-Tech. På startsiden er der seks
      kasser, som sikrer dig et hurtigt overblik over f.eks. aktuelle fejl.
    </p>
    <img class="img-guide" src="Guide/lovpligtigEftersynsOverblik/LovpligtigPortalEftersynOverblik.png" />
    <p>
      Hvis din virksomhed er registeret med flere lokationer, kan du skifte mellem
      lokationerne ved at klikke på ”Skift lokation” øverst i højre hjørne:
    </p>
    <img class="img-guide" src="Guide\lovpligtigEftersynsOverblik/LovpligtigPortalSkiftLokation.png" />
    <p>
      Når du klikker på en af lokationerne på oversigten, kan du arbejde med eller
      hente informationer om den pågældende lokation:
    </p>
    <img class="img-guide" src="Guide\lovpligtigEftersynsOverblik/LovpligtigPortalLokationer.png" />
    <p>
      Via startsiden kan du åbne disse rapporter ved at klikke på følgende symboler:
    </p>
    <ul>
      <li>
        • Fejlrapport:
        <img src="Guide\icons\FejlrapportIkon.png" />
      </li>
      <li>
        • Servicerapport
        <img src="Guide\icons\ServicerapportIkon.png" />
      </li>
      <li>
        • Kontrolrapport:
        <img src="Guide\icons\KontrolrapportIkon.png" />
      </li>
    </ul>
    <br />
    <p>På startsiden kan du se ”Aktuelle eftersyn”:</p>
    <img class="img-guide" src="Guide\lovpligtigEftersynsOverblik\eftersynsoverblik.png" />
    <p>
      Hvis du gerne vil se alle dine eftersyn, skal du klikke på ”Vis alle” øverst i
      højre hjørne.
    </p>

    <br />
    <p>
      Du har også mulighed for at hente rapporter direkte fra startsiden. Du skal
      sætte flueben ved de enheder, du gerne vil se rapporter for og klikke på ”Hent
      filer”.
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalAktuelleEftersyn2.png" />
    <br />
    <p>
      Nu skal du vælge, om det er fejlrapport, servicerapport og/eller kontrolskema,
      du gerne vil downloade. Husk at sætte flueben ved ”Tilføj de vedhæftede filer
      fra rapporterne”. Filerne bliver herefter pakket som zip-filer, som du kan
      gemme og/eller udskrive:
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalDownloadFiler.png" />

    <h3 id="header5">Vejledning til lovpligtige eftersyn indenfor brand</h3>
    <p>En vejledning til lovpligtige eftersyn indenfor brand kan findes på følgende PIXI bog</p>
    <a
      href="https://legislativestorage.blob.core.windows.net/legislativefiles/guidance/CRBE_Brandsikkerhed_Brochure.pdf?sp=r&st=2023-06-20T11:01:13Z&se=2024-06-20T19:01:13Z&spr=https&sv=2022-11-02&sr=b&sig=Rv1jGYOExXQ6LSt3Et5rxY6n23Si3kf89%2BjUBnt6u5g%3D">PIXI
      til lovpligtig eftersyn indenfor brand
    </a>
    <br /><br />
    <h2 id="header6">Menupunkter: Fejl, eftersyn, enheder og sted</h2>
    <p>
      I sidemenuen i venstre side har du adgang til fejl, eftersyn, enheder og
      steder. Hver funktion bliver gennemgået i det følgende:
    </p>
    <img class="img-guide" src="Guide\lovpligtigEftersynsOverblik\LovpligtigPortalEftersynOverblik.png" />
    <p>
      ”Fejl” er første punkt i menuen. Når du klikker på det, så får du mulighed for
      at vælge ”Aktuelle fejl” og ”Afsluttede fejl”:
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalAktuelleFejl.png" />
    <br /><br />
    <p>Ved ”Aktuelle fejl” får du samme funktioner, som du gør via startsiden.</p>
    <p>
      Forskellen er dog, at du nu har mulighed for at søge på enhedstype og data på tværs af alle kolonner:
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalAktuelleFejl2.png" />
    <p>
      Når du klikker på ”Enhedstype”, kan du se hvilke enhedstyper, der er
      registeret med aktuelle fejl. Du kan f.eks. vælge ”Pallereol”, hvis det kun er
      denne enhedstype, du vil se fejl for.
    </p>
    <br />
    <p>Du kan også få overblik over termografifejl ved at klikke på feltet:</p>
    <img class="img-guide" src="Guide\LovpligtigPortalAktuelleFejl3.png" />
    <p>
      Hvis du klikker på dette ikon
      <img class="img-guide" src="Guide\icons\DetaljerIkon.png" />
      under ”Detaljer”, åbner du termografirapporten:
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalThermographyReport.png" />
    <p>
      Du kan downloade termografirapporten ved at klikke på linket under
      ”Vedhæftning”.
    </p>
    <br />
    <p>
      Du kan også kvittere for en hændelse ved at klikke i kassen ”Status”. Det er
      vigtigt, du tilføjer en kommentar om årsagen til, hvorfor du vil ændre
      statussen. Bagefter skal du klikke på ”Opdater status for rapport”, så dine
      ændringer bliver gemt i historikken.
    </p>
    <br />
    <h2 id="header7">Eftersyn</h2>
    <p>
      Det næste menupunkt er ”Eftersyn”, hvor du har de samme funktioner som via
      startsiden. Forskellen er dog, at du nu har mulighed for at søge på dato
      og/eller enhedstype:
    </p>
    <img class="img-guide" src="Guide\LovpligtigPortalAktuelleEftersyn.png" />
    <p>Afsluttede og kommende eftersyn kan findes i menuen til venstre.</p>
    <br />
    <h2 id="header8">Enheder</h2>
    <p>
      Det næste menupunkt er ”Enheder”. Her har du mulighed for at se samtlige
      enheder, der er registeret i virksomheden. Du har de samme funktioner som via
      startsiden. På denne side er det muligt at søge efter enhedstype eller enhedsnummer i søgefeltet.
    </p>
    <img class="img-guide" src="Guide\units\Unit1.png" />
    <p>
      Som du kan se på ovenstående billede, så har du mulighed for at vælge ”Upload
      Fil” på enheden. Du kan slette filer ved at klikke på ikonet
      <img class="img-guide" src="Guide\icons\TrashIkon.png" />
      . Derudover er der en indikator længst til venstre i hver række der angiver om der er eftersyn som er forfaldne
      eller ej.
      Du kan også se enhedshistorikken ved at klikke på ”Se enhedshistorik”, hvor
      du får overblik over forfaldne eftersyn, fejl eller afsluttede fejl.
    </p>
    <br />
    <p>
      Enhedshistorikken viser alle fejl og eftersyn som er tilknyttet til den enkelte enhed.
    </p>
    <img class="img-guide" src="Guide\units\UnitLocation.png" />
  </div>
</template>

<script>
export default {
  name: "Guide",
  methods: {
    tableOfContentsClicked(headerId) {
      document.getElementById(headerId).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-guide {
  border: 1px solid black;
  max-width: 75%;
}

button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

ul {
  padding: 0 0 0 40px;
  margin: 0px;
}

button.sub {
  font-size: 16px;
}

button.subsub {
  font-size: 15px;
}
</style>
