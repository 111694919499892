<template>
  <div class="overlay-task">
    <div class="overlay-task__installation">
      <strong
        >{{ getDictionaryEntry("overlayTask.labelInstallation") }}: </strong
      >{{ task.content.installation }}
    </div>
    <div class="overlay-task__change-status">
      <div class="overlay-task__select-label">
        <strong>{{ getDictionaryEntry("overlayTask.labelStatus") }}:</strong>
      </div>
      <div class="overlay-task__select">
        <Select
          :options="selectOptions"
          :defaultSelected="{
            text: getDictionaryEntry(
              'selectTaskChangeStatus.selectItem.default'
            ),
          }"
          @input="handleSelectedStatus"
        />
      </div>

      <div class="overlay-task__note-label">
        <strong>{{ getDictionaryEntry("overlayTask.labelNote") }}</strong>
      </div>
      <Textarea @handle-input="setNote" />

      <div class="overlay-task__save">
        <Button v-on:click="changeStatus">{{
          getDictionaryEntry("overlayTask.buttonSave")
        }}</Button>
        <div class="overlay-task__saved-text" v-if="taskSaved">
          {{ getDictionaryEntry("overlayTask.statusUpdated.text") }}
        </div>
      </div>
    </div>

    <div class="overlay-task__table-heading">
      {{ getDictionaryEntry("overlayTask.statusHistory") }}
    </div>

    <div class="overlay-task__table">
      <TableSimple
        :tableData="task.content.history"
        :tableType="'OVERLAY_TASK_STATUS_HISTORY'"
        :simpleHeader="true"
      />
    </div>
  </div>
</template>

<script>
import Select from "./Select.vue";
import Textarea from "./Textarea.vue";
import Button from "./Button.vue";
import TableSimple from "./TableSimple.vue";
import { createNamespacedHelpers } from "vuex";
const overlay = createNamespacedHelpers("overlay");
const task = createNamespacedHelpers("task");

export default {
  name: "OverlayTask",
  components: {
    Select,
    Textarea,
    Button,
    TableSimple,
  },
  data() {
    return {
      note: null,
      selectedStatus: null,
      taskSaved: false,
    };
  },
  computed: {
    ...overlay.mapGetters({ task: "activeOverlay" }),
    selectOptions: function () {
      return [
        {
          id: "1",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.completed"
          ),
        },
        {
          id: "2",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.open"
          ),
        },
        {
          id: "3",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.postponedByIntego"
          ),
        },
        {
          id: "4",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.postponedByCustomer"
          ),
        },
        {
          id: "5",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.planned"
          ),
        },
        {
          id: "6",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.handled"
          ),
        },
        {
          id: "7",
          text: this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.notHandled"
          ),
        },
      ];
    },
  },
  methods: {
    ...task.mapActions(["UPDATE_TASK_STATUS"]),
    changeStatus() {
      this.UPDATE_TASK_STATUS([
        {
          status: this.selectedStatus.id,
          note: this.note,
          installation: this.task.content.id,
        },
      ]).then(() => {
        this.taskSaved = true;
      });
    },
    handleSelectedStatus(status) {
      this.selectedStatus = status;
    },
    setNote(value) {
      this.note = value;
    },
    valueGetterToolTip(params) {
      if (params.value) {
        return { value: params.value };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-task__installation {
  font-size: 1.3rem;
  margin-top: 0.7rem;
  margin-bottom: 1.6rem;
}

.overlay-task__change-status {
  padding: 3rem 0;
  border-top: var(--border-divider);
  border-bottom: var(--border-divider);
}

.overlay-task__select-label,
.overlay-task__note-label {
  margin-bottom: 1.2rem;
}

.overlay-task__note-label,
.overlay-task__save {
  margin-top: 2.5rem;
}

.overlay-task__save {
  display: flex;
  align-items: center;
}

.overlay-task__saved-text {
  margin-left: 2rem;
  color: var(--color-green);
}

.overlay-task__select {
  width: 25rem;
}

.overlay-task__table-heading {
  padding: 1.8rem;
  margin-top: 3rem;
  font-weight: var(--font-weight--semibold);
  font-size: 1.6rem;
  border-radius: var(--border-radius);
  background-color: var(--color-grey--light);
}
</style>
