<template>
  <nav
    class="navigation-sidebar"
    :class="[expandSidebar(), { 'navigation-sidebar--mobile': isMobile }]"
  >
    <transition name="fade">
      <div
        v-if="isSubNavOpen && !isMobile"
        class="navigation-sidebar__close"
        v-on:click="closeSubNavClick"
      >
        <IconClose />
      </div>
    </transition>
    <div
      v-for="(item, index) in itemList"
      class="navigation-sidebar__item"
      v-bind:key="index"
      v-on:click="navItemClick(item)"
    >
      <div
        v-if="isAuthenticationRequired(item)"
        class="navigation-sidebar__link"
      >
        <div class="navigation-sidebar__icon">
          <component v-bind:is="item.icon"></component>
        </div>
        <div class="navigation-sidebar__label">
          {{ item.label }}
        </div>
      </div>
      <transition-group name="slide">
        <div
          class="navigation-sidebar__sub-items"
          v-if="item.subItems && activeItem === item.label && isSubNavOpen"
        >
          <div
            v-for="(subItem, subIndex) in item.subItems"
            v-bind:key="subIndex"
            class="navigation-sidebar__sub-item"
            v-on:click="navSubItemClick(subItem)"
          >
            <div class="navigation-sidebar__link">
              {{ subItem.label }}
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </nav>
</template>

<script>
import IconDashboard from "./icons/IconDashboard.vue";
import IconIssue from "./icons/IconIssue.vue";
import IconTask from "./icons/IconTask.vue";
import IconUnit from "./icons/IconUnit.vue";
import IconLocation from "./icons/IconLocation.vue";
import IconNotification from "./icons/IconNotification.vue";
import IconClose from "./icons/IconClose.vue";
import IconStatistics from "./icons/IconStatistics.vue";
import { mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "NavigationSidebar",
  components: {
    IconDashboard,
    IconIssue,
    IconTask,
    IconUnit,
    IconLocation,
    IconNotification,
    IconClose,
    IconStatistics,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: "",
      isSubNavOpen: null,
      itemList: null,
    };
  },
  watch: {
    getDictionaryEntry: function () {
      this.itemList = [
        {
          icon: "IconDashboard",
          label: this.getDictionaryEntry("sidebarItem.dashboard"),
          url: "/",
          subItems: null,
        },
        {
          icon: "IconIssue",
          label: this.getDictionaryEntry("sidebarItem.issues"),
          url: null,
          subItems: [
            {
              label: this.getDictionaryEntry("sidebarItem.issues.activeIssues"),
              url: "/aktuelle-fejl",
              subItems: null,
            },
            {
              label: this.getDictionaryEntry(
                "sidebarItem.issues.completedIssues"
              ),
              url: "/afsluttede-fejl",
              subItems: null,
            },
          ],
        },
        {
          icon: "IconTask",
          label: this.getDictionaryEntry("sidebarItem.tasks"),
          url: null,
          subItems: [
            {
              label: this.getDictionaryEntry("sidebarItem.tasks.activeTasks"),
              url: "/aktuelle-eftersyn",
              subItems: null,
            },
            {
              label: this.getDictionaryEntry(
                "sidebarItem.tasks.completedTasks"
              ),
              url: "/afsluttede-eftersyn",
              subItems: null,
            },
            {
              label: this.getDictionaryEntry("sidebarItem.tasks.plannedTasks"),
              url: "/kommende-eftersyn",
              subItems: null,
            },
          ],
        },
        {
          icon: "IconUnit",
          label: this.getDictionaryEntry("sidebarItem.installations"),
          url: "/enheder",
          subItems: null,
        },
        {
          icon: "IconLocation",
          label: this.getDictionaryEntry("sidebarItem.location"),
          url: "/lokation",
          subItems: null,
        },
        {
          icon: "IconNotification",
          label: this.getDictionaryEntry("sidebarItem.notification"),
          url: "/Notifikation",
          subItems: null,
          //authRequired: true,
        },
        // {
        //   icon: "IconStatistics",
        //   label: this.getDictionaryEntry("sidebarItem.IconStatistics"),
        //   url: "/chart",
        //   subItems: null,
        // },
      ];
    },
  },
  computed: {
    ...mapGetters(["isMenuOpen"]),
    ...authentication.mapGetters(["activeCustomer"]),
  },
  methods: {
    closeSubNavClick() {
      this.isSubNavOpen = false;
    },
    expandSidebar() {
      if (this.isSubNavOpen !== null) {
        if (this.isSubNavOpen) {
          return "navigation-sidebar--expanded";
        } else {
          return "navigation-sidebar--contracted";
        }
      }
    },
    isAuthenticationRequired(item) {
      if (item.authRequired) {
        if (this.activeCustomer.role === "Admin") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    navItemClick(item) {
      if (item.subItems !== null && !this.isSubNavOpen) {
        this.activeItem = item.label;
        this.isSubNavOpen = true;
      } else if (
        item.subItems !== null &&
        this.isSubNavOpen &&
        this.activeItem !== item.label
      ) {
        this.activeItem = item.label;
        this.isSubNavOpen = true;
      } else if (this.activeItem === item.label) {
        this.isSubNavOpen = false;
        if (this.isMenuOpen) {
          this.$store.dispatch("toggleMenu");
        }
      } else if (item.subItems === null) {
        this.$router.push({ path: `${item.url}` });

        if (this.isMenuOpen) {
          this.$store.dispatch("toggleMenu");
        }
      }
    },
    navSubItemClick(subItem) {
      this.$router.push({ path: `${subItem.url}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s;
  transform: translateY(0);
  margin-top: 0;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-6rem);
  max-height: 0;
  width: 0;
  margin-top: 0;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 10rem;
  margin-top: 0;
}

.navigation-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3.5rem 1.6rem 0;
  padding-top: 3.5rem;

  &--contracted {
    animation: contract 0.5s;
  }

  &--expanded {
    animation: expand 0.5s;
    padding: 5.4rem 3.3rem 0;
  }

  &--mobile {
    padding: 4rem;
    animation: none;
  }

  @keyframes expand {
    0% {
      padding: 3.5rem 1.6rem 0;
    }
    100% {
      padding: 5.4rem 3.3rem 0;
    }
  }

  @keyframes contract {
    0% {
      padding: 5.4rem 3.3rem 0;
    }
    100% {
      padding: 3.5rem 1.6rem 0;
    }
  }
}

.navigation-sidebar__close {
  position: absolute;
  top: 1.7rem;
  right: 1.8rem;
  width: 1rem;
  color: var(--color-black);

  &:hover {
    cursor: pointer;
  }
}

.navigation-sidebar__item {
  color: var(--color-grey--dark);
  margin-bottom: 4rem;
  text-align: center;

  .navigation-sidebar--expanded &,
  .navigation-sidebar--mobile & {
    text-align: left;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.navigation-sidebar__link {
  color: var(--color-grey--dark);
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: var(--color-accent-green);
  }

  .navigation-sidebar--expanded &,
  .navigation-sidebar--mobile & {
    text-align: left;
    display: flex;
    align-items: center;
  }
}

.navigation-sidebar__icon {
  display: inline-block;
  width: 2.3rem;
  margin-bottom: 0.8rem;

  .navigation-sidebar--expanded &,
  .navigation-sidebar--mobile & {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.navigation-sidebar__label {
  font-weight: var(--font-weight--semibold);
  text-decoration: none;
}

.navigation-sidebar__sub-items {
  margin-left: 3rem;
  margin-top: 2.2rem;
  overflow: hidden;
}

.navigation-sidebar__sub-item {
  text-align: left;
  border-left: var(--border--sm) solid var(--color-blue);
  padding-left: 1rem;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.navigation-sidebar__link {
  text-decoration: none;
  color: var(--color-grey--dark);
}
</style>
