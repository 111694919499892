<template>
  <div>{{ translatedStatus }}</div>
</template>

<script>
export default {
  name: "StatusTask",
  data() {
    return {
      task: null,
    };
  },
  beforeMount() {
    const data = this.params.data;
    this.task = data;
  },
  computed: {
    translatedStatus: function () {
      let status = this.task.status;
      switch (this.task.status) {
        case "Completed":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.completed"
          );
          break;
        case "Open":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.open"
          );
          break;
        case "PostponedByIntego":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.postponedByIntego"
          );
          break;
        case "PostponedByCustomer":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.postponedByCustomer"
          );
          break;
        case "Planned":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.planned"
          );
          break;
        case "Handled":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.handled"
          );
          break;
        case "NotHandled":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.notHandled"
          );
          break;
        case "Started":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.started"
          );
          break;
        case "Activated":
          status = this.getDictionaryEntry(
            "selectTaskChangeStatus.selectItem.activated"
          );
          break;
      }

      return status;
    },
  },
};
</script>
