<template>
  <div
    class="select"
    :class="[
      { 'select--open': open },
      { 'select--disabled': disabled },
      { 'select--secondary': secondary },
      { 'select--dynamic-width': dynamicWidth },
    ]"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div
      v-if="selected !== null"
      class="select__selected"
      :class="{
        'select__selected--open': open,
      }"
      @click="handleOpenClick"
    >
      {{ selected.text }}
    </div>
    <div class="select__items" :class="{ hide: !open }">
      <div
        v-show="hasSelectedItem && !secondary"
        @click="handleClearClick"
        class="select__item select__item--clear"
      >
        Ryd valgte
      </div>
      <div
        class="select__item"
        v-for="(option, i) of options"
        :key="i"
        @click="handleItemClick(option)"
      >
        <div v-if="option.text !== null">
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    defaultSelected: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dynamicWidth: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hasSelectedItem: false,
      selected: this.defaultSelected
        ? this.defaultSelected
        : this.options.length > 0
        ? this.options[0].text
        : null,
      open: false,
    };
  },
  mounted() {
    if (this.selected !== this.defaultSelected) {
      this.$emit("input", this.selected);
    }
  },
  methods: {
    handleOpenClick() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    handleItemClick(option) {
      this.hasSelectedItem = true;
      this.selected = option;
      this.open = false;
      this.$emit("input", option);
    },
    handleClearClick() {
      if (this.defaultSelected) {
        this.selected = this.defaultSelected;
      } else {
        this.selected = this.options[0];
      }

      this.$emit("input", { text: "" });
      this.hasSelectedItem = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.select {
  position: relative;
  width: 100%;
  text-align: left;
  line-height: var(--input--height);
  z-index: 2;

  &--open {
    z-index: 3;
  }

  &--dynamic-width {
    &:focus,
    &:focus-visible {
      outline: 0;
    }
  }
}

.select__selected {
  cursor: pointer;
  border: var(--border--sm) solid var(--color-grey);
  border-radius: var(--border-radius);
  color: var(--color-primary);
  user-select: none;
  background: url(../assets/arrow-down.svg) no-repeat calc(100% - 1.3rem) center;
  background-size: 1.2rem auto;
  padding: 0 3.5rem 0 1.3rem;

  .select--disabled & {
    background: none;
    background-color: var(--color-grey);
    color: var(--color-white);
    cursor: initial;
  }

  .select--secondary & {
    width: fit-content;
    color: var(--color-cbre-green);
    background: url(../assets/arrow-down-blue.svg) no-repeat calc(100% - 0.3rem)
      center;
    background-size: 1.2rem auto;
    border: 0;
    padding-right: 2.5rem;
    font-weight: var(--font-weight--semibold);
  }

  .select--dynamic-width & {
    position: relative;
  }
}

.select__selected--open {
  border: var(--border--sm) solid var(--color-primary);
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
}

.select__selected--open {
  .select--dynamic-width & {
    border-bottom: var(--border--sm) solid var(--color-white);
  }
}

.select__items {
  position: absolute;
  max-height: 30rem;
  overflow-y: auto;
  left: 0;
  right: 0;
  color: var(--color-primary);
  background-color: var(--color-white);
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
  border-right: var(--border--sm) solid var(--color-primary);
  border-left: var(--border--sm) solid var(--color-primary);
  border-bottom: var(--border--sm) solid var(--color-primary);
  z-index: 1;

  .select--secondary & {
    border: 0;
  }

  .select--dynamic-width & {
    margin-top: -1px;
    min-width: 100%;
    width: fit-content;
    border: var(--border--sm) solid var(--color-primary);
  }
}

.select__item {
  color: var(--color-primary);
  padding-left: 1rem;
  cursor: pointer;
  user-select: none;

  &:nth-child(even) {
    background-color: var(--color-grey--extralight);
  }

  @include desktop {
    &:nth-child(even) {
      background-color: unset;
    }
  }

  &:hover {
    background-color: var(--color-grey--light);
  }

  &--clear {
    font-weight: var(--font-weight--semibold);
  }

  .select--dynamic-width & {
    white-space: nowrap;
    padding-right: 1rem;
  }
}

.hide {
  display: none;
}
</style>
