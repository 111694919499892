<template>
  <div class="grid-layout">
    <div class="grid-layout__header">
      <Header />
    </div>

    <div class="grid-layout__sidebar">
      <NavigationSidebar />
    </div>

    <main class="grid-layout__main">
      <div class="grid-layout__main-wrapper">
        <router-view :key="$route.fullPath" />
      </div>
    </main>

    <div
      class="grid-layout__overlay"
      :class="{ 'grid-layout__overlay--open': isOverlayOpen }"
    >
      <Overlay />
    </div>

    <div
      class="grid-layout__menu"
      :class="{ 'grid-layout__menu--open': isMenuOpen }"
    >
      <NavigationFooterMenu />
    </div>

    <footer class="grid-layout__footer">
      <NavigationFooter />
    </footer>

    <div
      class="grid-layout__backdrop"
      :class="{ 'grid-layout__backdrop--active': isBackdropActive }"
      @click="toggleMenu"
    ></div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import NavigationSidebar from "./components/NavigationSidebar.vue";
import NavigationFooter from "./components/NavigationFooter.vue";
import NavigationFooterMenu from "./components/NavigationFooterMenu.vue";
import Overlay from "./components/Overlay.vue";
import { mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";
const authentication = createNamespacedHelpers("authentication");
const dictionary = createNamespacedHelpers("dictionary");
const installation = createNamespacedHelpers("installation");
const table = createNamespacedHelpers("table");

export default {
  name: "App",
  components: {
    Header,
    NavigationSidebar,
    NavigationFooter,
    NavigationFooterMenu,
    Overlay,
  },
  data() {
    return {
      isLogging: true,
      hasDictionaryEntries: false,
      token: localStorage.getItem("token"),
      activeCustomerId: localStorage.getItem("customerId"),
    };
  },
  computed: {
    ...mapGetters(["isMenuOpen", "isOverlayOpen", "isBackdropActive"]),
    ...authentication.mapGetters(["accessToken"]),
  },
  watch: {
    $route: function (_, from) {
      if (this.$route.query.tableSearchQuery) {
        this.UPDATE_TABLE_SEARCH_QUERY(this.$route.query.tableSearchQuery);
      } else {
        this.CLEAR_TABLE_SEARCH_QUERY();
      }

      if (this.$route.query.accessToken) {
        this.AUTHENTICATE({
          accessToken: this.$route.query.accessToken,
        }).then(() => {
          this.isLogging = false;
          this.GET_DICTIONARY({ text: "" });
        });
        this.$router.replace("/");
      } else if (this.token && this.activeCustomerId) {
        const token = localStorage.getItem("token");
        const customerId = localStorage.getItem("customerId");

        if (token && customerId) {
          this.AUTHENTICATE({
            accessToken: token,
            customerId: customerId,
          }).then(() => {
            this.isLogging = false;
            this.GET_DICTIONARY({ text: "" });
          });
        }
      }

      if (from.fullPath === "/enheder") {
        this.RESET_INSTALLATIONS();
      }
    },
    isMenuOpen: function (isOpen) {
      if (isOpen) {
        this.$store.dispatch("toggleBackdrop", true);
      } else {
        this.$store.dispatch("toggleBackdrop", false);
      }
    },
  },
  beforeMount() {},
  methods: {
    ...authentication.mapActions(["AUTHENTICATE"]),
    ...installation.mapMutations(["RESET_INSTALLATIONS"]),
    ...table.mapActions([
      "UPDATE_TABLE_SEARCH_QUERY",
      "CLEAR_TABLE_SEARCH_QUERY",
    ]),
    ...dictionary.mapActions(["GET_DICTIONARY"]),
    toggleMenu() {
      this.$store.dispatch("toggleOverlay");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/normalize.scss";
@import "@/styles/borders.scss";
@import "@/styles/breakpoints.scss";
@import "@/styles/colors.scss";
@import "@/styles/datepicker.scss";
@import "@/styles/grid-layout.scss";
@import "@/styles/sizes.scss";
@import "@/styles/spacings.scss";
@import "@/styles/typography.scss";
@import "@/styles/z-index.scss";

html {
  font-size: 10px;
}

body {
  font-family: var(--font-family-body);
  color: --color-primary;
}

h3,
h2,
h1 {
  font-family: var(--font-family-header);
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 23px;
}

div,
p,
a {
  font-size: var(--font-size--normal);
}
</style>
