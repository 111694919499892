<template>
  <Modal :max-height="48" ref="modalRef">
    <template v-slot:header>
      <div>
        <Input
          :name="'search'"
          :placeholder="getDictionaryEntry('searchField.Customer')"
          @input="handleSearchInput"
        />
      </div>
    </template>
    <template v-slot:body>
      <div class="location__list" v-if="customers">
        <div
          v-for="(customer, index) in filteredCustomers"
          v-bind:key="'customer_' + index"
          class="location"
        >
          <div>
            <CustomerBlock
              :customer="customer"
              @customer-click="handleCustomerClick"
            />
          </div>
        </div>
      </div>
      <div class="location__spinner" v-else>
        <Spinner />
      </div>
    </template>
    <template v-slot:trigger>
      <slot name="trigger" />
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import Spinner from "./Spinner.vue";
import Input from "./Input.vue";
import CustomerBlock from "../components/CustomerBlock.vue";
import { createNamespacedHelpers } from "vuex";
const customers = createNamespacedHelpers("customer");
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "ModalCustomers",
  components: {
    Modal,
    CustomerBlock,
    Spinner,
    Input,
  },
  data() {
    return {
      searchQuery: null,
    };
  },
  computed: {
    ...authentication.mapGetters(["customerId"]),
    ...customers.mapGetters(["customers"]),
    ...customers.mapGetters(["showCutomerSelect"]),
    filteredCustomers: function () {
      let filteredList;
      if (this.searchQuery) {
        filteredList = this.customers.filter((customer) => {
          return customer.name
            .toLowerCase()
            .match(this.searchQuery.toLowerCase());
        });
      } else {
        filteredList = this.customers;
      }
      return filteredList;
    },
  },
  methods: {
    ...customers.mapActions(["GET_CUSTOMER_LIST"]),
    handleCustomerClick() {
      this.$refs.modalRef.toggleModal();
      this.$router.push({ name: "Dashboard" });
    },
    handleSearchInput(searchQuery) {
      this.searchQuery = searchQuery.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.location__spinner {
  position: relative;
  height: 20rem;
}

.location {
  width: 100%;
  margin-bottom: 1.5rem;
}
</style>
