import axios from "../../service/api";
import { GET_NEWSFEEDS } from "../action-types.js";
import { SET_NEWSFEEDS, SET_ACTIVE_NEWSFEED_ID } from "../mutation-types";

export default {
  namespaced: true,
  state: {
    activeNewsId: null,
    newsFeedCollection: null,
  },
  getters: {
    activeNews: (state) => {
      return state.newsFeedCollection.find((x) => x.id === state.activeNewsId);
    },
    newsFeedCollection: (state) => state.newsFeedCollection,
  },
  actions: {
    [GET_NEWSFEEDS]({ commit }) {
      var applicationId = 3; //3 ==  LegislativePortal
      return new Promise(() => {
        return axios
          .get(
            `${process.env.VUE_APP_API_URL}/NewsFeed/application/${applicationId}`
          )
          .then(({ data }) => {
            commit(SET_NEWSFEEDS, data);
          });
      });
    },
  },
  mutations: {
    [SET_NEWSFEEDS](state, payload) {
      state.newsFeedCollection = payload;
    },
    [SET_ACTIVE_NEWSFEED_ID](state, payload) {
      state.activeNewsId = payload;
    },
  },
};
