<template>
  <div class="link-installation" v-on:click="toggleTask">
    {{ getDictionaryEntry("tableInstallation.linkInstallationHistory") }}
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const installation = createNamespacedHelpers("installation");
const overlay = createNamespacedHelpers("overlay");

export default {
  name: "LinkInstallation",
  data() {
    return {
      type: "installation",
      installation: null,
    };
  },
  beforeMount() {
    const data = this.params.data;
    this.installation = data;
  },
  methods: {
    ...installation.mapActions(["UPDATE_ACTIVE_INSTALLATION"]),
    ...overlay.mapActions(["UPDATE_ACTIVE_OVERLAY"]),
    toggleTask() {
      this.$store.dispatch("toggleOverlay", this.type);
      this.UPDATE_ACTIVE_INSTALLATION(this.installation);
      this.UPDATE_ACTIVE_OVERLAY({
        type: this.type,
        content: this.installation.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-installation {
  height: 100%;
  cursor: pointer;
  font-size: --font-size--normal;

  &:hover {
    color: var(--color-blue);
  }
}
</style>
