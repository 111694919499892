export const AUTHENTICATE = "AUTHENTICATE";
export const CLEAR_ACTIVE_OVERLAY = "CLEAR_ACTIVE_OVERLAY";
export const CLEAR_TABLE_SEARCH_QUERY = "CLEAR_TABLE_SEARCH_QUERY";
export const DELETE_INSTALLATION_FILE = "DELETE_INSTALLATION_FILE";
export const DELETE_ISSUE_REPORT_FILE = "DELETE_ISSUE_REPORT_FILE";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const GET_ACTIVE_LOCATION_DETAILS = "GET_ACTIVE_LOCATION_DETAILS";
export const GET_ACTIVE_LOCATION_FILES = "GET_ACTIVE_LOCATION_FILES";
export const GET_CONTROL_REPORT = "GET_CONTROL_REPORT";
export const GET_DICTIONARY = "GET_DICTIONARY";
export const GET_DICTIONARY_ENTRY = "GET_DICTIONARY_ENTRY";
export const GET_DICTIONARY_ACTIVE_LANGUAGE = "GET_DICTIONARY_ACTIVE_LANGUAGE";
export const GET_INSTALLATION_FILES = "GET_INSTALLATION_FILES";
export const GET_INSTALLATION_ISSUE_LIST = "GET_INSTALLATION_ISSUE_LIST";
export const GET_INSTALLATION_LIST = "GET_INSTALLATION_LIST";
export const GET_INSTALLATION_TASK_LIST = "GET_INSTALLATION_TASK_LIST";
export const GET_ISSUE_REPORT = "GET_ISSUE_REPORT";
export const GET_ISSUE_REPORT_PDF = "GET_ISSUE_REPORT_PDF";
export const GET_ISSUE_LIST = "GET_ISSUE_LIST";
export const GET_ISSUE_LIST_LOCATIONS = "GET_ISSUE_LIST_LOCATIONS";
export const GET_ISSUE_THERMOGRAPHY = "GET_ISSUE_THERMOGRAPHY";
export const GET_LOCATION_LIST = "GET_LOCATION_LIST";
export const GET_SERVICE_REPORT = "GET_SERVICE_REPORT";
export const GET_SERVICE_REPORT_FILES = "GET_SERVICE_REPORT_FILES";
export const GET_CONTROL_REPORT_FILES = "GET_CONTROL_REPORT_FILES";
export const GET_TABLE_SEARCH_QUERY = "GET_TABLE_SEARCH_QUERY";
export const GET_TASK = "GET_TASK";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_TASK_LIST_LOCATIONS = "GET_TASK_LIST_LOCATIONS";
export const GET_TASK_FILES = "GET_TASK_FILES";
export const GET_TASKS_PLANNED = "GET_TASKS_PLANNED";
export const GET_TASKS_LOCATIONS_PLANNED = "GET_TASKS_LOCATIONS_PLANNED";
export const GET_NEWSFEEDS = "GET_NEWSFEEDS";
export const UPDATE_ACTIVE_INSTALLATION = "UPDATE_ACTIVE_INSTALLATION";
export const UPDATE_ACTIVE_INSTALLATION_FILE =
  "UPDATE_ACTIVE_INSTALLATION_FILE";
export const UPDATE_ACTIVE_LOCATION = "UPDATE_ACTIVE_LOCATION";
export const UPDATE_ACTIVE_OVERLAY = "UPDATE_ACTIVE_OVERLAY";
export const UPDATE_ACTIVE_ROW_ID = "UPDATE_ACTIVE_ROW_ID";
export const UPDATE_ISSUE_REPORT_STATUS = "UPDATE_ISSUE_REPORT_STATUS";
export const UPDATE_THERMOGRAPHY_STATUS = "UPDATE_THERMOGRAPHY_STATUS";
export const UPDATE_SELECTED_ISSUE_ROWS = "UPDATE_SELECTED_ISSUE_ROWS";
export const UPDATE_SELECTED_TASK_ROWS = "UPDATE_SELECTED_TASK_ROWS";
export const UPDATE_SELECT_FILTER = "UPDATE_SELECT_FILTER";
export const UPDATE_TABLE_SEARCH_QUERY = "UPDATE_TABLE_SEARCH_QUERY";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const UPLOAD_ACTIVE_LOCATION_FILES = "UPLOAD_ACTIVE_LOCATION_FILES";
export const UPLOAD_INSTALLATION_FILES = "UPLOAD_INSTALLATION_FILES";
export const UPLOAD_ISSUE_REPORT_FILES = "UPLOAD_ISSUE_REPORT_FILES";
export const UPLOAD_TASK_FILES = "UPLOAD_TASK_FILES";
export const DELETE_TASK_FILE = "DELETE_TASK_FILE";
export const DELETE_LOCATION_FILES = "DELETE_LOCATION_FILES";
export const GET_ISSUE_DETAILS = "GET_ISSUE_DETAILS";
export const GET_LOCATION_STATS = "GET_LOCATION_STATS";
export const UPDATE_ROWMODEL = "UPDATE_ROWMODEL";
export const UPDATE_GRID_HEIGHT = "UPDATE_GRID_HEIGHT";
export const INCREMENT_GRID_HEIGHT = "INCREMENT_GRID_HEIGHT";
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
export const UPDATE_OLD_TOP = "UPDATE_OLD_TOP";
export const UPDATE_TIME_INTERVAL = "UPDATE_TIME_INTERVAL";
export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const REQUEST_NEW_TOKEN = "REQUEST_NEW_TOKEN";
export const REQUEST_NEW_TOKEN_CUSTOMER = "REQUEST_NEW_TOKEN_CUSTOMER";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
