<template>
  <div class="link" v-on:click="goToTable">
    {{ getDictionaryEntry("overlayInstallation.tableIssue.link") }}
  </div>
</template>

<script>
export default {
  name: "LinkInstallationIssueNoAction",
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  beforeMount() {
    this.data = this.params.data;
  },
  methods: {
    goToTable() {
      this.$router.push({
        path: "aktuelle-fejl",
        query: { tableSearchQuery: this.data.installationReferenceCode },
      });

      this.$store.dispatch("toggleOverlay");
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  height: 100%;
  cursor: pointer;
  font-size: 1.3rem;

  &:hover {
    color: var(--color-blue);
  }
}
</style>
