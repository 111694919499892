<template>
  <div class="link-report">
    <div class="link-report__icon" @click="openDetailModal()">
      <IconDottedMore />
    </div>
  </div>
</template>

<script>
import IconDottedMore from "../icons/IconDottedMore.vue";
import { createNamespacedHelpers } from "vuex";

const overlay = createNamespacedHelpers("overlay");
export default {
  name: "CrThermographyMore",
  components: {
    IconDottedMore,
  },
  data() {
    return {
      data: null,
    };
  },
  beforeMount() {
    this.data = this.params.data;
  },
  methods: {
    ...overlay.mapMutations(["SET_ACTIVE_OVERLAY"]),
    openDetailModal() {
      this.$store.dispatch("toggleOverlay", "thermographyDetails");
      this.SET_ACTIVE_OVERLAY({
        type: "thermographyDetails",
        content: this.data,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-report {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.link-report__icon {
  // width: 1.7rem;
  color: var(--color-grey--dark);
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: var(--color-blue);
    cursor: pointer;
  }

  &--disabled {
    color: var(--color-grey--light);

    &:hover {
      cursor: initial;
      color: var(--color-grey--light);
    }
  }

  &--issue {
    width: 1.7rem;
  }

  &--service {
    width: 1.7rem;
  }

  &--control {
    width: 1.4rem;
  }
}
</style>
