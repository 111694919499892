<template>
  <div>
    <div v-if="isLoading">
      <img style="max-width: 15px" src="../assets/loading.gif" />
    </div>
    <div v-else>
      <div>
        <div
          v-if="installation.incidents == 0 && !installation.hasOverdue"
          class="location-block__status location-block__status--green"
        ></div>
        <div
          v-if="installation.incidents > 0"
          class="location-block__status location-block__status--red"
        ></div>
        <div
          v-if="installation.hasOverdue"
          class="location-block__status location-block__status--yellow"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InstallationFiles",
  components: {},
  data() {
    return {
      installation: null,
      isLoading: false,
    };
  },
  created() {
    if (this.params.value === undefined) {
      this.isLoading = true;
    }

    if (this.params.data != undefined) {
      this.installation = this.params.data;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.location-block__status {
  align-items: center;
  font-size: 1.1rem;
  text-align: left;
  float: left;
  margin: 1%;

  &:after {
    content: "";
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
  }

  &--yellow::after {
    background-color: var(--color-yellow);
  }

  &--green::after {
    background-color: var(--color-green);
  }

  &--red::after {
    background-color: var(--color-red);
  }
}
</style>
