<template>
  <div class="label-info">
    <div class="label-info__title"><slot name="title"></slot></div>
    <div class="label-info__text"><slot name="text"></slot></div>
  </div>
</template>

<script>
export default {
  name: "LabelInfo",
};
</script>

<style lang="scss" scoped>
.label-info {
  display: flex;
  flex-direction: column;
}

.label-info__title,
.label-info__text {
  font-size: 1.3rem;
}

.label-info__title {
  font-size: 1.6rem;
  font-weight: var(--font-weight--semibold);
  margin-bottom: 0.2rem;
}

.label-info__text {
  line-height: 1.5;
}
</style>
