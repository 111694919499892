import axios from "../../service/api";
import {
  DELETE_INSTALLATION_FILE,
  GET_INSTALLATION_ISSUE_LIST,
  GET_INSTALLATION_LIST,
  GET_INSTALLATION_TASK_LIST,
  UPDATE_ACTIVE_INSTALLATION,
  UPDATE_ACTIVE_INSTALLATION_FILE,
  UPLOAD_INSTALLATION_FILES,
} from "@/store/action-types";
import {
  RESET_INSTALLATIONS,
  SET_INSTALLATIONS,
  SET_INSTALLATION_FILES,
  SET_INSTALLATION_TASKS,
  SET_ACTIVE_INSTALLATION,
  SET_ACTIVE_INSTALLATION_FILE,
  SET_INSTALLATION_ISSUES,
  SET_INSTALLATIONS_PROCESSING,
} from "../mutation-types";
import { getMaxInterval } from "../../helpers/table/timeIntervalHelper";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    activeInstallation: null,
    activeInstallationCode: null,
    activeInstallationFile: null,
    dateFrom: (() => {
      return getMaxInterval().dateFrom;
    })(),
    dateTo: (() => {
      return getMaxInterval().dateTo;
    })(),
    installationFiles: null,
    installationIssuesCompleted: null,
    installationIssuesNoAction: null,
    installations: null,
    installationTasksCompleted: null,
    installationTasksPlanned: null,
    processingInstallations: 0,
  },
  getters: {
    installations: (state) => state.installations,
    installationFiles: (state) => state.installationFiles,
    installationIssuesNoAction: (state) => state.installationIssuesNoAction,
    installationIssuesCompleted: (state) => state.installationIssuesCompleted,
    installationTasksPlanned: (state) => state.installationTasksPlanned,
    installationTasksCompleted: (state) => state.installationTasksCompleted,
    activeInstallation: (state) => state.activeInstallation,
    activeInstallationFile: (state) => state.activeInstallationFile,
    installationCode: (state) => state.installationCode,
    processingInstallations: (state) => state.processingInstallations,
  },
  actions: {
    [DELETE_INSTALLATION_FILE]({ commit, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const installationId = payload.installationId;
      const fileName = payload.fileName;
      const locationReferenceCode = payload.locationRef;
      let installationCode = payload.installationCode;
      if (installationCode.includes("/")) {
        installationCode = installationCode.split("/")[1];
      }
      commit("SET_INSTALLATIONS_PROCESSING", ++state.processingInstallations);

      return new Promise((resolve, reject) => {
        return axios
          .delete(
            `${process.env.VUE_APP_API_URL}/installation/${customerId}/deleteFile/${installationId}/${fileName}/${locationReferenceCode}/${installationCode}`
          )
          .then(({ data }) => {
            commit(
              "SET_INSTALLATIONS_PROCESSING",
              --state.processingInstallations
            );
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    [GET_INSTALLATION_LIST]({ commit }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/installation/${customerId}/location/${activeLocation.code}/installations`
        )
        .then(({ data }) => {
          commit(SET_INSTALLATIONS, data);
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [GET_INSTALLATION_ISSUE_LIST]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const issueStatus = payload.status;
      const installationReferenceCode = payload.installationReferenceCode;

      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/installation/issues/${issueStatus}?locationReferenceCode=${activeLocation.code}`,
          installationReferenceCode,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          commit(SET_INSTALLATION_ISSUES, {
            data: data,
            status: issueStatus,
          });
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [GET_INSTALLATION_TASK_LIST]({ commit, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const taskStatus = payload.status;
      const installationReferenceCode = payload.installationReferenceCode;

      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/installation/tasks/${state.dateFrom}/${state.dateTo}/${taskStatus}`,
          installationReferenceCode,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          commit(SET_INSTALLATION_TASKS, {
            data: data,
            status: taskStatus,
          });
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [UPDATE_ACTIVE_INSTALLATION]({ commit }, payload) {
      commit(SET_ACTIVE_INSTALLATION, payload);
    },
    [UPDATE_ACTIVE_INSTALLATION_FILE]({ commit }, payload) {
      commit(SET_ACTIVE_INSTALLATION_FILE, payload);
    },
    [UPLOAD_INSTALLATION_FILES]({ dispatch, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const fileList = payload.files;
      let formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        formData.append("files", file, file.name);
      }

      if (payload.comment !== null) {
        var commentBlob = new Blob([payload.comment], { type: "text/plain" });
        formData.append("commentFile", commentBlob, "The comment");
      }
      let location = store.getters["location/activeLocation"];
      let installationCode = state.activeInstallation.code;
      if (state.activeInstallation.code.includes("/")) {
        installationCode = state.activeInstallation.code.split("/")[1];
      }

      return axios
        .put(
          `${process.env.VUE_APP_API_URL}/installation/${customerId}/${payload.id}/location/${location.code}/${installationCode}/uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          dispatch(GET_INSTALLATION_LIST);
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
  },
  mutations: {
    [RESET_INSTALLATIONS](state) {
      state.installations = null;
      state.activeInstallation = null;
      state.activeInstallationFile = null;
      state.installationFiles = null;
      state.installations = null;
    },
    [SET_INSTALLATIONS](state, payload) {
      state.installations = payload;
    },
    [SET_INSTALLATION_FILES](state, payload) {
      state.installationFiles = payload;
    },
    [SET_INSTALLATION_ISSUES](state, payload) {
      if (payload.status === "NoAction") {
        state.installationIssuesNoAction = payload.data;
      }

      if (payload.status === "Completed") {
        state.installationIssuesCompleted = payload.data;
      }
    },
    [SET_INSTALLATION_TASKS](state, payload) {
      if (payload.status === "Planned") {
        state.installationTasksPlanned = payload.data;
      }

      if (payload.status === "Completed") {
        state.installationTasksCompleted = payload.data;
      }
    },
    [SET_ACTIVE_INSTALLATION](state, payload) {
      state.activeInstallation = payload;
    },
    [SET_ACTIVE_INSTALLATION_FILE](state, payload) {
      state.activeInstallationFile = payload;
    },
    [SET_INSTALLATIONS_PROCESSING](state, payload) {
      state.processingInstallations = payload;
    },
  },
};
