<template>
  <TransitionFade>
    <TableContainer
      v-if="tasks"
      :table-data="tasks"
      :table-type="'COMPLETED_TASK'"
      :table-name="getDictionaryEntry('completedTasks.table.headerText')"
      :is-dashboard="false"
      :table-page-size="10"
    />
  </TransitionFade>
</template>

<script>
import TableContainer from "../../components/TableContainer.vue";
import TransitionFade from "../../components/TransitionFade.vue";
import { createNamespacedHelpers } from "vuex";
const task = createNamespacedHelpers("task");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");

export default {
  name: "AfsluttedeEftersyn",
  components: {
    TableContainer,
    TransitionFade,
  },
  computed: {
    ...task.mapGetters({ tasks: "tasksCompleted" }),
    ...location.mapGetters(["activeLocation"]),
    ...table.mapGetters(["getTimeInterval"]),
  },
  watch: {
    activeLocation: function () {
      this.SET_TASK_STATUS("COMPLETED");
      this.GET_TASK_LIST();
    },
    getTimeInterval: function () {
      this.GET_TASK_LIST();
    },
  },
  created() {
    if (this.activeLocation) {
      this.SET_TASK_STATUS("COMPLETED");
      this.GET_TASK_LIST();
    }
  },
  methods: {
    ...task.mapActions(["GET_TASK_LIST"]),
    ...task.mapMutations(["SET_TASK_STATUS"]),
  },
};
</script>
