<template>
  <table class="location-files">
    <thead class="location-files__head">
      <tr>
        <th>
          {{ getDictionaryEntry("location.tableFiles.tableHeader.files") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(file, index) in files" v-bind:key="'location_file_' + index">
        <td class="location-files__file">
          <div style="display: flex; flex-direction: row">
            <div class="location-files__item-icon">
              <IconTrash
                v-if="file.showProcessing !== true"
                @click="() => handleDeleteFile(index)"
              />
              <img
                v-if="file.showProcessing === true"
                style="width: 11px"
                src="../assets/loading.gif"
              />
            </div>
            <div class="location-files__file-links">
              <a :href="file.url" target="_blank" download>{{
                file.displayName
              }}</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import IconTrash from "./icons/IconTrash.vue";
const location = createNamespacedHelpers("location");
const modal = createNamespacedHelpers("modal");

export default {
  name: "LocationFiles",
  components: { IconTrash },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filesProcessing: false,
    };
  },
  methods: {
    ...location.mapActions(["DELETE_LOCATION_FILES"]),
    ...location.mapMutations(["SET_ACTIVE_LOCATION_FILES"]),
    ...modal.mapMutations(["SET_MODAL_CONTENT"]),
    handleDeleteFile(index) {
      this.$store.commit("REQUEST_MODAL_OPENED", true);
      this.SET_MODAL_CONTENT({
        fileName: this.files[index].fileName,
        displayName: this.files[index].displayName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.location-files {
  border-collapse: collapse;
  font-size: --font-size--normal;
  width: 100%;

  & thead tr {
    text-align: left;
    background-color: var(--color-grey--light);
    padding: 1.4rem 1.7rem;
  }
}

.location-files th,
.location-files td {
  padding: 1.7rem;
}

.location-files td {
  font-size: 1.3rem;
}

.location-files__file {
  width: 40rem;
  margin: 0;
}

.location-files__file-links {
  display: flex;
  align-items: center;
}

.location-files_delete-file {
  width: 1rem;
  margin-right: 1rem;
  color: var(--color-red);
  cursor: pointer;
}
.location-files__item-icon {
  width: 1.1rem;
  margin-right: 13px;
  color: var(--color-red);

  &:hover {
    cursor: pointer;
  }
}
</style>
