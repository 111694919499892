<template>
  <input
    class="input"
    :class="{ 'input--search': name === 'search' }"
    name="name"
    v-bind:placeholder="placeholder"
    type="text"
    v-model="searchModel"
    v-on:input="handleInput"
    ref="input"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: "Input",
  props: {
    name: {
      type: String,
      default: null,
    },
    model: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchModel: this.model === " " ? null : this.model,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
    clearInput() {
      this.$refs.input.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: calc(100% - 1.3rem);
  height: var(--input--height);
  border: var(--border--sm) solid var(--color-grey);
  border-radius: var(--border-radius);
  padding-left: 1.3rem;

  &:focus {
    outline: var(--border--md) solid var(--color-primary);
  }

  &::placeholder,
  &::-ms-input-placeholder {
    color: var(--color-primary);
  }

  &--search {
    background: url(../assets/search.svg) no-repeat calc(100% - 1.3rem) center;
    background-size: 1.7rem 1.7rem;
    padding: 0 4rem 0 1.3rem;
    width: calc(100% - 5.5rem);
  }
}
</style>
