import { dateDanishFormat } from "../helpers/dateFormatter";
import { createNamespacedHelpers } from "vuex";
const dictionary = createNamespacedHelpers("dictionary");

export const ISSUE = "ISSUE";
export const ISSUE_THERMOGRAPHY = "ISSUE_THERMOGRAPHY";
export const TASK = "TASK";
export const TASKS_PLANNED = "TASKS_PLANNED";
export const TASKS_PLANNED_DASHBOARD = "TASKS_PLANNED_DASHBOARD";
export const COMPLETED_TASK = "COMPLETED_TASK";
export const INSTALLATION = "INSTALLATION";
export const MODAL_CHANGE_STATUS = "MODAL_CHANGE_STATUS";
export const OVERLAY_INSTALLATION_ISSUE_NO_ACTION =
  "OVERLAY_INSTALLATION_ISSUE_NO_ACTION";
export const OVERLAY_INSTALLATION_ISSUE_COMPLETED =
  "OVERLAY_INSTALLATION_ISSUE_COMPLETED";
export const OVERLAY_INSTALLATION_TASK_PLANNED =
  "OVERLAY_INSTALLATION_TASK_PLANNED";
export const OVERLAY_INSTALLATION_TASK_COMPLETED =
  "OVERLAY_INSTALLATION_TASK_COMPLETED";
export const OVERLAY_TASK_STATUS_HISTORY = "OVERLAY_TASK_STATUS_HISTORY";
export const REPORT_CONTROL_CHECKLIST = "REPORT_CONTROL_CHECKLIST";
export const REPORT_SERVICE_COMMENTS = "REPORT_SERVICE_COMMENTS";
export const NEWSFEED = "NEWSFEED";
export const NEWSFEED_DASHBOARD = "NEWSFEED_DASHBOARD";

export default {
  data() {
    return {
      defaultFilterParams: {
        readOnly: true,
      },
    };
  },
  computed: {
    ...dictionary.mapGetters(["dictionaryEntries"]),
    [NEWSFEED]: function () {
      return [
        {
          field: "title",
          cellRenderer: (params) => {
            var releaseDate = dateDanishFormat(params.data.releaseDate);
            return `<small>${releaseDate}</small><h3>${params.value}</h3> ${params.data.teaser} <br/><br/>`;
          },
          cellStyle: { "line-height": "normal" },
          tooltipField: "teaser",
          flex: 1,
          autoHeight: true,
        },
        {
          field: "",
          cellRenderer: "GoToNewsButton",
          width: 90,
        },
      ];
    },
    [NEWSFEED_DASHBOARD]: function () {
      return [
        {
          field: "title",
          cellRenderer: (params) => {
            var releaseDate = dateDanishFormat(params.data.releaseDate);
            return `<small>${releaseDate}</small><h3>${params.value}</h3> ${params.data.teaser} <br/><br/>`;
          },
          tooltipField: "teaser",
          autoHeight: true,
          cellStyle: { "line-height": "normal" },
          flex: 1,
        },
        {
          field: "",
          cellRenderer: "GoToNewsButton",
          width: 90,
        },
      ];
    },
    [ISSUE_THERMOGRAPHY]: function () {
      // todo, add keys for headernames
      return [
        {
          field: "Tavlenr",
          headerName: "Tavlenummer",
          width: 165,
        },
        {
          field: "Tavletype",
          headerName: "Tavletype",
          width: 165,
        },
        {
          field: "status",
          headerName: "Status",
          width: 108,
        },
        {
          field: "Fejlklasse",
          headerName: "Fejlklasse",
          width: 115,
        },
        {
          field: "responsibleInitials",
          headerName: "Ansvarlig",
          width: 125,
        },
        {
          field: "created",
          headerName: "Oprettet",
          width: 160,
        },
        {
          headerName: "Detaljer",
          cellRenderer: "CrThermographyMore",
          sortable: false,
          flex: 1,
        },
      ];
    },
    [ISSUE]: function () {
      return [
        {
          field: "id",
          hide: true,
        },
        {
          field: "taskId",
          hide: true,
        },
        {
          field: "installationReferenceCode",
          hide: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installationReferenceCode"
          ),
          width: 150,
          valueGetter: this.installationReferenceCodeValueGetter,
          checkboxSelection: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.installation"
          ),
          field: "installation",
          minWidth: 200,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "installation",
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.location"
          ),
          field: "locationName",
          width: 150,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "locationName",
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.report"),
          field: ["hasIssueReport", "hasServiceReport", "hasControlSchema"],
          width: 150,
          cellRenderer: "LinkReport",
          suppressNavigable: true,
          cellClass: "table__cell--no-border",
          sortable: false,
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.person"),
          field: "responsible",
          width: 145,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "responsible",
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.created"),
          field: "created",
          valueFormatter: dateDanishFormat,
          width: 130,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableIssue.tableHeader.lastActivity"
          ),
          field: "updated",
          valueFormatter: dateDanishFormat,
          width: 150,
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.note"),
          field: "latestNote",
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.type"),
          field: "installationType",
          hide: true,
        },
      ];
    },
    [TASKS_PLANNED_DASHBOARD]: function () {
      return [
        {
          field: "id",
          hide: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installationReferenceCode"
          ),
          field: "number",
          flex: 1,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installation"
          ),
          field: "facilityName",
          flex: 1,
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.type"),
          field: "type",
          flex: 1,
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.planned"),
          field: "planned",
          valueFormatter: (data) => {
            const monthNames = [
              "Januar",
              "Februar",
              "Marts",
              "April",
              "Maj",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "December",
            ];
            var date = new Date(data.value);
            return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
          },
          width: 136,
          flex: 1,
        },
      ];
    },
    [TASKS_PLANNED]: function () {
      return [
        {
          field: "id",
          hide: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installationReferenceCode"
          ),
          field: "number",
          flex: 1,
          filter: true,
          filterParams: {
            filterOptions: ["contains"],
          },
          suppressMenu: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installation"
          ),
          field: "facilityName",
          minWidth: 300,
          flex: 1,
          filter: true,
          filterParams: {
            filterOptions: ["contains"],
          },
          suppressMenu: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.location"
          ),
          field: "location",
          minWidth: 300,
          flex: 1,
          filter: true,
          filterParams: {
            filterOptions: ["contains"],
          },
          suppressMenu: true,
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.type"),
          field: "type",
          flex: 1,
          filter: true,
          filterParams: {
            filterOptions: ["contains"],
          },
          suppressMenu: true,
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.planned"),
          field: "planned",
          valueFormatter: (data) => {
            const monthNames = [
              "Januar",
              "Februar",
              "Marts",
              "April",
              "Maj",
              "Juni",
              "Juli",
              "August",
              "September",
              "Oktober",
              "November",
              "December",
            ];
            var date = new Date(data.value);
            return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
          },
          width: 136,
          flex: 1,
        },
      ];
    },
    [TASK]: function () {
      return [
        {
          field: "id",
          hide: true,
        },
        {
          field: "installation",
          filter: "agSetColumnFilter",
          filterParams: this.defaultFilterParams,
          hide: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installationReferenceCode"
          ),
          width: 150,
          valueGetter: this.installationReferenceCodeValueGetter,
          checkboxSelection: false,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installation"
          ),
          field: "installation",
          width: 200,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "installation",
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.location"
          ),
          field: "locationName",
          minWidth: 200,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "locationName",
        },
        {
          headerName: "Type",
          width: 150,
          field: "installationType",
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "installationType",
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.status"),
          field: "status",
          cellRendererFramework: "StatusTask",
          width: 100,
        },
        {
          headerName: this.getDictionaryEntry("tableTask.tableHeader.dueDate"),
          field: "due",
          valueFormatter: dateDanishFormat,
          width: 125,
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.note"),
          field: "latestNote",
          width: 150,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
      ];
    },
    [COMPLETED_TASK]: function () {
      return [
        {
          field: "id",
          hide: true,
        },
        {
          field: "installation",
          filter: "agSetColumnFilter",
          filterParams: this.defaultFilterParams,
          hide: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableTask.tableHeader.installationReferenceCode"
          ),
          valueGetter: this.installationReferenceCodeValueGetter,
          width: 150,
          checkboxSelection: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.installation"
          ),
          field: "installation",
          Width: 200,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "installation",
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.location"
          ),
          field: "locationName",
          width: 150,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "locationName",
        },
        {
          headerName: "Type",
          width: 150,
          field: "installationType",
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "installationType",
        },
        {
          headerName: "Jobnummer",
          field: "jobNumber",
          width: 120,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.report"
          ),
          field: ["hasIssueReport", "hasServiceReport", "hasControlSchema"],
          width: 150,
          cellRenderer: "LinkReport",
          cellRendererParams: {
            tableType: COMPLETED_TASK,
          },
          suppressNavigable: true,
          cellClass: "table__cell--no-border",
          sortable: false,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.dateCompleted"
          ),
          field: "completed",
          valueFormatter: dateDanishFormat,
          width: 125,
        },
        {
          headerName: this.getDictionaryEntry("tableIssue.tableHeader.note"),
          width: 150,
          field: "latestNote",
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry(
            "tableCompletedTask.tableHeader.type"
          ),
          field: "installationType",
          hide: true,
        },
      ];
    },
    [INSTALLATION]: function () {
      return [
        {
          field: "type",
          colId: "type_hide",
          filter: "agSetColumnFilter",
          filterParams: this.defaultFilterParams,
          hide: true,
        },
        {
          headerName: "",
          colId: "HasOverdue",
          field: "hasOverdue",
          cellRenderer: "InstallationHasOverdue",
          width: 80,
          sortable: true,
          tooltipValueGetter: () =>
            "<p>Grøn: Ok</p><p>Gul: Forfaldne eftersyn på enhed</p><p>Rød: Fejl på enhed</p>",
          tooltipComponentFramework: "CustomToolTip",
        },
        {
          headerName: this.getDictionaryEntry(
            "tableInstallation.tableHeader.type"
          ),
          colId: "Type",
          field: "type",
          width: 200,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableInstallation.tableHeader.name"
          ),
          colId: "Name",
          field: "name",
          width: 250,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableInstallation.tableHeader.files"
          ),
          field: "files",
          cellRenderer: "InstallationFiles",
          width: 300,
          wrapText: true,
          suppressNavigable: true,
          cellClass: "table__cell--no-border",
          sortable: false,
        },
        {
          headerName: "",
          field: "files",
          cellRendererFramework: "InstallationDescription",
          minWidth: 0,
          // flex: 1,
          wrapText: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "tableInstallation.tableHeader.viewHistory"
          ),
          minWidth: 0,
          flex: 1,
          field: "installationLink",
          cellRendererFramework: "LinkInstallation",
          suppressNavigable: true,
        },
      ];
    },
    [REPORT_SERVICE_COMMENTS]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.checkListLines.description"
          ),
          field: "description",
          flex: 1,
          cellRendererFramework: "CheckListService",
          suppressNavigable: true,
          cellClass: "table__cell--no-border",
          sortable: false,
          wrapText: true,
          autoHeight: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.checkListLines.input"
          ),
          field: "input",
          flex: 1,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "input",
        },
      ];
    },
    [REPORT_CONTROL_CHECKLIST]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "reportControl.tableHeader.number"
          ),
          field: "number",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "number",
          cellClass: "table-cell-custom",
          cellStyle: {
            "word-break": "break-word",
          },
        },
        {
          headerName: this.getDictionaryEntry(
            "reportControl.tableHeader.qRCode"
          ),
          field: "qrCode",
          flex: 1,
          minWidth: 100,
          cellClass: "table-cell-custom",
        },
        {
          headerName: this.getDictionaryEntry("reportControl.tableHeader.type"),
          field: "type",
          flex: 1,
          minWidth: 118,
          cellClass: "table-cell-custom",
        },
        {
          headerName: this.getDictionaryEntry(
            "reportControl.tableHeader.report"
          ),
          field: "checklist",
          minWidth: 199,
          flex: 1,
          cellRenderer: "CheckList",
          cellClass: "table-cell-custom",
          sortable: false,
          wrapText: true,
          autoHeight: true,
          resizable: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "reportControl.tableHeader.general"
          ),
          cellClass: "table-cell-custom",
          field: "general",
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "reportControl.tableHeader.status"
          ),
          field: "status",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "status",
          cellClass: "table-cell-custom",
        },
      ];
    },
    [OVERLAY_TASK_STATUS_HISTORY]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayTask.tableHistory.tableHeader.date"
          ),
          field: "created",
          valueFormatter: dateDanishFormat,
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayTask.tableHistory.tableHeader.status"
          ),
          field: "taskStatus",
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayTask.tableHistory.tableHeader.note"
          ),
          field: "note.value",
          flex: 1,
          minWidth: 218,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "note.value",
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayTask.tableHistory.tableHeader.person"
          ),
          field: "responsible",
          flex: 1,
          minWidth: 170,
        },
      ];
    },
    [OVERLAY_INSTALLATION_TASK_COMPLETED]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.dateCompleted"
          ),
          field: "created",
          cellRenderer: (params) => {
            return dateDanishFormat(params.data.completed);
          },
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.note"
          ),
          field: "latestNote",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.viewTask"
          ),
          field: "id",
          cellRendererFramework: "LinkInstallationTaskCompleted",
          suppressNavigable: true,
          width: 120,
        },
      ];
    },
    [OVERLAY_INSTALLATION_TASK_PLANNED]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.date"
          ),
          field: "created",
          valueFormatter: dateDanishFormat,
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.note"
          ),
          field: "latestNote",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.viewTask"
          ),
          field: "id",
          cellRendererFramework: "LinkInstallationTaskPlanned",
          suppressNavigable: true,
          width: 120,
        },
      ];
    },
    [OVERLAY_INSTALLATION_ISSUE_COMPLETED]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.date"
          ),
          field: "created",
          valueFormatter: dateDanishFormat,
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.note"
          ),
          field: "latestNote",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.viewIssue"
          ),
          field: "id",
          cellRendererFramework: "LinkInstallationIssueCompleted",
          suppressNavigable: true,
          width: 120,
        },
      ];
    },
    [OVERLAY_INSTALLATION_ISSUE_NO_ACTION]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.date"
          ),
          field: "created",
          valueFormatter: dateDanishFormat,
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.note"
          ),
          field: "latestNote",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
        {
          headerName: this.getDictionaryEntry(
            "overlayInstallation.tableIssue.tableHeader.viewIssue"
          ),
          field: "id",
          cellRendererFramework: "LinkInstallationIssueNoAction",
          suppressNavigable: true,
          width: 120,
        },
      ];
    },
    [MODAL_CHANGE_STATUS]: function () {
      return [
        {
          headerName: this.getDictionaryEntry(
            "modalChangeStatus.tableHeader.installation"
          ),
          field: "installation",
          flex: 1,
          minWidth: 118,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        {
          headerName: this.getDictionaryEntry(
            "modalChangeStatus.tableHeader.newStatus"
          ),
          field: "status",
          flex: 1,
          minWidth: 118,
        },
        {
          headerName: this.getDictionaryEntry(
            "modalChangeStatus.tableHeader.note"
          ),
          field: "note",
          flex: 1,
          minWidth: 118,
          tooltipValueGetter: this.valueGetterToolTip,
          tooltipComponentFramework: "CustomToolTip",
          tooltipField: "latestNote",
        },
      ];
    },
  },
  methods: {
    installationValueGetter(params) {
      var installation = params.getValue("installation");
      var installRefCode = params.data.installationReferenceCode;
      var installId = "";
      if (installRefCode !== undefined)
        installId = `(${installRefCode.split("/")[1]})`; // id included in installationname for tasks.

      if (installId === "(undefined)") {
        return `${installation}`;
      }

      var result = `${installation} ${installId}`;
      return result;
    },
    installationReferenceCodeValueGetter(params) {
      var installRefCode = params.data.installationReferenceCode;
      var installId = "";
      if (installRefCode !== undefined)
        installId = `${installRefCode.split("/")[1]}`; // id included in installationname for tasks.

      if (installId === "undefined") {
        return `${installRefCode}`;
      }

      var result = `${installId}`;
      return result;
    },
  },
};
