<template>
  <div>
    <div class="report-issue__info-list">
      <LabelInfo>
        <template #title>
          {{ getDictionaryEntry("overlay.thermography.lastUpdated") }}
        </template>
        <template #text>{{ thermography.content.created }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.person")
        }}</template>
        <template #text>{{ thermography.content.responsible }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueClass")
        }}</template>
        <template #text>{{ thermography.content.Fejlklasse }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.tabletNumber")
        }}</template>
        <template #text>{{ thermography.content.Tavlenr }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.tabletType")
        }}</template>
        <template #text>{{ thermography.content.Tavletype }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueType")
        }}</template>
        <template #text>{{ thermography.content.Fejltype }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("reportIssue.labelInfo.issueID")
        }}</template>
        <template #text>{{ thermography.content.FejlId }}</template>
      </LabelInfo>
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("overlay.thermography.file")
        }}</template>
        <template #text
          ><a :href="thermography.content.fileUrl">{{
            thermography.content.fileName
          }}</a></template
        >
      </LabelInfo>
    </div>
    <br />
    <div class="overlay__line">
      <LabelInfo>
        <template #title>{{
          getDictionaryEntry("overlay.thermography.note")
        }}</template>
        <template #text>{{ thermography.content.note }}</template>
      </LabelInfo>
      <br />
    </div>

    <div v-if="thermography.content.notes" class="report-issue__note-list">
      <strong>{{ getDictionaryEntry("reportIssue.labelNotes") }}:</strong>
      <div
        v-for="(note, index) in thermography.content.notes"
        :key="index"
        class="report-issue__note"
      >
        {{ note.value }}
      </div>
    </div>

    <div class="report-issue__status">
      <div class="report-issue__status-label">
        <strong>{{ getDictionaryEntry("reportIssue.labelStatus") }}:</strong>
      </div>

      <div class="report-issue__status-buttons">
        <div class="report-issue__status-select">
          <Select
            :default-selected="{
              text: thermography.content.status,
              id: 'NoAction',
            }"
            :options="changeStatusOptions"
            @input="handleSelectedStatus"
          />
        </div>
      </div>
    </div>

    <div class="report-issue__comment">
      <Textarea
        label="Kommentar/udbedret af"
        @handle-input="handleTextareaInput"
      />
    </div>

    <div class="report-issue__form-buttons">
      <div class="report-issue__submit">
        <Button :disabled="submitDisabled" @click="submitStatusUpdate">{{
          getDictionaryEntry("reportIssue.buttonStatusUpdate")
        }}</Button>
      </div>
    </div>
    <br /><br />

    <div v-if="activeReport != null" class="report-issue__transaction-list">
      <h2 class="report-issue__history_header">
        {{ getDictionaryEntry("text.history") }}
      </h2>
      <div
        v-for="(transaction, index) in activeReport.data.transactions"
        :key="'transaction_' + index"
        class="report-issue__transaction-item"
      >
        <!-- start -->
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.date") }}
          </div>
          <div class="report-issue__transaction-item-text">
            {{ formatDate(new Date(transaction.date)) }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.person") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.person }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.status") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.statusPhrase }}
          </div>
        </div>
        <div class="report-issue__item-field">
          <div class="report-issue__transaction-item-label">
            {{ getDictionaryEntry("text.comment") }}:
          </div>
          <div class="report-issue__transaction-item-text">
            {{ transaction.comment }}
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
</template>

<script>
import { dateDanishFormat } from "@/helpers/dateFormatter";
import Button from "../Button.vue";
import LabelInfo from "../LabelInfo.vue";
import Select from "../Select.vue";
import Textarea from "../Textarea.vue";
import { createNamespacedHelpers } from "vuex";
const report = createNamespacedHelpers("report");
const overlay = createNamespacedHelpers("overlay");
const issue = createNamespacedHelpers("issue");

export default {
  name: "OverlayThermography",
  components: {
    Button,
    LabelInfo,
    Select,
    Textarea,
  },
  data() {
    return {
      comment: "",
      selectedStatus: null,
      updating: false,
    };
  },
  computed: {
    ...overlay.mapGetters({ thermography: "activeOverlay" }),
    ...report.mapGetters(["activeReport"]),
    ...issue.mapGetters(["issueStatus"]),
    changeStatusOptions: function () {
      return [
        {
          text: this.getDictionaryEntry("reportIssue.selectStatus.noIncident"),
          id: "NoAction",
        },
        {
          text: this.getDictionaryEntry("reportIssue.selectStatus.completed"),
          id: "Completed",
        },
        {
          text: this.getDictionaryEntry("reportIssue.selectStatus.Created"),
          id: "Created",
        },
      ];
    },
    submitDisabled: function () {
      if (this.updating === true) return true;

      return this.comment === ""; // or upload underway
    },
  },
  beforeMount() {
    // Get details from endpoint and insert
    this.GET_ISSUE_DETAILS({
      issueId: this.thermography.content.issueId,
    });
  },
  methods: {
    ...report.mapActions([
      "UPDATE_ISSUE_REPORT_STATUS",
      "GET_ISSUE_REPORT",
      "DELETE_ISSUE_REPORT_FILE",
      "UPDATE_THERMOGRAPHY_STATUS",
      "GET_THERMOGRAPHY_DETAILS",
      "GET_ISSUE_DETAILS",
    ]),
    ...issue.mapActions(["GET_ISSUE_THERMOGRAPHY"]),
    formatDate(date) {
      return dateDanishFormat(date);
    },
    handleSelectedStatus(status) {
      this.selectedStatus = status;
    },
    handleTextareaInput(input) {
      this.comment = input;
    },
    submitStatusUpdate() {
      this.updating = true;

      this.UPDATE_THERMOGRAPHY_STATUS({
        issueId: this.thermography.content.issueId,
        comment: this.comment,
        status: this.selectedStatus.id,
      })
        .then(() => {
          this.GET_ISSUE_THERMOGRAPHY();
        })
        .then(() => {
          this.GET_ISSUE_DETAILS({
            issueId: this.thermography.content.issueId,
          });
          this.updating = false;
        });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";
.overlay__line {
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue {
  display: flex;
  flex-direction: column;
}

.report-issue__manchet {
  display: flex;
  font-size: 1.3rem;
  margin-top: 1rem;

  & > *:first-child {
    margin-right: 1rem;
  }
}

.report-issue__unit {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.report-issue__info-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 3rem;
  padding: 3rem 0;
  border-top: var(--border--sm) solid var(--color-grey);
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__note-list {
  padding: 1.6rem 0;
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__status {
  padding: 3rem 0;
}

.report-issue__status-label {
  margin-bottom: 1.2rem;
}

.report-issue__status-buttons {
  display: flex;
  flex-direction: column;

  @include tablet-portrait {
    flex-direction: row;
  }
}

.report-issue__status-select {
  margin-right: 0.8rem;
  min-width: 16rem;
  margin-bottom: 2rem;

  @include tablet-portrait {
    margin-bottom: 0;
  }
}

.report-issue__uploaded-files {
  padding-bottom: 3rem;
}

.report-issue_uploaded-file-list {
  text-decoration: none;
}

.report-issue_uploaded-file {
  list-style: none;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.report-issue__transaction-list {
  width: 100%;
  display: none;
  flex-direction: column;
  border-bottom: var(--border-divider);

  @include tablet-portrait {
    display: flex;
  }
}

.report-issue__transaction-item {
  display: block;
  flex-direction: row;
  padding: 1.6rem 0;
}

.report-issue__item-field {
  display: flex;
  margin-right: 1.6rem;

  &:last-child {
    margin-right: 0;
  }
}

.report-issue__transaction-item-label {
  font-weight: var(--font-weight--semibold);
  margin-right: 0.2rem;
}

.report-issue__comment {
  padding-bottom: 3rem;
}
.report-issue__history_header {
  border-bottom: var(--border--sm) solid var(--color-grey);
}

.report-issue__form-buttons {
  display: flex;
  margin-top: 3rem;
}

.report-issue__submit {
  margin-right: 0.7rem;
}
</style>
