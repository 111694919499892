<template>
  <div class="custom-tooltip" v-if="val" v-html="val"></div>
</template>

<script>
export default {
  name: "CustomToolTip",
  data() {
    return {
      val: null,
    };
  },
  beforeMount() {
    const params = this.params;
    this.val = params.value;
  },
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
  position: absolute;
  width: 30rem;
  padding: 1rem 2rem;
  pointer-events: none;
  transition: opacity 0.5s;
  background-color: white;
  box-shadow: 0px 0px 16px 2px rgba(223, 223, 223, 1);
  z-index: var(--z-index--tooptip);
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
</style>
