<template>
  <div>
    <h2>{{ getDictionaryEntry("chartControl.Header") }}</h2>
    <table style="float: right">
      <tr>
        <td>
          <b>{{ getDictionaryEntry("chartControl.FilterLocations") }}</b>
        </td>
      </tr>
      <tr>
        <td><input type="text" @input="filter($event)" /></td>
      </tr>
      <tr>
        <td>
          <b>{{ getDictionaryEntry("chartControl.AvailibleLocations") }}</b>
        </td>
        <td>
          <b>{{ getDictionaryEntry("chartControl.SelectedLocations") }}</b>
        </td>
      </tr>
      <tr>
        <td>
          <select
            v-model="selected"
            multiple
            class="select"
            @change="selectChange()"
          >
            <option
              v-for="option in filterOptions"
              :value="{ code: option.code, text: option.name }"
              :key="option.code"
            >
              {{ option.name }}
            </option>
          </select>
        </td>
        <td>
          <select
            class="select"
            v-model="selectedActive"
            multiple="multiple"
            @change="selectChangeRemove()"
          >
            <option
              v-for="option in optionsActive"
              v-bind:value="{ code: option.code, text: option.name }"
              :key="option.code"
            >
              {{ option.name }}
            </option>
          </select>
        </td>
      </tr>
    </table>
    <canvas id="planet-chart"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { createNamespacedHelpers } from "vuex";
const location = createNamespacedHelpers("location");
const chartdata = createNamespacedHelpers("chartdata");
export default {
  name: "Planet",
  components: {},
  data() {
    return {
      optionsActive: [],
      selectedActive: null,
      options: [],
      filterOptions: [],
      selected: null,
    };
  },
  mounted() {
    this.GET_LOCATION_LIST().then((data) => {
      var op = [];
      this.selected = data[0].code;
      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj["name"] = data[i].name;
        obj["code"] = data[i].code;
        op.push(obj);

        if (data[i].children) {
          for (var c = 0; c < data[i].children.length; c++) {
            var child = {};
            child["name"] = data[i].children[c].name;
            child["code"] = data[i].children[c].code;
            op.push(child);
          }
        }
      }
      this.options = op;
      this.filterOptions = op;
    });

    this.crate();
  },
  methods: {
    ...location.mapGetters(["locations"]),
    ...location.mapActions(["GET_LOCATION_LIST"]),
    ...chartdata.mapActions(["GET_LOCATION_STATS"]),
    filter(event) {
      this.filterOptions = this.options.filter((item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
    },
    move(value, arrFrom, arrTo) {
      var index = arrFrom.findIndex(function (el) {
        return el.code == value;
      });
      var item = arrFrom[index];

      arrFrom.splice(index, 1);
      arrTo.push(item);
    },
    async selectChangeRemove() {
      const t = Chart.getChart("planet-chart");
      var found = t.data.labels.indexOf(this.selectedActive[0].text);
      t.data.labels.splice(found, 1);
      t.data.datasets.forEach((dataset) => {
        dataset.data.splice(found, 1);
      });
      t.update();

      this.move(
        this.selectedActive[0].code,
        this.optionsActive,
        this.filterOptions
      );
    },
    async selectChange() {
      const locationCode = { locationCode: this.selected[0].code };
      var stat = await this.GET_LOCATION_STATS(locationCode);
      if (!stat) {
        this.selected[0].text = this.selected[0].text + "(No Data)";
        return;
      }

      this.move(this.selected[0].code, this.filterOptions, this.optionsActive);
      const t = Chart.getChart("planet-chart");
      t.data.labels.push(stat.name);
      t.data.datasets.forEach((dataset) => {
        switch (dataset.label) {
          case "Aktuelle fejl":
            dataset.data.push(stat.openIssues);
            break;
          case "Afsluttet fejl":
            dataset.data.push(stat.closedIssues);
            break;
          case "Aktuelle eftersyn":
            dataset.data.push(stat.openTasks);
            break;
          case "Afsluttet eftersyn":
            dataset.data.push(stat.closedTasks);
            break;
        }
      });
      t.update();
    },
    crate() {
      var planetChartData = {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Aktuelle fejl",
              data: [],
              backgroundColor: "#AD2A2A",
              borderColor: "#AD2A2A",
              borderWidth: 3,
            },
            {
              label: "Afsluttet fejl",
              data: [],
              backgroundColor: "#885073",
              borderColor: "#885073",
              borderWidth: 3,
            },
            {
              label: "Aktuelle eftersyn",
              data: [],
              backgroundColor: "#435254",
              borderColor: "#435254",
              borderWidth: 3,
            },
            {
              label: "Afsluttet eftersyn",
              data: [],
              backgroundColor: "#80BBAD",
              borderColor: "#80BBAD",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      };
      const ctx = document.getElementById("planet-chart");
      const chart = new Chart(ctx, planetChartData);
      console.log(chart);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.select {
  position: relative;
  width: 250px;
  text-align: left;
  line-height: var(--input--height);
  z-index: 2;
  height: 150px;

  &--open {
    z-index: 3;
  }

  &--dynamic-width {
    &:focus,
    &:focus-visible {
      outline: 0;
    }
  }
}
</style>
