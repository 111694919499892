export const FILTER_ISSUE = "FILTER_ISSUE";
export const RESET_INSTALLATIONS = "RESET_INSTALLATIONS";
export const RESET_TABLE_SEARCH_QUERY = "RESET_TABLE_SEARCH_QUERY";
export const SET_ACTIVE_CUSTOMER = "SET_ACTIVE_CUSTOMER";
export const SET_ACTIVE_INSTALLATION = "SET_ACTIVE_INSTALLATION";
export const SET_ACTIVE_INSTALLATION_FILE = "SET_ACTIVE_INSTALLATION_FILE";
export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
export const SET_ACTIVE_LOCATION = "SET_ACTIVE_LOCATION";
export const SET_ACTIVE_LOCATION_DETAILS = "SET_ACTIVE_LOCATION_DETAILS";
export const SET_ACTIVE_LOCATION_FILES = "SET_ACTIVE_LOCATION_FILES";
export const SET_ACTIVE_OVERLAY = "SET_ACTIVE_OVERLAY";
export const SET_ACTIVE_REPORT = "SET_ACTIVE_REPORT";
export const SET_ACTIVE_ROW_ID = "SET_ACTIVE_ROW_ID";
export const SET_ACTIVE_TOKEN = "SET_ACTIVE_TOKEN";
export const SET_AUTHENTICATION_CUSTOMER = "SET_AUTHENTICATION_CUSTOMER";
export const SET_AUTHENTICATION_TOKEN = "SET_AUTHENTICATION_TOKEN";
export const SET_DICTIONARY = "SET_DICTIONARY";
export const SET_INSTALLATION_FILES = "SET_INSTALLATION_FILES";
export const SET_INSTALLATION_ISSUES = "SET_INSTALLATION_ISSUES";
export const SET_INSTALLATION_TASKS = "SET_INSTALLATION_TASKS";
export const SET_INSTALLATIONS = "SET_INSTALLATIONS";
export const SET_ISSUES = "SET_ISSUES";
export const SET_ISSUES_THERMOGRAPHY = "SET_ISSUES_THERMOGRAPHY";
export const SET_ISSUE_STATUS = "SET_ISSUE_STATUS";
export const SET_FILTER_ISSUES_LOCATIONS = "SET_FILTER_ISSUES_LOCATIONS";
export const SET_FILTER_TASKS_LOCATIONS = "SET_FILTER_TASKS_LOCATIONS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_REPORT_FILES_URL = "SET_REPORT_FILES_URL";
export const SET_SELECT_FILTER = "SET_SELECT_FILTER";
export const SET_SELECTED_ISSUE_ROWS = "SET_SELECTED_ISSUE_ROWS";
export const SET_SELECTED_TASK_ROWS = "SET_SELECTED_TASK_ROWS";
export const SET_TABLE_SEARCH_QUERY = "SET_TABLE_SEARCH_QUERY";
export const SET_TASKS = "SET_TASKS";
export const SET_TASK_STATUS = "SET_TASK_STATUS";
export const SET_PLANNED_TASKS = "SET_PLANNED_TASKS";
export const SET_TABLE_ROW_MODEL = "SET_TABLE_ROW_MODEL";
export const SET_GRID_HEIGHT = "SET_GRID_HEIGHT";
export const SET_OLD_TOP = "SET_OLD_TOP";
export const SET_FILTER_COLLECTION = "SET_FILTER_COLLECTION";
export const RESET_TIME_INTERVAL = "RESET_TIME_INTERVAL";
export const SET_TIME_INTERVAL = "SET_TIME_INTERVAL";
export const SET_NEWSFEEDS = "SET_NEWSFEEDS";
export const SET_ACTIVE_NEWSFEED_ID = "SET_ACTIVE_NEWSFEED_ID";
export const SET_INSTALLATIONS_PROCESSING = "SET_INSTALLATIONS_PROCESSING";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
