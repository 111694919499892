<template>
  <div>
    <div class="table-container__header">
      <div class="table-container__header-left">
        <div
          v-if="tableType === 'ISSUE' || tableType === 'ISSUE_THERMOGRAPHY'"
          class="table-container__header--filters"
        >
          <LabelStatus :color="'red'">
            <h2>
              {{ tableName }}
            </h2>
          </LabelStatus>

          <div class="table-container__filter-tabs">
            <FilterTabs :options="options" @click-tab="handleFilterTabClick" />
          </div>
        </div>
        <div
          v-if="tableType === 'TASK'"
          class="table-container__header--filters"
        >
          <LabelStatus :color="'yellow'">
            <h2>
              {{ tableName }}
            </h2>
          </LabelStatus>

          <div class="table-container__filter-tabs">
            <FilterTabs :options="options" @click-tab="handleFilterTabClick" />
          </div>
        </div>
        <div
          v-if="
            tableType === 'TASKS_PLANNED_DASHBOARD' ||
            tableType === 'NEWSFEED_DASHBOARD'
          "
          class="table-container__header--filters"
          style="flex-direction: column"
        >
          <h2 style="margin-top: 2rem">
            {{ tableName }}
          </h2>
          <div
            style="display: flex; flex-direction: column"
            v-if="tableType === 'NEWSFEED_DASHBOARD'"
          >
            <ModalNewsfeedSubscription></ModalNewsfeedSubscription>
            <br />
          </div>
          <div class="table-container__filter-tabs">
            <FilterTabs :options="options" @click-tab="handleFilterTabClick" />
          </div>
        </div>
      </div>
      <div v-if="tableData" class="table-container__header-right">
        <LabelPagination :array="tableData" :tablePageSize="tablePageSize" />
        <Link :arrow="true" :url="seeAllLink">{{
          getDictionaryEntry("tableContainer.viewAllLink")
        }}</Link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import FilterTabs from "./FilterTabs.vue";
import LabelPagination from "./LabelPagination.vue";
import LabelStatus from "./LabelStatus.vue";
import Link from "./Link.vue";
import Button from "./Button.vue";
import Modal from "./Modal.vue";
import ModalNewsfeedSubscription from "./modals/ModalNewsfeedSubscription.vue"
import { createNamespacedHelpers } from "vuex";
const issue = createNamespacedHelpers("issue");
const authentication = createNamespacedHelpers("authentication");
const employee = createNamespacedHelpers("employee");

export default {
  name: "TableContainerHeaderDashboard",
  components: {
    FilterTabs,
    LabelPagination,
    LabelStatus,
    Link,
    Button,
    Modal,
    ModalNewsfeedSubscription
  },
  data() {
    return {
      checked: false,
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
    tableName: {
      type: String,
      default: null,
    },
    tablePageSize: {
      type: Number,
      default: null,
    },
    isDashboard: {
      type: Boolean,
      default: true,
    },
    seeAllLink: {
      type: String,
      default: "\/",
    },
  },
  computed: {
    ...authentication.mapGetters(["activeCustomer"]),
    ...employee.mapGetters(["isSubscribed", "isRequestBusy"]),
    options: function () {
      if (
        this.tableType === "ISSUE" ||
        this.tableType === "ISSUE_THERMOGRAPHY"
      ) {
        return [
          {
            text: this.getDictionaryEntry("issuesNoAction.filterTabs.all"),
            id: "issue-tab-all",
          },
          {
            text: this.getDictionaryEntry("issuesNoAction.filterTabs.thermo"),
            id: "issue-tab-thermography",
          },
        ];
      }
      return [];
    },
  },
  watch: {
    isSubscribed: function () {
      this.checked = this.isSubscribed;
    },
  },
  created() {
    this.checked = this.isSubscribed;
  },
  methods: {
    ...issue.mapMutations(["FILTER_ISSUE"]),
    ...employee.mapActions([
      "SUBSCRIBE_TO_NEWSFEED",
      "UNSUBSCRIBE_FROM_NEWSFEED",
    ]),
    handleFilterTabClick(option) {
      this.$emit("click-tab", option); // emitting because event doesn't bubble
    },    
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.table-container__header {
  display: flex;
  height: 8.6rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(var(--spacing-table--padding-x) + 0.4rem);
  border-bottom: var(--border--lg) solid var(--color-grey--light);

  &--filters {
    display: flex;
  }
}

.table-container__filter-tabs {
  display: none;

  @include tablet-portrait {
    display: block;
  }
}

.table-container__header-left {
  display: flex;
  align-items: center;
}

.table-container__header-right {
  display: none;

  @include tablet-portrait {
    display: flex;
  }
}

.table-container__table-name {
  width: max-content;
  font-size: 1.8rem;
  font-weight: var(--font-weight--semibold);
}

.table-container__header-right {
  & > *:first-child {
    margin-right: 2rem;
  }
}
</style>
