<template>
  <div class="filter-inputs__filters">
    <div v-if="showFilterTabs" class="filter-inputs__filters-left">
      <FilterTabs :options="options" @click-tab="handleFilterTabClick" />
    </div>
    <div v-else></div>
    <div class="filter-inputs__filters-right">
      <div
        v-if="showFilterTabs"
        class="filter-inputs__filter filter-inputs__filter--mobile"
      >
        <Select :options="options" :default-selected="options[0]" />
      </div>
      <div style="width: 400px" v-if="showFilterTabs">
        <MultiselectTags :table-type="tableType" :id="opId" />
      </div>
      <div class="filter-inputs__filter filter-inputs__filter--type">
        <Select
          :default-selected="{
            text: getDictionaryEntry(
              'tableContainer.filterInstallationType.defaultText'
            ),
          }"
          :options="filterList"
          @input="handleSelectInput"
        />
      </div>
      <div class="filter-inputs__filter filter-inputs__filter--search">
        <Input
          :name="'search'"
          :placeholder="getDictionaryEntry('searchField.placeholder')"
          :model="searchField"
          @input="handleSearchInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import DatePicker from "./DatePicker";
import Input from "./Input.vue";
import Select from "./Select.vue";
import MultiselectTags from "./MultiselectTags.vue";
import { getUniqueList } from "../helpers/filter";
import { dateFrom, dateTo } from "../helpers/datePicker";
import { createNamespacedHelpers } from "vuex";
import FilterTabs from "./FilterTabs";
const issue = createNamespacedHelpers("issue");
const table = createNamespacedHelpers("table");
const task = createNamespacedHelpers("task");

export default {
  name: "TableContainerFilters",
  components: {
    // DatePicker,
    Input,
    Select,
    FilterTabs,
    MultiselectTags,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
  },
  data() {
    var list = getUniqueList(this.tableData, this.tableType);
    return {
      dateFrom: dateFrom,
      dateTo: dateTo,
      filterList: list,
      opId: "HEJ",
    };
  },
  computed: {
    ...table.mapGetters(["searchField", "filterCollection"]),
    showFilterTabs: function () {
      if (
        this.tableType === "ISSUE" ||
        this.tableType === "TASK" ||
        this.tableType === "COMPLETED_TASK"
      ) {
        return true;
      } else {
        return false;
      }
    },
    options: function () {
      if (
        this.tableType === "ISSUE" ||
        this.tableType === "ISSUE_THERMOGRAPHY"
      ) {
        return [
          {
            text: this.getDictionaryEntry("issuesNoAction.filterTabs.all"),
            id: "issue-tab-all",
          },
          {
            text: this.getDictionaryEntry("issuesNoAction.filterTabs.thermo"),
            id: "issue-tab-thermography",
          },
        ];
      }
      return [];
    },
  },
  watch: {
    filterCollection: function () {
      this.filterList = getUniqueList(this.tableData, this.tableType);
    },
  },
  emits: ["update:id"],
  methods: {
    ...issue.mapActions([
      "UPDATE_TIMEINTERVAL",
      "GET_ISSUE_LIST_FROM_DATES",
      "GET_ISSUE_THERMOGRAPHY",
    ]),
    ...issue.mapMutations(["FILTER_ISSUE"]),
    ...table.mapActions(["UPDATE_TABLE_SEARCH_QUERY", "UPDATE_SELECT_FILTER"]),
    ...task.mapActions(["GET_TASK_LIST_FROM_DATES"]),
    ...task.mapMutations(["FILTER_TASKS"]),
    handleFilterTabClick(option) {
      this.$emit("click-tab", option); // emitting because event doesn't bubble
      this.opId = option.id;
    },
    handleSearchInput(e) {
      this.UPDATE_TABLE_SEARCH_QUERY(e.target.value);
    },
    handleSelectInput(option) {
      if (
        this.getDictionaryEntry(
          "tableContainer.filterInstallationType.defaultText"
        ) !== option.text
      ) {
        this.UPDATE_SELECT_FILTER(option.text);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.filter-inputs__filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 20;
}

.filter-inputs__filters-left {
  width: 100%;
  display: block;

  @media screen and (min-width: 1500px) {
    width: unset;
    display: block;
  }
}

.filter-inputs__filters-right {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  grid-template-areas:
    "item"
    "item"
    "item"
    "item";
  padding-top: 2rem;

  @include tablet-portrait {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "item item"
      "item item";
  }

  @media screen and (min-width: 1500px) {
    width: initial;
    justify-content: space-between;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    grid-template-areas: "item item item";
    padding: 0;
  }
}

.filter-inputs__filter {
  @media screen and (min-width: 1500px) {
    min-width: 16.5rem;
  }

  &--desktop {
    display: none;

    @include tablet-portrait {
      display: block;
    }
  }

  &--mobile {
    @include tablet-portrait {
      display: none;
    }
  }

  &--type {
    @media screen and (min-width: 1500px) {
      min-width: 20rem;
    }
  }

  &--search {
    @media screen and (min-width: 1500px) {
      width: 16.5rem;
    }
  }
}

.filter-inputs__date-label {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: var(--font-weight--semibold);
}
</style>
