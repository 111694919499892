import axios from "../../service/api";
import { GET_NOTIFICATIONS } from "@/store/action-types";
import { ADD_NOTIFICATIONS } from "@/store/action-types";
import { DELETE_NOTIFICATIONS } from "@/store/action-types";
import store from "@/store";
export default {
  namespaced: true,
  actions: {
    [DELETE_NOTIFICATIONS](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      return axios
        .delete(
          `${process.env.VUE_APP_API_URL}/notification/${customerId}/removeNotification/${payload.notificationId}`
        )
        .then((data) => {
          return data.data;
        });
    },
    [GET_NOTIFICATIONS](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      const locationCode = payload.locationReferenceCode;
      var url = `${process.env.VUE_APP_API_URL}/notification/${customerId}/GetNotification/${locationCode}`;

      if (locationCode === "") {
        url = `${process.env.VUE_APP_API_URL}/notification/${customerId}/GetNotification`;
      }

      return axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          dataType: "json",
        })
        .then((data) => {
          return data.data;
        });
    },
    [ADD_NOTIFICATIONS](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/notification/${customerId}/AddNotification`,
          {
            id: 0,
            customerId: customerId,
            name: payload.name,
            email: payload.email,
            type: payload.type,
            locationReferenceCode: payload.locationReferenceCode,
            culture: payload.culture,
          }
        )
        .then((data) => {
          return data.data;
        });
    },
  },
};
