<template>
  <ul v-if="files" class="installation-files">
    <li
      v-for="(file, index) in files"
      :key="'file' + index"
      class="installation-files__item"
    >
      <div
        class="installation-files__item-icon"
        :title="getDictionaryEntry('text.deletefile')"
        @:click="handleDeleteFile(index)"
      >
        <div v-if="file.isDeleting === true">
          <img style="width: 11px" src="../assets/loading.gif" />
        </div>
        <div v-else>
          <IconTrash @click="() => handleDeleteFile(index)" />
        </div>
      </div>
      <a target="_blank" :key="file.url" :href="file.url">{{
        file.displayName
      }}</a>
    </li>
    <li
      class="installation-files__item installation-files__item--upload"
      v-on:click="handleUploadFiles"
    >
      <div class="installation-files__item-icon">
        <IconUpload />
      </div>
      <div>{{ getDictionaryEntry("tableInstallation.linkUploadFile") }}</div>
    </li>
  </ul>
</template>

<script>
import IconTrash from "./icons/IconTrash.vue";
import IconUpload from "./icons/IconUpload.vue";
import { createNamespacedHelpers } from "vuex";
const installation = createNamespacedHelpers("installation");
const table = createNamespacedHelpers("table");
const location = createNamespacedHelpers("location");
const modal = createNamespacedHelpers("modal");

export default {
  name: "InstallationFiles",
  components: {
    IconTrash,
    IconUpload,
  },
  data() {
    return {
      files: null,
      installation: null,
    };
  },
  created() {
    var pageIndex = this.params.node.id % 10;
    if (pageIndex === 0) {
      // reset grid height
      this.UPDATE_GRID_HEIGHT(91); // ag-grid footer + header height
      this.UPDATE_OLD_TOP(0);
    }

    var pageOffset = 0;
    if (this.params.node.id >= 10) {
      pageOffset = (this.params.node.id / 10) * 500;
    }

    // todo: abstraher det her ud.
    if (this.params.data != undefined) {
      var rowHeight = 50 + this.params.data.files.length * 30;

      // get the old rowtop  + pageOffset;
      if (this.params.node.id >= 10) {
        pageOffset = Math.floor(this.params.node.id / 10) * 500;
      }

      var rowTop = this.oldTop + pageOffset;
      this.params.node.setRowHeight(rowHeight);
      this.params.api.onRowHeightChanged(); // No effect with infinite rowmodel.
      this.params.node.setRowTop(rowTop);

      this.INCREMENT_GRID_HEIGHT(rowHeight);
      this.installation = this.params.data;
      this.files = this.installation.files;
    }
  },
  computed: {
    ...table.mapGetters(["oldTop"]),
    ...location.mapGetters(["activeLocation"]),
  },
  methods: {
    ...installation.mapActions([
      "GET_INSTALLATION_LIST",
      "UPDATE_ACTIVE_INSTALLATION_FILE",
      "UPDATE_ACTIVE_INSTALLATION",
      "DELETE_INSTALLATION_FILE",
    ]),
    ...table.mapActions([
      "UPDATE_GRID_HEIGHT",
      "INCREMENT_GRID_HEIGHT",
      "UPDATE_OLD_TOP",
    ]),
    ...modal.mapMutations(["SET_MODAL_CONTENT"]),
    handleUploadFiles() {
      this.UPDATE_ACTIVE_INSTALLATION(this.installation);
      this.UPDATE_ACTIVE_INSTALLATION_FILE(this.installation.id);
    },
    handleDeleteFile(index) {
      // emit some event in vuex that open the modal
      this.$store.commit("REQUEST_MODAL_OPENED", true);
      this.SET_MODAL_CONTENT({
        locationRef: this.activeLocation.code,
        installationId: this.installation.id,
        installationCode: this.installation.code,
        fileName: this.installation.files[index].fileName,
        displayName: this.installation.files[index].displayName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.installation-files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.7rem;
}

.installation-files__item {
  position: relative;
  font-size: 1.3rem;
  line-height: 1;
  padding-bottom: 1.3rem;
  padding-left: 2rem;

  &--upload {
    color: var(--color-blue);
    font-weight: var(--font-weight--semibold);

    &:hover {
      cursor: pointer;
    }
  }

  &--hide {
    opacity: 0;
  }
}

.installation-files__item-link {
  color: var(--color-blue);
}

.installation-files__item-icon {
  width: 1.1rem;
  position: absolute;
  left: 0;
  color: var(--color-red);

  &:hover {
    cursor: pointer;
  }

  .installation-files__item--upload & {
    color: var(--color-blue);
  }
}
</style>
