<template>
  <div
    v-if="customer"
    class="location-block"
    :class="{
      'location-block--secondary': secondary,
      'location-block--selected': selected,
    }"
    v-on:click="handleCustomerClick"
  >
    <div class="location-block__content">
      <div class="location-block__name">{{ customer.name }}</div>
      <div class="location-block__code">{{ customer.xalId }}</div>
    </div>
    <div v-if="secondary" class="location-block__icon">
      <IconChevron />
    </div>
  </div>
</template>

<script>
import IconChevron from "./icons/IconChevron.vue";
import { createNamespacedHelpers } from "vuex";
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "CustomerBlock",
  components: {
    IconChevron,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...authentication.mapActions(["REQUEST_NEW_TOKEN_CUSTOMER"]),
    handleCustomerClick() {
      this.REQUEST_NEW_TOKEN_CUSTOMER({
        customerId: this.customer.id,
      }).then(() => {
        this.$emit("customer-click", this.customer);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.location-block {
  position: relative;
  height: var(--accordion--height);
  display: flex;
  align-items: center;
  padding: 0 1.8rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-grey--light);
  border-radius: var(--border-radius);
  color: var(--color-primary);

  @include tablet-portrait {
    flex-direction: row;
    align-items: unset;
    padding: 0 calc(2.1rem + var(--accordion-item-trigger--width)) 0 1.8rem;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-grey--extralight);
  }

  &--secondary {
    border: 0.1rem solid var(--color-grey--dark);
    background-color: var(--color-white);
    margin: 0 1.7rem;
  }

  &--selected {
    border: 0.2rem solid var(--color-green);
    background-color: var(--color-grey--extralight);
  }
}

.location-block__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.location-block__name {
  font-size: 1.4rem;
  font-weight: var(--font-weight--semibold);
  margin-bottom: 0.2rem;

  @include tablet-portrait {
    font-size: 1.6rem;
  }
}

.location-block__code {
  font-size: 1.1rem;

  @include tablet-portrait {
    font-size: 1.2rem;
  }
}

.location-block__status-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include tablet-portrait {
    flex-direction: row;
    align-items: unset;
  }
}

.location-block__status {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  text-align: right;
  margin: 0.5rem 0;

  @include tablet-portrait {
    margin: 0;
    margin-right: 2.7rem;
    font-size: 1.6rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1.1rem;
    border-radius: 50%;

    @include tablet-portrait {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--red::after {
    background-color: var(--color-red);
  }

  &--yellow::after {
    background-color: var(--color-yellow);
  }

  &--disabled {
    opacity: 0.1;
  }
}

.location-block__icon {
  position: absolute;
  top: 2rem;
  right: 3rem;
  width: 1.3rem;
  transform: rotate(-90deg);
}
</style>
