import axios from "../../service/api";

export default {
  namespaced: true,
  state: {
    subscribeRequestBusy: false,
  },
  getters: {
    isSubscribed: (state, getters, rootState) => {
      var isSubscribed = rootState.authentication.activeCustomer.newsFeed;
      if (isSubscribed === "True") {
        return true;
      }
      return false;
    },
    isRequestBusy: (state) => state.subscribeRequestBusy,
  },
  actions: {
    ["SUBSCRIBE_TO_NEWSFEED"]({ dispatch, rootState, commit }) {
      let appId = 5; // Legislative App has appId 5
      commit("SET_REQUEST_BUSY", true);
      return axios
        .put(
          `${process.env.VUE_APP_API_URL}/newsfeed/employee/${rootState.authentication.activeCustomer.employeeId}/add/${appId}`
        )
        .then(() => {
          dispatch("authentication/REQUEST_NEW_TOKEN", null, { root: true });
          commit("SET_REQUEST_BUSY", false);
        });
    },
    ["UNSUBSCRIBE_FROM_NEWSFEED"]({ rootState, dispatch, commit }) {
      let appId = 5; // Legislative App has appId 5
      commit("SET_REQUEST_BUSY", true);
      return axios
        .put(
          `${process.env.VUE_APP_API_URL}/newsfeed/employee/${rootState.authentication.activeCustomer.employeeId}/remove/${appId}`
        )
        .then(() => {
          dispatch("authentication/REQUEST_NEW_TOKEN", null, { root: true });
          commit("SET_REQUEST_BUSY", false);
        });
    },
  },
  mutations: {
    ["SET_REQUEST_BUSY"](state, payload) {
      state.subscribeRequestBusy = payload;
    },
  },
};
