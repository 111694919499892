import timeFilter from "./table/timeFilter";
import { getInitialIntervalByPage } from "../../helpers/table/timeIntervalHelper.js";

import {
  RESET_TABLE_SEARCH_QUERY,
  SET_TABLE_SEARCH_QUERY,
  SET_SELECTED_ISSUE_ROWS,
  SET_SELECTED_TASK_ROWS,
  SET_SELECT_FILTER,
  SET_ACTIVE_ROW_ID,
  SET_TABLE_ROW_MODEL,
  SET_GRID_HEIGHT,
  SET_OLD_TOP,
  SET_FILTER_COLLECTION,
} from "../mutation-types";
import {
  CLEAR_TABLE_SEARCH_QUERY,
  UPDATE_TABLE_SEARCH_QUERY,
  UPDATE_SELECTED_ISSUE_ROWS,
  UPDATE_SELECTED_TASK_ROWS,
  UPDATE_SELECT_FILTER,
  UPDATE_ACTIVE_ROW_ID,
  UPDATE_ROWMODEL,
  UPDATE_GRID_HEIGHT,
  INCREMENT_GRID_HEIGHT,
  UPDATE_OLD_TOP,
  UPDATE_TIME_INTERVAL,
  HANDLE_PAGE_CHANGE,
} from "../action-types";

export default {
  namespaced: true,
  state: {
    tableSearchQuery: null,
    selectedIssueRows: null,
    selectedTaskRows: null,
    activeRowId: null,
    searchField: null,
    selectFilter: "",
    isTimeFilterSet: false,
    filterCollection: null,
    gridHeight: 0,
    oldTop: 0,
    rowModel: null,
    tableType: null,
    timeInterval: null,
  },
  getters: {
    tableSearchQuery: (state) => state.tableSearchQuery,
    selectedIssueRows: (state) => state.selectedIssueRows,
    selectedTaskRows: (state) => state.selectedTaskRows,
    activeRowId: (state) => state.activeRowId,
    searchField: (state) => state.searchField,
    selectFilter: (state) => state.selectFilter,
    rowModel: (state) => state.rowModel,
    gridHeight: (state) => state.gridHeight,
    oldTop: (state) => state.oldTop,
    filterCollection: (state) => state.filterCollection,
    isTimeFilterSet: (state) => state.timeFilter.isTimeFilterSet,
    getTimeInterval: (state) => state.timeFilter.timeInterval,
  },
  actions: {
    [CLEAR_TABLE_SEARCH_QUERY]({ commit }) {
      commit(RESET_TABLE_SEARCH_QUERY);
    },
    [UPDATE_TABLE_SEARCH_QUERY]({ commit }, payload) {
      commit(SET_TABLE_SEARCH_QUERY, payload);
    },
    [UPDATE_SELECTED_ISSUE_ROWS]({ commit }, payload) {
      commit(SET_SELECTED_ISSUE_ROWS, payload);
    },
    [UPDATE_SELECTED_TASK_ROWS]({ commit }, payload) {
      commit(SET_SELECTED_TASK_ROWS, payload);
    },
    [UPDATE_SELECT_FILTER]({ commit }, payload) {
      commit(SET_SELECT_FILTER, payload);
    },
    [UPDATE_ACTIVE_ROW_ID]({ commit }, payload) {
      commit(SET_ACTIVE_ROW_ID, payload);
    },
    [UPDATE_ROWMODEL]({ commit }, payload) {
      commit(SET_TABLE_ROW_MODEL, payload);
    },
    [UPDATE_GRID_HEIGHT]({ commit }, payload) {
      commit(SET_GRID_HEIGHT, payload);
    },
    [INCREMENT_GRID_HEIGHT]({ commit, state }, payload) {
      var currentGridHeight = state.gridHeight;
      commit(SET_GRID_HEIGHT, currentGridHeight + payload);
      var currentTop = state.oldTop;
      commit(SET_OLD_TOP, currentTop + payload);
    },
    [UPDATE_OLD_TOP]({ commit }, payload) {
      commit(SET_OLD_TOP, payload);
    },
    [UPDATE_TIME_INTERVAL]({ commit }, payload) {
      commit("timeFilter/SET_TIME_INTERVAL", payload);
    },
    [HANDLE_PAGE_CHANGE]({ commit }, payload) {
      var timeinterval = getInitialIntervalByPage(payload.name);
      commit("timeFilter/SET_TIME_INTERVAL", timeinterval);
    },
  },
  mutations: {
    [RESET_TABLE_SEARCH_QUERY](state) {
      state.searchField = "";
      state.tableSearchQuery = null;
      state.selectFilter = "";
    },
    [SET_TABLE_SEARCH_QUERY](state, payload) {
      state.searchField = payload;
      state.tableSearchQuery = `${state.selectFilter} ${payload}`;
    },
    [SET_SELECTED_ISSUE_ROWS](state, payload) {
      state.selectedIssueRows = payload;
    },
    [SET_SELECTED_TASK_ROWS](state, payload) {
      state.selectedTaskRows = payload;
    },
    [SET_SELECT_FILTER](state, payload) {
      state.selectFilter = payload;
      state.tableSearchQuery = `${payload} ${state.searchField}`;
    },
    [SET_ACTIVE_ROW_ID](state, payload) {
      state.activeRowId = payload;
    },
    [SET_TABLE_ROW_MODEL](state, payload) {
      state.rowModel = payload;
    },
    [SET_GRID_HEIGHT](state, payload) {
      state.gridHeight = payload;
    },
    [SET_OLD_TOP](state, payload) {
      state.oldTop = payload;
    },
    [SET_FILTER_COLLECTION](state, payload) {
      state.filterCollection = payload;
    },
  },
  modules: {
    timeFilter: timeFilter,
  },
};
