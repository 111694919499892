<template>
  <ul class="check-list">
    <li
      v-for="(check, index) in checkList"
      :key="index"
      class="check-list__item"
    >
      <div class="check-list__item-content">
        <div class="check-list__item-text check-list__text check-list__title">
          {{ this.getDictionaryEntry(check.name.toUpperCase()) }}:
        </div>
        <div>
          <div v-if="check.name.includes('.check')">
            <div v-if="check.completed === '1'" class="check-list__item-icon">
              <IconCheckMark />
            </div>
          </div>
          <div v-else style="display: flex">
            <div
              class="check-list__item-text check-list__text check-list__value"
            >
              {{ check.completed }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import IconCheckMark from "./icons/iconCheckMark.vue";

export default {
  name: "CheckList",
  components: {
    IconCheckMark,
  },
  data() {
    return {
      checkList: null,
    };
  },
  beforeMount() {
    this.checkList = this.params.data.checklist;
  },
};
</script>

<style lang="scss" scoped>
.check-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.check-list__item-content {
  display: flex;
  flex-direction: row;
  min-width: 160px;
}

.check-list__title {
  flex-grow: 2;
}

.check-list__value {
  flex-grow: 1;
  min-width: 50px;
  text-align: end;
}

.check-list__text {
  font-size: 8pt;
}

.check-list__item {
  position: relative;
  font-size: 1.3rem;
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #aaa;
}

.check-list__item-icon {
  width: 1rem;
  left: 0;
  color: var(--color-green);
}
</style>
