<template>
  <div v-if="dictionaryEntries" class="dashboard">
    <div style="display: flex">
      <!-- column for Keynumbers -->
      <div style="flex-grow: 1; max-width: 50%">
        <div class="dashboard__item">
          <CustomerInfo />
        </div>
        <div class="dashboard__item">
          <TransitionFade>
            <KeyNumbers />
          </TransitionFade>
        </div>
      </div>

      <!-- column for news -->
      <div style="flex-grow: 1">
        <div class="dashboard__item">
          <TransitionFade>
            <TableContainer
              v-if="newsFeedCollection"
              :is-dashboard="true"
              :see-all-link="'/nyheder'"
              :table-data="newsFeedCollection"
              :table-name="
                getDictionaryEntry('dashboard.tableNewsFeed.headerText')
              "
              :table-page-size="3"
              :table-type="'NEWSFEED_DASHBOARD'"
              :is-simple-table="true"
            />
          </TransitionFade>
        </div>
      </div>
    </div>

    <div class="dashboard__item">
      <TransitionFade>
        <TableContainer
          v-if="issues"
          :is-dashboard="true"
          :see-all-link="'/aktuelle-fejl'"
          :table-data="issues"
          :table-name="getDictionaryEntry('dashboard.tableIssue.headerText')"
          :table-page-size="5"
          :table-type="'ISSUE'"
        />
      </TransitionFade>
    </div>
    <div class="dashboard__item">
      <TransitionFade>
        <TableContainer
          v-if="tasksUrgent"
          :is-dashboard="true"
          :see-all-link="'/aktuelle-eftersyn'"
          :table-data="tasksUrgent"
          :table-name="getDictionaryEntry('dashboard.tableTask.headerText')"
          :table-page-size="5"
          :table-type="'TASK'"
        />
      </TransitionFade>
    </div>

    <div class="dashboard__item">
      <TransitionFade>
        <TableContainer
          v-if="tasksPlanned"
          :is-dashboard="true"
          :see-all-link="'/kommende-eftersyn'"
          :table-data="tasksPlanned"
          :table-name="'Kommende eftersyn'"
          :table-page-size="5"
          :table-type="'TASKS_PLANNED_DASHBOARD'"
        />
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import CustomerInfo from "../components/CustomerInfo.vue";
import KeyNumbers from "../components/KeyNumbers.vue";
import TableContainer from "../components/TableContainer.vue";
import TransitionFade from "../components/TransitionFade.vue";
import { createNamespacedHelpers } from "vuex";
const issue = createNamespacedHelpers("issue");
const task = createNamespacedHelpers("task");
const location = createNamespacedHelpers("location");
const dictionary = createNamespacedHelpers("dictionary");
const newsFeed = createNamespacedHelpers("newsfeed");

export default {
  name: "Dashboard",
  components: {
    KeyNumbers,
    TableContainer,
    CustomerInfo,
    TransitionFade,
  },
  computed: {
    ...issue.mapGetters({ issues: "issuesNoAction" }),
    ...task.mapGetters(["tasksPlanned", "tasksUrgent"]),
    ...location.mapGetters(["activeLocation"]),
    ...dictionary.mapGetters(["dictionaryEntries"]),
    ...newsFeed.mapGetters({ newsFeedCollection: "newsFeedCollection" }),
  },
  watch: {
    activeLocation: function () {
      this.GET_NEWSFEEDS();
      this.GET_TASK_LIST();
      this.GET_ISSUE_LIST();
      this.GET_ISSUE_THERMOGRAPHY();
      this.GET_TASKS_PLANNED();
    },
  },
  created() {
    this.SET_ISSUE_STATUS("DASHBOARD");
    this.SET_TASK_STATUS("DASHBOARD");

    if (this.activeLocation) {
      this.SET_TASK_STATUS("DASHBOARD");
      this.SET_ISSUE_STATUS("DASHBOARD");
      this.GET_NEWSFEEDS();
      this.GET_TASK_LIST();
      this.GET_ISSUE_LIST();
      this.GET_ISSUE_THERMOGRAPHY();
      this.GET_TASKS_PLANNED();
    }
  },
  methods: {
    ...issue.mapActions(["GET_ISSUE_THERMOGRAPHY", "GET_ISSUE_LIST"]),
    ...issue.mapMutations(["SET_ISSUE_STATUS"]),
    ...task.mapActions(["GET_TASK_LIST", "GET_TASKS_PLANNED", ""]),
    ...task.mapMutations(["SET_TASK_STATUS"]),
    ...newsFeed.mapActions(["GET_NEWSFEEDS"]),
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 3rem 0 5rem;
}

.dashboard__item {
  margin-bottom: 3.5rem;
}
</style>
