<template>
  <div class="label-location" v-if="activeLocation">
    <div class="label-location__icon">
      <IconLocation />
    </div>
    <div class="label-location__text">
      {{ activeLocation.name }}
    </div>
  </div>
</template>

<script>
import IconLocation from "./icons/IconLocation.vue";
import { createNamespacedHelpers } from "vuex";
const location = createNamespacedHelpers("location");

export default {
  name: "LabelLocation",
  components: {
    IconLocation,
  },
  computed: {
    ...location.mapGetters(["activeLocation"]),
  },
};
</script>

<style lang="scss" scoped>
.label-location {
  display: flex;
}

.label-location__icon {
  width: 1.3rem;
  color: var(--color-cbre-green);
  margin-right: 1rem;
}

.label-location__text {
  font-weight: var(--font-weight--semibold);
  color: var(--color-cbre-green);
}
</style>
