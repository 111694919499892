<template>
  <div>
    <PlanetCom />
  </div>
</template>

<script>
import PlanetCom from "../components/PlanetChart.vue";
export default {
  name: "Planet",
  components: {
    PlanetCom,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
    };
  },
};
</script>
