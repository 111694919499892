<template>
  <div></div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("authentication");

export default {
  name: "Login",
  computed: {
    ...mapGetters(["accessToken"]),
  },
  created() {
    if (this.isUserAuthenticated) {
      this.$router.push({
        name: this.getStartPageForCurrentUser(),
      });
    } else {
      this.AUTHENTICATE();
    }
  },
  methods: {
    ...mapActions(["AUTHENTICATE"]),
  },
};
</script>
