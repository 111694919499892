import { createNamespacedHelpers } from "vuex";

const dictionary = createNamespacedHelpers("dictionary");

export default {
  computed: {
    ...dictionary.mapGetters(["dictionaryEntries"]),
    getDictionaryEntry: function () {
      const { dictionaryEntries } = this;

      return (key) => {
        if (!dictionaryEntries || dictionaryEntries[key] === undefined) {
          return key;
        } else if (this.$route.query.debugger) {
          return key;
        } else {
          return dictionaryEntries[key];
        }
      };
    },
  },
};
