export default {
  namespaced: true,
  state: {
    content: null,
  },
  getters: {
    modalContent: (state) => state.content,
  },
  mutations: {
    SET_MODAL_CONTENT(state, payload) {
      state.content = payload;
    },
  },
};
