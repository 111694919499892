import Vuex from "vuex";
import authentication from "./modules/authentication";
import dictionary from "./modules/dictionary";
import installation from "./modules/installation";
import issue from "./modules/issue";
import location from "./modules/location";
import customer from "./modules/customer";
import overlay from "./modules/overlay";
import report from "./modules/report";
import table from "./modules/table";
import task from "./modules/task";
import chartdata from "./modules/ChartData";
import notification from "./modules/notification";
import newsfeed from "./modules/newsFeed";
import employee from "./modules/employee";
import modal from "./modules/modals/modal";

export default new Vuex.Store({
  state: {
    isMenuOpen: false,
    isBackdropActive: false,
    isModalOpen: false,
    requestModalToBeOpened: false,
  },
  getters: {
    isMenuOpen: (state) => state.isMenuOpen,
    isOverlayOpen: (state) => state.overlay.isOpen,
    overlayType: (state) => state.overlay.type,
    isBackdropActive: (state) => state.isBackdropActive,
    isModalOpen: (state) => state.isModalOpen,
    requestModalToBeOpened: (state) => state.requestModalToBeOpened,
  },
  mutations: {
    REQUEST_MODAL_OPENED(state, bool) {
      state.requestModalToBeOpened = bool;
    },
    TOGGLE_BACKDROP(state, bool) {
      state.isBackdropActive = bool;
    },
    TOGGLE_MENU(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
    TOGGLE_OVERLAY(state, type) {
      state.isBackdropActive = !state.isBackdropActive;
      state.overlay.type = type;
      state.overlay.isOpen = !state.overlay.isOpen;

      if (!state.overlay.isOpen) {
        this.dispatch("overlay/CLEAR_ACTIVE_OVERLAY");
      }
    },
    TOGGLE_MODAL(state) {
      state.isModalOpen = !state.isModalOpen;
    },
  },
  actions: {
    toggleBackdrop({ commit }, payload) {
      commit("TOGGLE_BACKDROP", payload);
    },
    toggleMenu({ commit }) {
      commit("TOGGLE_MENU");
    },
    toggleOverlay({ commit }, payload) {
      if (payload != "file") {
        document.getElementById("redrawButton").click();
      }
      commit("TOGGLE_OVERLAY", payload);
    },
    toggleModal({ commit }, payload) {
      commit("TOGGLE_MODAL", payload);
    },
  },
  modules: {
    authentication,
    dictionary,
    installation,
    issue,
    location,
    overlay,
    report,
    table,
    task,
    chartdata,
    notification,
    newsfeed,
    employee,
    modal,
    customer,
  },
});
