// Get the initial interval for a view
import { dateAndHoursFormat } from "../dateFormatter";

export function getInitialIntervalByPage(page) {
  let dateFrom = new Date();
  let dateTo = new Date();
  dateFrom.setHours(0, 0, 0);
  dateTo.setHours(23, 59, 59);

  switch (page) {
    case "AfsluttedeFejl":
    case "AfsluttedeEftersyn":
    case "AktuelleEftersyn":
    case "AktuelleFejl":
      dateFrom.setFullYear(dateFrom.getFullYear() - 1);
      break;

    case "KommendeEftersyn":
      dateTo.setMonth(dateTo.getMonth() + 1);
      break;

    default:
  }
  return {
    dateFrom: dateAndHoursFormat(dateFrom),
    dateTo: dateAndHoursFormat(dateTo),
  };
}

export function getMaxInterval() {
  let dateFrom = new Date();
  let dateTo = new Date();
  dateFrom.setFullYear(1900);
  dateTo.setFullYear(3000);
  return {
    dateFrom: dateAndHoursFormat(dateFrom),
    dateTo: dateAndHoursFormat(dateTo),
  };
}

export function getDashboardIntervals(tableType) {
  let dateFrom = new Date();
  let dateTo = new Date();
  dateFrom.setHours(0, 0, 0);
  dateTo.setHours(23, 59, 59);

  switch (tableType) {
    case "urgentIssues":
    case "tasksUrgent":
      dateFrom.setFullYear(dateFrom.getFullYear() - 5);
      dateTo.setFullYear(dateTo.getFullYear() + 1);
      break;

    case "tasksPlanned":
      dateFrom.setFullYear(dateFrom.getFullYear() - 5);
      dateTo.setMonth(dateTo.getMonth() + 1);
      break;

    default:
      return {};
  }
  return {
    dateFrom: dateAndHoursFormat(dateFrom),
    dateTo: dateAndHoursFormat(dateTo),
  };
}
