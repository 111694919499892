<template>
  <div>
    <Modal ref="modalRef">
      <template v-slot:header>
        <h2>Bekræft sletning</h2>
      </template>
      <template v-slot:body>
        <div v-if="this.modalContent">
          <p>Slet følgende fil?</p>
          {{ this.modalContent.displayName }}
          <div style="margin-top: 1rem" v-if="!showDeleting">
            <Button
              @click="deleteFile"
              style="display: inline !important; margin-right: 1rem"
              >OK</Button
            >
            <Button @click="closeModal" style="display: inline !important"
              >Annuller</Button
            >
          </div>
          <div v-else>
            Sletter fil...
            <img style="width: 20px" src="../../../assets/loading.gif" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from "vuex";
import Modal from "../../Modal.vue";
import Button from "../../Button.vue";

const installation = createNamespacedHelpers("installation");
const modal = createNamespacedHelpers("modal");
export default {
  name: "ModalDeleteFileInstallation",
  components: {
    Modal,
    Button,
  },
  computed: {
    ...mapGetters(["requestModalToBeOpened"]),
    ...modal.mapGetters(["modalContent"]),
  },
  data() {
    return {
      showDeleting: false,
    };
  },
  watch: {
    requestModalToBeOpened: function () {
      if (this.requestModalToBeOpened) {
        this.$refs.modalRef.toggleModal();
        this.$store.commit("REQUEST_MODAL_OPENED", false);
      }
    },
  },
  methods: {
    ...installation.mapActions([
      "DELETE_INSTALLATION_FILE",
      "GET_INSTALLATION_LIST",
    ]),
    closeModal: function () {
      this.$refs.modalRef.toggleModal();
    },
    deleteFile: function () {
      this.showDeleting = true;
      this.DELETE_INSTALLATION_FILE(this.modalContent).then(() => {
        this.showDeleting = false;
        this.$refs.modalRef.toggleModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.installation-files__item-icon {
  width: 1.1rem;
  position: absolute;
  left: 0;
  color: var(--color-red);

  &:hover {
    cursor: pointer;
  }
}
</style>
