<template>
  <div>
    <div class="table">
      <div class="table__wrapper">
        <TableOverlaySwipe
          :class="{ 'table-overlay-swipe--disabled': overlaySwipeDisabled }"
        />
        <AgGridVue
          class="ag-theme-alpine"
          :style="{ width: width, height: height }"
          :localeText="localeText"
          :columnDefs="computedColumnDefs"
          :defaultColDef="defaultColDef"
          :domLayout="domLayout"
          :gridApi="gridApi"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :pagination="true"
          :paginationPageSize="tablePageSize"
          :row-selection="rowSelection"
          :rowData="rowData"
          :suppressRowTransform="true"
          :suppressPaginationPanel="paginationSuppression"
          :rowModelType="rowModelType"
          :cacheBlockSize="10"
          :datasource="datasource"
          :suppressHorizontalScroll="false"
          :suppressRowClickSelection="true"
          :suppressScrollOnNewData="false"
          :tooltipShowDelay="tooltipShowDelay"
          @body-scroll="handleScroll"
          @grid-ready="onGridReady"
          @pagination-changed="onPaginationChanged"
          @selection-changed="onSelectionChanged"
        >
        </AgGridVue>
      </div>
      <button
        id="redrawButton"
        ref="redrawButton"
        @click="refreshGrid"
        style="display: none"
      ></button>
    </div>
  </div>
</template>

<script>
/*
  Todo: se på paginationGoToPage for at navigere blandt de mange sider.
*/

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community";
import { AgGridVue } from "ag-grid-vue3";
import CustomToolTip from "./CustomToolTip.vue";
import InstallationDescription from "./InstallationDescription.vue";
import InstallationFiles from "./InstallationFiles.vue";
import InstallationHasOverdue from "./InstallationHasOverdue.vue";
import LinkInstallation from "./LinkInstallation.vue";
import LinkReport from "./LinkReport.vue";
import CrThermographyMore from "./cell-renderers/CrThermographyMore.vue";
import GoToNewsButton from "./cell-renderers/goToNewsButton.vue";
import LinkTask from "./LinkTask.vue";
import StatusTask from "./StatusTask";
import TableOverlaySwipe from "./TableOverlaySwipe.vue";
import IconQuestionmark from "./icons/IconQuestionmark.vue";
import ReportThermography from "./ReportThermography.vue";
import { AG_GRID_LOCALE } from "../helpers/agGridLocale"; // localization for ag grid
import {
  ISSUE,
  ISSUE_THERMOGRAPHY,
  TASK,
  COMPLETED_TASK,
  INSTALLATION,
  TASKS_PLANNED,
  TASKS_PLANNED_DASHBOARD,
  NEWSFEED,
  NEWSFEED_DASHBOARD,
} from "../mixins/columnDefinitions";
import { createNamespacedHelpers } from "vuex";
const table = createNamespacedHelpers("table");
const task = createNamespacedHelpers("task");
const issue = createNamespacedHelpers("issue");
const installation = createNamespacedHelpers("installation");

// Disabling vue/no-unused-components because they are used in columnDefinitions.js
/* eslint-disable vue/no-unused-components */
export default {
  name: "Table",
  components: {
    AgGridVue,
    TableOverlaySwipe,
    CustomToolTip,
    InstallationDescription,
    InstallationFiles,
    InstallationHasOverdue,
    LinkInstallation,
    LinkReport,
    CrThermographyMore,
    GoToNewsButton: GoToNewsButton,
    LinkTask,
    StatusTask,
    IconQuestionmark,
    ReportThermography,
  },
  props: {
    isDashboard: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableName: {
      type: String,
      default: null,
    },
    tablePageSize: {
      type: Number,
      default: 10,
    },
    tableType: {
      type: String,
      default: null,
      validator(value) {
        // The value must match one of these strings
        return [
          ISSUE,
          ISSUE_THERMOGRAPHY,
          TASK,
          COMPLETED_TASK,
          INSTALLATION,
          TASKS_PLANNED,
          TASKS_PLANNED_DASHBOARD,
          NEWSFEED,
          NEWSFEED_DASHBOARD,
        ].includes(value);
      },
    },
  },
  data() {
    this.localeText = AG_GRID_LOCALE;
    this.paginationSuppression = false;
    this.rowModelType = "clientSide"; // default
    this.fetchInterval = null;
    return {
      datasource: null,
      chosenColumnDefs: null,
      currentPage: null,
      defaultColDef: {
        sortable: true,
        cellStyle: {
          fontSize: "18px",
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          "word-break": "break-word",
        },
        wrapText: true,
      },
      alwaysShowHorizontalScroll: true,
      domLayout: null,
      gridApi: null,
      isLastPage: null,
      lastPage: null,
      overlaySwipeDisabled: true,
      pageSize: null,
      rowData: null,
      rowSelection: null,
      tooltipShowDelay: null,
      totalPages: null,
      redrawInitial: false,
      width: "100%",
      height: "0px",
    };
  },
  computed: {
    ...table.mapGetters(["tableSearchQuery", "rowModel", "gridHeight"]),
    ...task.mapGetters(["tasksPlanned", "tasksCompleted"]),
    ...issue.mapGetters([
      "issuesNoAction",
      "issuesCompleted",
      "issuesThermographyNoAction",
      "issuesThermographyCompleted",
      "issueStatus",
    ]),
    ...installation.mapGetters(["installations", "processingInstallations"]),
    computedColumnDefs: function () {
      // Denifined in columnDefinitions.js
      return this[this.tableType];
    },
    overlayNoRowsTemplate() {
      return `<span>${this.getDictionaryEntry(
        "filterTabs.missingData"
      )} ${this.tableName.toLowerCase()}</span>`;
    },
  },
  beforeMount() {
    this.rowData = [];
    // todo: refactor
    if (this.tableType !== INSTALLATION) {
      this.domLayout = "autoHeight";
      this.width = "100%";
      this.height = "100%";
    } else {
      this.domLayout = "normal";
      this.width = "100%";
    }

    this.rowData = this.tableData;
    this.tooltipShowDelay = 500;
    this.rowSelection = "multiple";
    if (this.isDashboard) {
      this.paginationSuppression = true;
    }
    // TODO: Add this in props
    if (this.tableType === "INSTALLATION") {
      this.rowModelType = this.rowModel.rowModelType;
      this.datasource = this.rowModel.datasource;
      // var self = this;
      // this.fetchInterval = setInterval(() => {
      //   if (this.processingInstallations <= 0) {
      //     self.gridApi.refreshInfiniteCache();
      //   }
      // }, 5000);
    }
  },
  beforeUnmount() {
    clearInterval(this.fetchInterval);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    this.gridApi.destroy();
  },
  methods: {
    ...table.mapActions([
      "UPDATE_SELECTED_ISSUE_ROWS",
      "UPDATE_SELECTED_TASK_ROWS",
    ]),
    deSelectAll() {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    handleScroll(e) {
      setTimeout(() => {
        if (e.direction === "horizontal" && e.left > 10) {
          this.overlaySwipeDisabled = true;
        }
      }, 100);
    },
    onGoToPage(page) {
      this.gridApi.paginationGoToPage(page);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      if (this.tableType === "INSTALLATION") {
        this.gridApi.redrawRows();
      }
      this.gridApi.setQuickFilter(this.tableSearchQuery);
      this.totalPages = this.gridApi.paginationGetTotalPages();
      if (
        this.tableType === NEWSFEED_DASHBOARD ||
        this.tableType === NEWSFEED
      ) {
        this.gridApi.setHeaderHeight(0);
      }
    },
    onPaginationChanged() {
      if (this.gridApi) {
        this.lastPage = this.gridApi.paginationIsLastPageFound();
        this.pageSize = this.gridApi.paginationGetPageSize();
        this.currentPage = this.gridApi.paginationGetCurrentPage();
        this.totalPages = this.gridApi.paginationGetTotalPages();
        this.isLastPage = this.gridApi.paginationIsLastPageFound();
        this.gridApi.paginationSetPageSize(this.tablePageSize);
      }

      this.$emit("resetSelectAll");
    },

    // Called when checkboxes in table are ticked/unticked.
    onSelectionChanged() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      switch (this.tableType) {
        case ISSUE:
          this.UPDATE_SELECTED_ISSUE_ROWS(selectedData);
          break;
        case TASK:
          this.UPDATE_SELECTED_TASK_ROWS(selectedData);
          break;
        case COMPLETED_TASK:
          this.UPDATE_SELECTED_TASK_ROWS(selectedData);
          break;
      }
      this.$emit("setSelectedRows", selectedNodes.length);
    },
    refreshGrid(event) {
      this.gridApi.redrawRows();
      if (event) {
        console.log(event.target.tagName);
      }
    },
    selectAll() {
      this.gridApi.forEachNode((node) => {
        if (node.displayed) {
          node.setSelected(true);
        }
      });
    },
    valueGetterToolTip(params) {
      return { value: params.value };
    },
  },
  watch: {
    tableData: function () {
      this.rowData = this.tableData;
      if (this.gridApi) this.gridApi.redrawRows();
    },
    gridHeight: function () {
      this.height = `${this.gridHeight}px`;
      this.gridApi.redrawRows();
    },
    timeInterval: function () {
      switch (this.tableType) {
        case ISSUE_THERMOGRAPHY:
          if (this.issueStatus === "Completed") {
            this.rowData = this.issuesThermographyCompleted;
          } else {
            this.rowData = this.issuesThermographyNoAction;
          }
          break;

        case ISSUE:
          if (this.issueStatus === "Completed") {
            this.rowData = this.issuesCompleted;
          } else {
            this.rowData = this.issuesNoAction;
          }
          break;

        default:
          break;
      }

      if (this.gridApi) this.gridApi.redrawRows();
    },
    tableType: function (newval) {
      if (newval === ISSUE_THERMOGRAPHY) {
        if (this.issueStatus === "Completed") {
          this.rowData = this.issuesThermographyCompleted;
        } else {
          this.rowData = this.issuesThermographyNoAction;
        }
      }

      if (newval === ISSUE) {
        if (this.issueStatus === "Completed") {
          this.rowData = this.issuesCompleted;
        } else {
          this.rowData = this.issuesNoAction;
        }
      }
      if (this.gridApi) this.gridApi.redrawRows();
    },
    issuesThermographyNoAction: function (issueThermography) {
      if (this.tableType === ISSUE_THERMOGRAPHY) {
        this.rowData = issueThermography;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    issuesThermographyCompleted: function (issueThermography) {
      if (this.tableType === ISSUE_THERMOGRAPHY) {
        this.rowData = issueThermography;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    tableSearchQuery: function (query) {
      if (this.gridApi.getModel().constructor.name === "InfiniteRowModel") {
        this.gridApi.setDatasource(this.rowModel.datasource);
      } else if (this.gridApi) {
        this.gridApi.setQuickFilter(query);
      }
    },
    tasksPlanned: function (newTasksPlanned) {
      if (this.tableType === TASKS_PLANNED) {
        this.rowData = newTasksPlanned;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    tasksCompleted: function (newTasksCompleted) {
      if (this.tableType === COMPLETED_TASK) {
        this.rowData = newTasksCompleted;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    issuesNoAction: function (newIssuesNoAction) {
      if (this.tableType === ISSUE && this.issueStatus === "NoAction") {
        this.rowData = newIssuesNoAction;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    issuesCompleted: function (newIssuesCompleted) {
      if (this.tableType === ISSUE && this.issueStatus === "Completed") {
        this.rowData = newIssuesCompleted;
        if (this.gridApi) this.gridApi.redrawRows();
      }
    },
    rowModel: function () {
      this.datasource = this.rowModel.datasource;
    },
    installations: function (newInstallations) {
      this.rowData = newInstallations;
      if (this.gridApi) this.gridApi.redrawRows();
    },
  },
};
</script>

<style lang="scss">
.border-fat {
  border: solid;
}

.table-cell-custom {
  border: 1px solid !important;
  word-break: break-word !important;
}

.notApproved {
  background: red;
  color: whitesmoke;
  border: 1px solid black !important;
  word-break: break-word !important;
}
</style>

<style lang="scss">
.table {
  position: relative;
}

@import "~/src/styles/custom-table.scss";
</style>
