import {
  GET_TASKS_PLANNED,
  GET_TASKS_LOCATIONS_PLANNED,
  GET_TASK_LIST,
  GET_TASK_LIST_LOCATIONS,
  GET_TASK_FILES,
  GET_TASK,
  UPDATE_TASK_STATUS,
  UPDATE_TIMEINTERVAL,
  UPLOAD_TASK_FILES,
  DELETE_TASK_FILE,
} from "@/store/action-types";
import {
  SET_TASKS,
  FILTER_TASKS,
  SET_TIMEINTERVAL,
  SET_TASK_STATUS,
  SET_PLANNED_TASKS,
  SET_FILTER_TASKS_LOCATIONS,
} from "../mutation-types";
import { dateAndHoursFormat } from "@/helpers/dateFormatter";
import {
  getDashboardIntervals,
  getMaxInterval,
} from "../../helpers/table/timeIntervalHelper";
import store from "@/store";
import axios from "../../service/api";

/*
  NB: 
  Tasks plannned are used for "Aktuelle Eftersyn".
  TasksFuture are used for "Planlagte Eftersyn"
*/
export default {
  namespaced: true,
  state: {
    currentTaskId: null,
    currentJobNumber: null,
    taskFiles: null,
    initialTasksPlanned: null,
    initialTasksUrgent: null,
    tasksUrgent: null,
    tasksPlanned: null,
    tasksCompleted: null,
    status: null,
    timeInterval: {
      dateFrom: (() => {
        const d = new Date();
        d.setFullYear(d.getFullYear() - 1);
        return dateAndHoursFormat(d);
      })(),
      dateTo: dateAndHoursFormat(new Date()),
    },
    timeIntervalPlanned: {
      dateTo: (() => {
        const d = new Date();
        d.setFullYear(d.getFullYear() + 1);
        return dateAndHoursFormat(d);
      })(),
      dateFrom: dateAndHoursFormat(new Date()),
    },
  },
  getters: {
    initialTasksPlanned: (state) => state.initialTasksPlanned,
    initialTasksUrgent: (state) => state.initialTasksUrgent,
    tasksPlanned: (state) => state.tasksPlanned,
    taskFiles: (state) => state.taskFiles,
    tasksCompleted: (state) => state.tasksCompleted,
    taskStatus: (state) => state.status,
    tasksUrgent: (state) => state.tasksUrgent,
    timeInterval: (state) => state.timeInterval,
    timeIntervalPlanned: (state) => state.timeIntervalPlanned,
    currentJobNumber: (state) => state.currentJobNumber,
  },
  actions: {
    /* eslint-disable */
    [UPLOAD_TASK_FILES]({ dispatch, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const fileList = payload.files;
      let formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        formData.append("files", file, file.name);
      }

      var commentBlob = new Blob([payload.comment], { type: "text/plain" });
      formData.append("commentFile", commentBlob, "The comment");
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/tasks/${state.currentTaskId}/uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          dispatch(GET_TASK_FILES, {
            type: "file",
            taskId: state.currentTaskId,
            jobNumber: state.currentJobNumber,
          });
        })
        .catch(() => {
          // todo: show something bad happend.
        });
    },
    [UPDATE_TIMEINTERVAL]({ dispatch, commit }, payload) {
      commit(SET_TIMEINTERVAL, payload);
      dispatch(GET_TASK_LIST); // tasks for 'Afsluttede' and 'Aktuelle'
      dispatch(GET_TASKS_PLANNED); // tasks for 'Kommende'
    },
    [GET_TASKS_LOCATIONS_PLANNED]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const tableTimeFilter = store.getters["table/timeFilter/getTimeInterval"];

      let start;
      let end;
      switch (state.status) {
        // dashboard only show now and one month in future
        case "DASHBOARD":
          {
            let startTaskPlanned = new Date();
            let endTaskPlanned = new Date();
            endTaskPlanned.setMonth(startTaskPlanned.getMonth() + 1);
            start = startTaskPlanned.toISOString().slice(0, 10);
            end = endTaskPlanned.toISOString().slice(0, 10);
          }
          break;
        // use state from tablefilter
        default:
          start = tableTimeFilter.dateFrom.slice(0, 10);
          end = tableTimeFilter.dateTo.slice(0, 10);
          break;
      }
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/serviceplan?from=${start}&to=${end}`, 
          {
            locations: state.locations.map(({ code }) => code),
          },
          {}
        )
        .then((data) => {
          commit(SET_PLANNED_TASKS, { data });
        })
        .catch((error) => {
          console.error("An error occoured");
        });
    },
    [GET_TASKS_PLANNED]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const tableTimeFilter = store.getters["table/timeFilter/getTimeInterval"];

      var locationReferece = activeLocation.code;
      if (locationReferece == undefined) return;

      let start;
      let end;
      switch (state.status) {
        // dashboard only show now and one month in future
        case "DASHBOARD":
          {
            let startTaskPlanned = new Date();
            let endTaskPlanned = new Date();
            endTaskPlanned.setMonth(startTaskPlanned.getMonth() + 1);
            start = startTaskPlanned.toISOString().slice(0, 10);
            end = endTaskPlanned.toISOString().slice(0, 10);
          }
          break;
        // use state from tablefilter
        default:
          start = tableTimeFilter.dateFrom.slice(0, 10);
          end = tableTimeFilter.dateTo.slice(0, 10);
          break;
      }
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/location/${locationReferece}/serviceplan?from=${start}&to=${end}`
        )
        .then((data) => {
          commit(SET_PLANNED_TASKS, { data });
        })
        .catch((error) => {
          console.error("An error occoured");
        });
    },
    [GET_TASK_LIST_LOCATIONS]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      let timeInterval = store.getters["table/timeFilter/getTimeInterval"];
      let taskState = state.status;
      let tempState;
      switch (state.status) {
        case "DASHBOARD":
          tempState = "PLANNED"; // Tech debt: In api, data for "Aktuelle fejl" is categorized as "PLANNED"
          timeInterval = getDashboardIntervals("tasksUrgent");
          break;

        case "URGENT":
          timeInterval = getMaxInterval();
          tempState = "PLANNED"; // Tech debt: In api, data for "Aktuelle fejl" is categorized as "PLANNED"
          break;

        default:
          timeInterval = getMaxInterval();
          tempState = taskState;
          break;
      }
      if (taskState == "URGENT") tempState = "PLANNED";

      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/tasks/${timeInterval.dateFrom}/${timeInterval.dateTo}/${tempState}`,
          {
            locations: state.locations.map(({ code }) => code),
          },
          {}
        )
        .then(({ data }) => {
          commit(SET_TASKS, { data: data, status: taskState });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit(SET_TASKS, { data: [], status: taskState });
          } else {
            error.handleGlobally && error.handleGlobally();
          }
        });
    },
    [GET_TASK_LIST]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      let timeInterval = store.getters["table/timeFilter/getTimeInterval"];
      let taskState = state.status;
      let tempState;
      switch (state.status) {
        case "DASHBOARD":
          tempState = "PLANNED"; // Tech debt: In api, data for "Aktuelle fejl" is categorized as "PLANNED"
          timeInterval = getDashboardIntervals("tasksUrgent");
          break;

        case "URGENT":
          timeInterval = getMaxInterval();
          tempState = "PLANNED"; // Tech debt: In api, data for "Aktuelle fejl" is categorized as "PLANNED"
          break;

        default:
          timeInterval = getMaxInterval();
          tempState = taskState;
          break;
      }
      if (taskState == "URGENT") tempState = "PLANNED";

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/location/${activeLocation.code}/tasks/${timeInterval.dateFrom}/${timeInterval.dateTo}/${tempState}`
        )
        .then(({ data }) => {
          commit(SET_TASKS, { data: data, status: taskState });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit(SET_TASKS, { data: [], status: taskState });
          } else {
            error.handleGlobally && error.handleGlobally();
          }
        });
    },
    [DELETE_TASK_FILE]({ dispatch, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      return axios
        .delete(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/tasks/${state.currentTaskId}/${payload.fileName}`
        )
        .then(() => {
          dispatch(GET_TASK_FILES, {
            type: "file",
            taskId: state.currentTaskId,
            jobNumber: state.currentJobNumber,
          });
        });
    },
    [GET_TASK_FILES]({ commit, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      state.currentTaskId = payload.taskId;
      state.currentJobNumber = payload.jobNumber;
      state.taskFiles = null;

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/tasks/${payload.taskId}/getFiles`
        )
        .then((data) => {
          state.taskFiles = data.data;
          commit(
            "overlay/SET_ACTIVE_OVERLAY",
            {
              type: payload.type,
              taskId: payload.taskId,
            },
            { root: true }
          );
          return data.data;
        })
        .catch((error) => {
          console.error("Getting files failed");
          console.error(error);
        });
    },
    [GET_TASK]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/history/${payload.task.id}`
        )
        .then(({ data }) => {
          const newTask = payload.task;
          newTask.history = data;

          commit(
            "overlay/SET_ACTIVE_OVERLAY",
            {
              type: payload.type,
              content: newTask,
            },
            { root: true }
          );
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [UPDATE_TASK_STATUS]({ state, dispatch }, payload) {
      const customerId = store.getters["authentication/customerId"];

      return new Promise((resolve, reject) => {
        payload.map((task) => {
          return axios
            .post(
              `${process.env.VUE_APP_API_URL}/task/${customerId}/updateStatus/${task.installation}`,
              {
                taskStatus: task.status.id,
                comment: task.note,
              }
            )
            .then(({ data }) => {
              resolve(data);
              return data;
            })
            .catch((error) => {
              error.handleGlobally && error.handleGlobally();
              reject(error);
            });
        });
        dispatch("GET_TASK_LIST", {
          status: state.status,
        });
      });
    },
  },
  mutations: {
    [SET_TASK_STATUS](state, payload) {
      state.status = payload;
    },
    [SET_PLANNED_TASKS](state, payload) {
      state.tasksPlanned = payload.data.data;
    },
    [SET_TASKS](state, payload) {
      switch (payload.status) {
        case "DASHBOARD":
          state.tasksUrgent = payload.data;
          break;

        case "URGENT":
          state.tasksUrgent = payload.data;
          break;

        case "PLANNED": // should not show
          state.tasksUrgent = payload.data;
          break;

        case "COMPLETED":
          state.tasksCompleted = payload.data;
          break;
      }
    },
    [FILTER_TASKS](state, payload) {
      if (payload.filter === "all") {
        state.tasksPlanned = state.initialTasksPlanned;
      }

      if (payload.filter === "coming") {
        state.tasksPlanned = state.initialTasksPlanned.filter((task) => {
          return task.status === "Completed" || task.status === "NotHandled";
        });
      }

      if (payload.filter === "overdue") {
        state.tasksPlanned = state.initialTasksPlanned.filter((task) => {
          return (
            task.status === "NotHandled" && new Date(task.start) > new Date()
          );
        });
      }
    },
    [SET_TIMEINTERVAL](state, payload) {
      var interval = {
        dateFrom: dateAndHoursFormat(new Date(payload.dateFrom)),
        dateTo: dateAndHoursFormat(new Date(payload.dateTo)),
      };

      switch (state.status) {
        case "COMPLETED":
        case "URGENT":
          state.timeInterval = interval;
          break;

        case "PLANNED":
          state.timeIntervalPlanned = interval;
          break;

        default:
      }
    },
    [SET_FILTER_TASKS_LOCATIONS](state, payload) {
      state.locations = payload;
    },
  },
};
