<template>
  <div>
    <div>
      <h2>
        {{ getDictionaryEntry("overlay.file.filesForJobNumber") }}
        {{ this.currentJobNumber }}
      </h2>
      <div v-if="taskFiles != null">
        <table>
          <thead>
            <tr>
              <th style="width: 30%"></th>
              <th style="width: 50%"></th>
              <th style="width: 20%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) in taskFiles" :key="'file_' + index">
              <td>
                <a :href="file.url" target="_blank">{{ file.displayName }}</a>
              </td>
              <td>
                <p v-text="file.shortDescription"></p>
              </td>
              <td>
                <Button style="background: red" v-on:click="deleteFile(file)">{{
                  getDictionaryEntry("overlay.file.button.delete")
                }}</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h2 style="margin-top: 2rem">
      {{ getDictionaryEntry("overlay.file.uploadFiles") }}
    </h2>
    <Textarea
      style="margin-top: 1rem"
      :label="getDictionaryEntry('overlay.file.comment')"
      @handle-input="handleTextareaInput"
    />
    <div>
      <input
        style="margin-top: 1rem"
        id="overlayFileInput"
        type="file"
        @change="uploadFiles"
        multiple
      />
      <Button
        id="submitBtn"
        :disabled="this.isBusy || !this.fileSizeOk"
        style="margin-top: 1rem"
        v-on:click="sendFiles"
      >
        {{ getDictionaryEntry("overlay.file.button.files") }}
      </Button>
      <label for="submitBtn">Maks filstørrelse: 100MB</label>
      <p v-if="!this.fileSizeOk" style="color: red">
        Grænse for filstørrelse er overskredet
      </p>
      <div v-if="this.isBusy">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Button from "../Button.vue";
import Textarea from "../Textarea.vue";
import Spinner from "../Spinner.vue";
const task = createNamespacedHelpers("task");

export default {
  name: "OverlayFile",
  components: {
    Button,
    Textarea,
    Spinner,
  },
  data() {
    return {
      files: [],
      uploadComment: "",
      isBusy: false,
      fileSizeOk: true,
    };
  },
  computed: {
    ...task.mapGetters(["taskFiles", "currentJobNumber"]),
  },
  methods: {
    ...task.mapActions(["UPLOAD_TASK_FILES", "DELETE_TASK_FILE"]),
    sendFiles() {
      this.isBusy = true;
      this.UPLOAD_TASK_FILES({
        files: this.files,
        comment: this.uploadComment,
      }).then(() => {
        this.isBusy = false;
        this.taskFiles = [];
        document.getElementById("overlayFileInput").value = "";
      });
    },
    handleTextareaInput(input) {
      this.uploadComment = input;
    },
    deleteFile(e) {
      this.isBusy = true;
      this.files = [];
      document.getElementById("overlayFileInput").value = "";
      this.DELETE_TASK_FILE({
        fileName: e.fileName,
      }).then(() => {
        this.isBusy = false;
      });
    },
    uploadFiles(e) {
      this.files = [];
      const files = e.target.files;
      Array.from(files).forEach((file) => {
        this.files.push(file);
      });

      var sizeLimit = 100000000; // 10MB
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].size > sizeLimit) {
          this.fileSizeOk = false;
          return;
        }
      }
      this.fileSizeOk = true;
    },
  },
};
</script>
