<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 13.042 11.838"
  >
    <path
      d="M15.042,4.5H3l4.817,5.7v3.938l2.408,1.2V10.2Z"
      transform="translate(-2.5 -4)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>
<script>
export default {
  name: "IconFilter",
};
</script>
