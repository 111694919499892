<template>
  <div class="link-report">
    <div
      v-if="this.params.tableType !== 'COMPLETED_TASK'"
      v-on:click="toggleReport('issue')"
      class="link-report__icon link-report__icon--issue"
      :class="{ 'link-report__icon--disabled': !issue }"
      :title="getDictionaryEntry('linkreport.issue.titletag')"
    >
      <IconIssue />
    </div>
    <div
      v-on:click="toggleReport('service')"
      class="link-report__icon link-report__icon--service"
      :class="{ 'link-report__icon--disabled': !service }"
      :title="getDictionaryEntry('linkreport.service.titletag')"
    >
      <IconService />
    </div>
    <div
      v-on:click="toggleReport('control')"
      class="link-report__icon link-report__icon--control"
      :class="{ 'link-report__icon--disabled': !control }"
      :title="getDictionaryEntry('linkreport.control.titletag')"
    >
      <IconControl :itemCount="itemCount" />
    </div>
    <div v-if="this.params.tableType === 'COMPLETED_TASK'">
      <div
        v-on:click="toggleReport('file')"
        class="link-report__icon link-report__icon--control"
      >
        <IconFile :taskId="taskId" :taskJobNumber="jobNumber" />
      </div>
    </div>
  </div>
</template>

<script>
import IconIssue from "./icons/IconIssue.vue";
import IconService from "./icons/IconService.vue";
import IconControl from "./icons/IconControl.vue";
import IconFile from "./icons/IconFile.vue";
import { createNamespacedHelpers } from "vuex";
const report = createNamespacedHelpers("report");
const task = createNamespacedHelpers("task");
export default {
  name: "LinkReport",
  components: {
    IconIssue,
    IconService,
    IconControl,
    IconFile,
  },
  props: ["params"],
  data() {
    return {
      issue: null,
      service: null,
      control: null,
      issueId: null,
      taskId: null,
      jobNumber: null,
      controlSchemaFile: null,
      locationCode: null,
      locationName: null,
      installationName: null,
      installationType: null,
      fileCount: null,
      itemCount: null,
    };
  },
  beforeMount() {
    const data = this.params.data;
    this.taskId = data.id;
    if (data.hasIssueReport) {
      this.issue = data.hasIssueReport;
      this.taskId = data.taskId; //if there is issuerapport, its issue data then set taskid
    }
    if (data.hasServiceReport) {
      this.service = data.hasServiceReport;
    }

    if (data.hasControlSchema) {
      this.control = data.hasControlSchema;
    }

    this.issueId = data.id;
    this.jobNumber = data.jobNumber;
    this.locationCode = data.locationCode;
    this.locationName = data.locationName;
    this.installationName = data.installation;
    this.installationType = data.installationType;
    this.fileCount = data.fileCount;
    this.itemCount = data.controlSchemaItems;

    // Control Scheme path differentiates whether you are using "AfsluttedeFejl" or "AfsluttedeEftersyn"
    this.controlSchemaFile = data.controlSchemaFile;
    if (this.controlSchemaFile === undefined) {
      this.controlSchemaFile = data.schemaReportFile;
    }

    this.installationReferenceCode = data.installationReferenceCode;
  },
  methods: {
    ...report.mapActions([
      "GET_ISSUE_REPORT",
      "GET_SERVICE_REPORT",
      "GET_CONTROL_REPORT",
    ]),
    ...task.mapActions(["GET_TASK_FILES"]),
    toggleReport(type) {
      // if it is greyed out, then ignore.
      if (type === "issue" && this.issue) {
        this.GET_ISSUE_REPORT({ type, issueId: this.issueId });
        this.$store.dispatch("toggleOverlay", type);
      }
      if (type === "service" && this.service) {
        this.GET_SERVICE_REPORT({
          type,
          taskId: this.taskId,
          installationReferenceCode: this.installationReferenceCode,
        });
        this.$store.dispatch("toggleOverlay", type);
      }
      if (type === "control" && this.control) {
        this.GET_CONTROL_REPORT({
          type,
          installationReferenceCode: this.installationReferenceCode,
          controlSchemaFile: this.controlSchemaFile,
          locationName: this.locationName,
          installationName: this.installationName,
          installationType: this.installationType,
        });
        this.$store.dispatch("toggleOverlay", type);
      }
      if (type === "file") {
        this.GET_TASK_FILES({
          type,
          taskId: this.taskId,
          jobNumber: this.jobNumber,
        });
        this.$store.dispatch("toggleOverlay", type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link-report {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.link-report__icon {
  width: 1.7rem;
  color: var(--color-grey--dark);
  margin-right: 2rem;

  &:hover {
    color: var(--color-accent-green);
    cursor: pointer;
  }

  &--disabled {
    color: var(--color-grey--light);

    &:hover {
      cursor: initial;
      color: var(--color-grey--light);
    }
  }

  &--issue {
    width: 1.7rem;
  }

  &--service {
    width: 1.7rem;
  }

  &--control {
    width: 1.4rem;
  }
}
</style>
