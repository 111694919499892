<template>
  <div class="navigation-footer">
    <nav class="navigation-footer__wrapper">
      <Logo />
      <div class="navigation-footer__location">
        <ModalLocation>
          <template v-slot:trigger>
            <IconLocation />
          </template>
        </ModalLocation>
      </div>
      <div class="navigation-footer__burger" v-on:click="menuToggle">
        <IconBurger />
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import IconLocation from "./icons/IconLocation.vue";
import IconBurger from "./icons/IconBurger.vue";
import ModalLocation from "./ModalLocation.vue";

export default {
  name: "NavigationFooter",
  components: {
    Logo,
    IconLocation,
    IconBurger,
    ModalLocation,
  },
  methods: {
    menuToggle() {
      this.$store.dispatch("toggleMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--footer--height);
  box-shadow: 0px -5px 7px var(--color-grey--light);
  background-color: var(--color-white);
}

.navigation-footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  max-width: 35rem;
}

.navigation-footer__location,
.navigation-footer__burger {
  color: var(--color-blue);
}

.navigation-footer__location {
  width: 1.7rem;
}

.navigation-footer__burger {
  width: 2rem;

  &:hover {
    cursor: pointer;
  }
}
</style>
