<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    fill="currentColor"
    viewBox="0 0 15.945 10.635"
  >
    <path
      d="M18.044,11.455a.724.724,0,0,0-.006,1.019l3.367,3.373H8.589a.72.72,0,0,0,0,1.44H21.4L18.032,20.66a.729.729,0,0,0,.006,1.019.717.717,0,0,0,1.014-.006l4.564-4.6h0a.809.809,0,0,0,.15-.227.687.687,0,0,0,.055-.277.722.722,0,0,0-.2-.5l-4.564-4.6A.706.706,0,0,0,18.044,11.455Z"
      transform="translate(-7.875 -11.252)"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrow",
};
</script>
