<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 419.37 326.96"
    stroke="currentColor"
  >
    <line
      x1="10"
      y1="163.48"
      x2="409.37"
      y2="163.48"
      fill="none"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="20"
    ></line>
    <line
      x1="10"
      y1="316.96"
      x2="409.37"
      y2="316.96"
      fill="none"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="20"
    ></line>
    <line
      x1="10"
      y1="10"
      x2="409.37"
      y2="10"
      fill="none"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="20"
    ></line>
  </svg>
</template>

<script>
export default {
  name: "IconBurger",
};
</script>
