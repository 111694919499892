<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 12 6.861"
    fill="currentColor"
  >
    <path
      d="M12.19,16.039,16.727,11.5a.854.854,0,0,1,1.211,0,.865.865,0,0,1,0,1.215L12.8,17.858a.856.856,0,0,1-1.183.025L6.438,12.717A.858.858,0,1,1,7.649,11.5Z"
      transform="translate(-6.188 -11.246)"
    />
  </svg>
</template>

<script>
export default {
  name: "IconChevron",
};
</script>

<style scoped></style>
