<template>
  <div class="report-service" v-if="service.content">
    <div class="report-service__unit">
      <strong
        >{{ getDictionaryEntry("reportService.labelInstallation") }}:</strong
      >
      {{ service.content.activityType }}
    </div>
    <div class="report-service__info-list">
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.taskNumber")
        }}</template>
        <template v-slot:text>{{ service.content.caseNumber }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.type")
        }}</template>
        <template v-slot:text>{{ service.content.activityType }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.name")
        }}</template>
        <template v-slot:text>{{ service.content.taskName }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.installationNumber")
        }}</template>
        <template v-slot:text>{{
          service.content.installationNumber
        }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.fitter")
        }}</template>
        <template v-slot:text>{{ service.content.responsible }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.datePerformed")
        }}</template>
        <template v-slot:text>{{ service.content.stopDate }}</template>
      </LabelInfo>
      <LabelInfo>
        <template v-slot:title>{{
          getDictionaryEntry("reportService.labelInfo.reference")
        }}</template>
        <template v-slot:text>{{ service.content.customer }}</template>
      </LabelInfo>
    </div>

    <LinkDownload :files="service.content.files" />

    <div class="report-service__checklist">
      <Accordion>
        <AccordionItem :disabled="checkListLines.length < 1">
          <template v-slot:accordion-header>
            {{ getDictionaryEntry("overlayInstallation.checkListLines.input") }}
          </template>
          <template v-slot:accordion-content>
            <TableSimple
              v-if="checkListLines"
              :tableData="checkListLines"
              :tableType="'REPORT_SERVICE_COMMENTS'"
              :simpleHeader="true"
            />
          </template>
        </AccordionItem>
      </Accordion>
    </div>

    <div class="report-service__report-button">
      <div class="download__spinner" style="border 1px solid;">
        <Spinner :size="5" v-if="btnText === 'W'" />
      </div>
      <Button v-on:click="getReport" ref="GetReportBtn"
        >{{ getDictionaryEntry("reportService.buttonDownloadReports") }}
      </Button>
    </div>
  </div>
</template>

<script>
import Accordion from "./Accordion.vue";
import AccordionItem from "./AccordionItem.vue";
import TableSimple from "./TableSimple.vue";
import LabelInfo from "./LabelInfo.vue";
import LinkDownload from "./LinkDownload.vue";
import Button from "./Button.vue";
import Spinner from "./Spinner.vue";
import { createNamespacedHelpers } from "vuex";
const report = createNamespacedHelpers("report");
const overlay = createNamespacedHelpers("overlay");

export default {
  name: "ReportService",
  components: {
    Spinner,
    Accordion,
    AccordionItem,
    TableSimple,
    LabelInfo,
    LinkDownload,
    Button,
  },
  data() {
    return {
      btnText: "",
      checkListLinesColumnDefs: null,
      tt: "",
    };
  },
  computed: {
    ...overlay.mapGetters({ service: "activeOverlay" }),
    checkListLines: function () {
      return this.service.content.checklistLines;
    },
  },
  methods: {
    ...report.mapActions(["GET_SERVICE_REPORT_FILES"]),
    async getReport() {
      this.btnText = "W";
      try {
        await this.GET_SERVICE_REPORT_FILES();
      } catch (err) {
        console.error(err);
      }
      this.btnText = "Done";
      this.tt = "DAC";
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner_getreport {
  width: 15px;
  height: 15px;
}

.report-service {
  display: flex;
  flex-direction: column;
}

.report-service__unit {
  font-size: 1.3rem;
  margin-top: 1rem;
}

.report-service__info-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 3rem;
  padding: 3rem 0;
  border-top: var(--border--sm) solid var(--color-grey);
}

.report-service__checklist {
  margin-top: 5rem;
}

.report-service__report-button {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: var(--border-divider);
}

.download__spinner {
  width: 1rem;
  height: 1rem;
}
</style>
