<template>
  <div class="table-overlay-swipe">
    <div class="table-overlay-swipe__text">SWIPE</div>
  </div>
</template>

<script>
export default {
  name: "TableOverlaySwipe",
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.table-overlay-swipe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 6rem;
  transition: opacity 0.5s;
  background: rgb(244, 246, 248);
  background: linear-gradient(
    90deg,
    rgba(244, 246, 248, 0.2) 0%,
    rgba(206, 214, 221, 1) 100%
  );
  z-index: var(--z-index--swipe);

  &--disabled {
    opacity: 0;
  }

  @include desktop {
    display: none;
  }
}

.table-overlay-swipe__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  right: 0.7rem;
  font-weight: var(--font-weight--semibold);
  color: var(--color-secondary);
}
</style>
