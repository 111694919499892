<template>
  <div class="check-list-service">
    <div class="check-list-service__item">
      <div v-if="item.checked" class="check-list-service__item-icon">
        <IconCheckMark />
      </div>
      <div
        class="check-list-service__item-text"
        :class="{
          'check-list-service__item-text--header': item.type === 'header',
        }"
      >
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script>
import IconCheckMark from "./icons/iconCheckMark.vue";

export default {
  name: "CheckListService",
  components: {
    IconCheckMark,
  },
  data() {
    return {
      item: null,
    };
  },
  beforeMount() {
    this.item = this.params.data;
  },
};
</script>

<style lang="scss" scoped>
.check-list-service {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.7rem;
}

.check-list-service__item {
  position: relative;
  font-size: 1.3rem;
  line-height: 1;
  padding-bottom: 1.3rem;
  padding-left: 2rem;
}

.check-list-service__item-icon {
  position: absolute;
  width: 1rem;
  left: 0;
  color: var(--color-green);
}

.check-list-service__item-text--header {
  font-weight: var(--font-weight--semibold);
}
</style>
