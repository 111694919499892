<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 17.828 17.828"
  >
    <g transform="translate(-4.586 -4.184)">
      <line
        x1="15"
        y2="15"
        transform="translate(6 5.598)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        x2="15"
        y2="15"
        transform="translate(6 5.598)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconClose",
};
</script>
