<template>
  <div v-if="id != 'issue-tab-thermography'">
    <br />
    <multiselect
      v-model="code"
      :options="locationObjs"
      label="name"
      track-by="code"
      :preselect-first="true"
      :multiple="true"
      :taggable="true"
      :close-on-select="false"
      :placeholder="getDictionaryEntry('filter.selectLocation.placeholder')"
      @update:model-value="handleSelected()"
      :selectLabel="getDictionaryEntry('filter.selectLocation.selectLabel')"
      :deselectLabel="getDictionaryEntry('filter.selectLocation.deSelectLabel')"
      :selectedLabel="getDictionaryEntry('filter.selectLocation.selectedLabel')"
    >
      <template v-slot:selection="{ values, search, isOpen }">
        <pre class="beGone">
          {{ values.Length }}{{ search }}{{ isOpen }}
        </pre>
        <div>
          {{ values.length }}
          {{ getDictionaryEntry("filter.locationsSelected.placeholder") }}
        </div>
      </template>
    </multiselect>
    <span @click="handleSelectAll" class="selector">
      {{ getDictionaryEntry("filter.locationsSelected.SelectAll") }}
    </span>
    <span @click="handleDeSelectAll" class="selector">
      {{ getDictionaryEntry("filter.locationsSelected.DeSelectAll") }}
    </span>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { createNamespacedHelpers } from "vuex";
import store from "@/store";
const issues = createNamespacedHelpers("issue");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");
const task = createNamespacedHelpers("task");
const authentication = createNamespacedHelpers("authentication");
let selected = [];
export default {
  components: { Multiselect },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "issue-tab-all",
    },
  },
  data() {
    return {
      code: selected,
    };
  },
  computed: {
    ...authentication.mapGetters(["activeCustomer"]),
    ...location.mapGetters(["locations"]),
    ...table.mapGetters(["searchField", "filterCollection"]),
    locationObjs() {
      let customerId = localStorage.getItem("customerId");
      selected.length = 0;
      let storeLocations = JSON.parse(
        localStorage.getItem("MultitagsItems_" + customerId) || "[]"
      );
      if (storeLocations.length > 0) {
        for (let i = 0; storeLocations.length > i; i++) {
          selected.push(storeLocations[i]);
        }
      } else {
        const activeLocation = store.getters["location/activeLocation"];
        selected.push(activeLocation);
      }

      let allLocations = [];
      for (let i = 0; i < this.locations.length; i++) {
        let loc = {
          name: this.locations[i].name,
          code: this.locations[i].code,
        };
        allLocations.push(loc);
        this.addChildren(this.locations[i], allLocations);
      }
      switch (this.tableType) {
        case "ISSUE":
          this.SET_FILTER_ISSUES_LOCATIONS(this.code);
          this.GET_ISSUE_LIST_LOCATIONS();
          break;
        case "TASK":
        case "COMPLETED_TASK":
          this.SET_FILTER_TASKS_LOCATIONS(this.code);
          this.GET_TASK_LIST_LOCATIONS();
          break;
        case "TASKS_PLANNED":
          this.SET_FILTER_TASKS_LOCATIONS(this.code);
          this.GET_TASKS_LOCATIONS_PLANNED();
          break;
        default:
          break;
      }
      return allLocations;
    },
  },
  methods: {
    ...issues.mapMutations(["SET_FILTER_ISSUES_LOCATIONS"]),
    ...issues.mapActions(["GET_ISSUE_LIST_LOCATIONS"]),
    ...task.mapMutations(["SET_FILTER_TASKS_LOCATIONS"]),
    ...task.mapActions(["GET_TASK_LIST_LOCATIONS"]),
    ...task.mapActions(["GET_TASKS_LOCATIONS_PLANNED"]),

    handleDeSelectAll() {
      selected = [];
      this.code = selected;
      this.handleSelected();
    },
    handleSelectAll() {
      selected = [];
      var obj = this.locationObjs;
      for (let i = 0; i < obj.length; i++) {
        selected.push(obj[i]);
      }
      this.code = selected;
      this.handleSelected();
    },
    handleSelected() {
      switch (this.tableType) {
        case "ISSUE":
          this.SET_FILTER_ISSUES_LOCATIONS(this.code);
          this.GET_ISSUE_LIST_LOCATIONS();
          break;
        case "TASK":
        case "COMPLETED_TASK":
          this.SET_FILTER_TASKS_LOCATIONS(this.code);
          this.GET_TASK_LIST_LOCATIONS();
          break;
        case "TASKS_PLANNED":
          this.SET_FILTER_TASKS_LOCATIONS(this.code);
          this.GET_TASKS_LOCATIONS_PLANNED();
          break;
        default:
          break;
      }
      let customerId = localStorage.getItem("customerId");
      localStorage.setItem(
        "MultitagsItems_" + customerId,
        JSON.stringify(this.code)
      );
    },
    addChildren(obj, toArr) {
      if (obj.children == null) {
        return;
      }
      if (obj.children.length > 0) {
        for (let i = 0; i < obj.children.length; i++) {
          let loc = {
            name: obj.children[i].name,
            code: obj.children[i].code,
          };
          toArr.push(loc);

          if (obj.children[i].children.length > 0) {
            for (
              let child2 = 0;
              child2 < obj.children[i].children.length;
              child2++
            ) {
              let loc2 = {
                name: obj.children[i].children[child2].name,
                code: obj.children[i].children[child2].code,
              };
              toArr.push(loc2);
              if (obj.children[i].children[child2].children.length > 0) {
                for (
                  let c3 = 0;
                  c3 < obj.children[i].children[child2].children.length;
                  c3++
                ) {
                  let loc3 = {
                    name: obj.children[i].children[child2].children[c3].name,
                    code: obj.children[i].children[child2].children[c3].code,
                  };
                  toArr.push(loc3);
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.beGone {
  display: none;
}
.selector {
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;
  font-size: small;
}
</style>
