<template>
  <ul class="filter-tabs">
    <li
      v-for="(option, index) in options"
      v-bind:key="'tab_' + index"
      class="filter-tabs__item"
      id="index"
      :class="{ 'filter-tabs__item--active': index === selected }"
      @click="handleClick(index, option)"
    >
      {{ option.text }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "FilterTabs",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    handleClick(index, option) {
      this.selected = index;
      this.$emit("click-tab", option);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/colors.scss";
.filter-tabs {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}

.filter-tabs__item {
  margin: 0 2rem;
  padding: 3.6rem 0;
  font-size: 1.5rem;
  font-weight: var(--font-weight--semibold);
  opacity: 0.5;

  &:hover {
    cursor: pointer;
  }

  &--active {
    opacity: 1;
    border-bottom: var(--border--lg) solid var(--color-accent-green);
  }
}
</style>
