<template>
  <button
    class="button"
    v-bind:class="{
      'button--secondary': secondary || filter,
      'button--secondary-padding': close,
      'button--upload': upload,
      'button--disabled': disabled,
    }"
    v-bind:disabled="disabled"
    v-on:click="$emit('buttonClick')"
  >
    <div v-if="upload" class="button__icon button__icon--upload">
      <IconUpload />
    </div>
    <div v-if="filter" class="button__icon button__icon--filter">
      <IconFilter />
    </div>
    <div v-if="close" class="button__icon button__icon--close">
      <IconClose />
    </div>
    <slot />
    <div v-if="arrow" class="button__icon button__icon--arrow">
      <IconArrow />
    </div>
  </button>
</template>

<script>
import IconArrow from "./icons/IconArrow.vue";
import IconClose from "./icons/IconClose.vue";
import IconFilter from "./icons/IconFilter.vue";
import IconUpload from "./icons/IconUpload.vue";

export default {
  name: "Button",
  components: {
    IconArrow,
    IconClose,
    IconFilter,
    IconUpload,
  },
  props: {
    arrow: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  display: flex;
  align-items: center;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--color-cbre-green);
  font-size: 18px;
  font-weight: var(--font-weight--semibold);
  color: var(--color-white);
  padding: 1rem 2.9rem;

  &:hover {
    cursor: pointer;
  }

  &--upload {
    padding: 1.5rem;
  }

  &--secondary {
    border: var(--border--md) solid var(--color-cbre-green);
    background-color: var(--color-white);
    color: var(--color-cbre-green);
    padding: 0.8rem 1rem;
  }

  &--disabled {
    background-color: var(--color-grey);
    cursor: default;
  }

  &--secondary-padding {
    padding: 0.8rem 1rem;
  }
}

.button__icon {
  fill: var(--color-cbre-green);
  width: 1.6rem;
}

.button__icon--arrow {
  margin-left: 0.5rem;
}

.button__icon--upload,
.button__icon--filter,
.button__icon--close {
  margin-right: 1rem;
}

.button__icon--filter,
.button__icon--close {
  margin-top: 0.3rem;
}

.button__icon--close {
  padding: 0.1rem 0.2rem;
}
</style>
