<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 25 25"
  >
    <g transform="translate(-3.5 -3.5)">
      <path
        d="M4.5,4.5h8.944v8.944H4.5Z"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M21,4.5h8.944v8.944H21Z"
        transform="translate(-2.444)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M21,21h8.944v8.944H21Z"
        transform="translate(-2.444 -2.444)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M4.5,21h8.944v8.944H4.5Z"
        transform="translate(0 -2.444)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconDashboard",
};
</script>
