import store from "@/store";
export function getUniqueList(data, tableType) {
  if (tableType === "INSTALLATION") {
    let collection = store.getters["table/filterCollection"];
    let result = [];
    if (collection === null) {
      return;
    }

    for (let i = 0; i < collection.length; i++) {
      result.push({ text: collection[i] });
    }
    return result;
  }

  // Refactor start
  let result = [];
  let typeIdentifier = "";
  switch (tableType) {
    case "TASKS_PLANNED":
      typeIdentifier = "type";
      break;

    default:
      typeIdentifier = "installationType";
  }

  Array.from(data).forEach((d) => {
    // if the element doesn't exist in array, then push it.
    if (result.findIndex((el) => el["text"] === d[typeIdentifier]) === -1) {
      result.push({ text: d[typeIdentifier] });
    }
  });

  result.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  return result;
}
