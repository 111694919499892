<template>
  <li class="accordion-item" :class="{ 'accordion-item--disabled': disabled }">
    <div class="accordion-item__header-container" @click="open">
      <div class="accordion-item__header">
        <slot name="accordion-header" />
      </div>
      <div
        class="accordion-item__trigger"
        :class="{ 'accordion-item__trigger--active': visible }"
      >
        <div class="accordion-item__trigger-icon">
          <svg
            role="img"
            width="100%"
            viewBox="0 0 20 20"
            stroke="currentColor"
            stroke-width="2px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="10" y1="0" x2="10" y2="20" />
            <line
              class="accordion-item__trigger-icon-line"
              x1="0"
              y1="10"
              x2="20"
              y2="10"
            />
          </svg>
        </div>
      </div>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible">
        <ul class="accordion-item__content">
          <slot name="accordion-content" />
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    },
  },
  methods: {
    open() {
      if (!this.disabled) {
        if (this.visible) {
          this.Accordion.active = null;
        } else {
          this.Accordion.active = this.index;
        }
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  cursor: pointer;
  position: relative;
  margin-bottom: 1.5rem;

  &--disabled {
    opacity: 0.1;
  }
}

.accordion-item__header-container {
  display: flex;
  justify-content: space-between;
  height: 5.7rem;
}

.accordion-item__header {
  width: 100%;
  padding: 1rem 2.9rem 1rem 1.8rem;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  background-color: var(--color-grey--light);
}

.accordion-item__trigger {
  width: var(--accordion-item-trigger--width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background-color: var(--color-accordion--trigger);
}

.accordion-item__trigger-icon {
  width: 1.5rem;
}

.accordion-item__trigger-icon-line {
  transition: transform 0.4s ease-in-out;
  transform-origin: center;

  .accordion-item__trigger--active & {
    transform: rotate(90deg);
  }
}

.accordion-item__content {
  padding: 1.5rem;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.4s ease, opacity 0.4s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
