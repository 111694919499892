import { SET_ACTIVE_REPORT, SET_REPORT_FILES_URL } from "../mutation-types";
import {
  DELETE_ISSUE_REPORT_FILE,
  GET_ALL_REPORTS,
  GET_ISSUE_REPORT,
  GET_ISSUE_REPORT_PDF,
  GET_SERVICE_REPORT,
  GET_CONTROL_REPORT,
  GET_SERVICE_REPORT_FILES,
  GET_CONTROL_REPORT_FILES,
  UPLOAD_ISSUE_REPORT_FILES,
  UPDATE_ISSUE_REPORT_STATUS,
  UPDATE_THERMOGRAPHY_STATUS,
  GET_ISSUE_DETAILS,
} from "../action-types";
import store from "@/store";
import axios from "../../service/api";

export default {
  namespaced: true,
  state: {
    activeTask: null,
    activeReport: null,
    reportFilesUrl: null,
  },
  getters: {
    activeReport: (state) => state.activeReport,
    reportFilesUrl: (state) => state.reportFilesUrl,
  },
  actions: {
    [GET_ISSUE_REPORT_PDF]({ state }) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/${state.activeReport}/${activeLocation.code}/issueReport/pdf`
        )
        .then(({ data }) => {
          var link = document.createElement("a");
          link.href = data;
          link.download = data.substr(data.lastIndexOf("/") + 1);
          link.target = "_blank";
          link.setAttribute("download", "");
          link.click();
          return "OK";
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
          return "Error";
        });
    },
    [DELETE_ISSUE_REPORT_FILE](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      const issueId = payload.issueId;
      const fileName = payload.file.fileName;

      return new Promise((resolve, reject) => {
        return axios
          .delete(
            `${process.env.VUE_APP_API_URL}/issue/${customerId}/deleteFile/${issueId}/${fileName}`
          )
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    [GET_ALL_REPORTS](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const {
        includeFiles,
        includeServiceReport,
        includeIssueReport,
        includeControlSchema,
        selectedIds,
        selectedInstallationIds,
        tableType,
        email,
        name,
      } = payload;

      var url;
      var data;
      if (tableType === "ISSUE") {
        url = `${process.env.VUE_APP_API_URL}/issue/${customerId}/zipfile/${activeLocation.code}/${includeFiles}/${includeServiceReport}/${includeIssueReport}/${includeControlSchema}`;
        data = {
          IssueIds: selectedIds,
          email: email,
          name: name,
        };
      } else if (tableType === "COMPLETED_TASK" || tableType === "TASK") {
        url = `${process.env.VUE_APP_API_URL}/task/${customerId}/zipfile/${activeLocation.code}/${includeFiles}/${includeServiceReport}/${includeIssueReport}/${includeControlSchema}`;
        data = {
          TaskIds: selectedIds,
          InstallationIds: selectedInstallationIds,
          email: email,
          name: name,
        };
      }
      return new Promise((resolve, reject) => {
        return axios
          .post(url, data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    [GET_ISSUE_DETAILS]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const issueId = payload.issueId;
      commit(SET_ACTIVE_REPORT, null);
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/issue/${issueId}`,
          JSON.stringify(activeLocation.code),
          {
            headers: {
              "Content-Type": "application/json",
            },
            dataType: "json",
          }
        )
        .then((data) => {
          commit(SET_ACTIVE_REPORT, data);
        });
    },
    [GET_ISSUE_REPORT]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const activeLocation = store.getters["location/activeLocation"];
      const issueId = payload.issueId;

      commit(SET_ACTIVE_REPORT, payload.issueId);
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/issue/${customerId}/issue/${issueId}`,
          JSON.stringify(activeLocation.code),
          {
            headers: {
              "Content-Type": "application/json",
            },
            dataType: "json",
          }
        )
        .then(({ data }) => {
          commit(
            "overlay/SET_ACTIVE_OVERLAY",
            { type: payload.type, content: data },
            { root: true }
          );
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [GET_SERVICE_REPORT]({ commit, state }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const taskId = payload.taskId;
      let installationReferenceCode = payload.installationReferenceCode;
      let installationNumber = installationReferenceCode.split("/");
      state.activeTask = payload.taskId;
      state.installationNumber = installationNumber[1];

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/task/${taskId}/${installationNumber[1]}/serviceReport`
        )
        .then(({ data }) => {
          commit(
            "overlay/SET_ACTIVE_OVERLAY",
            { type: payload.type, content: data },
            { root: true }
          );
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },

    [GET_SERVICE_REPORT_FILES]({ state }) {
      const customerId = store.getters["authentication/customerId"];
      const taskId = state.activeTask;
      const installationNumber = state.installationNumber;

      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/task/${taskId}/${installationNumber}/serviceReport/pdf`
        )
        .then(({ data }) => {
          var link = document.createElement("a");
          link.href = data;
          link.download = data.substr(data.lastIndexOf("/") + 1);
          link.target = "_blank";
          link.setAttribute("download", "");
          link.click();
          return "OK";
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
          return "Error";
        });
    },
    [GET_CONTROL_REPORT_FILES]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const installationReferenceCode = payload.installationReferenceCode;
      const controlSchemaFile = payload.controlSchemaFile;
      const installationName = payload.installationName;
      const installationType = payload.installationType;
      let locationName = payload.locationName.replace("A/S", "A-S");

      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/${locationName}/${installationName}/${installationType}/controlSchema/pdf`,
          {
            reportFile: controlSchemaFile,
            installationReferenceCode: installationReferenceCode,
          }
        )
        .then(({ data }) => {
          var link = document.createElement("a");
          link.href = data;
          link.download = data.substr(data.lastIndexOf("/") + 1);
          link.target = "_blank";
          link.setAttribute("download", "");
          link.click();
          commit();
          return "OK";
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
          return "Error";
        });
    },
    [GET_CONTROL_REPORT]({ commit }, payload) {
      // todo: remove logs when fixed
      const customerId = store.getters["authentication/customerId"];
      const installationReferenceCode = payload.installationReferenceCode;
      const controlSchemaFile = payload.controlSchemaFile;
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/task/${customerId}/controlSchema/`,
          {
            reportFile: controlSchemaFile,
            installationReferenceCode: installationReferenceCode,
            installationType: payload.installationType,
          }
        )
        .then(({ data }) => {
          data.installationName = payload.installationName;
          data.locationName = payload.locationName;
          data.installationType = payload.installationType;
          commit(
            "overlay/SET_ACTIVE_OVERLAY",
            { type: payload.type, content: data },
            { root: true }
          );
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
    [UPLOAD_ISSUE_REPORT_FILES](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      const issueId = payload.issueId;
      const fileList = payload.files;
      let formData = new FormData();

      for (var i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        formData.append("files", file, file.name);
      }

      return new Promise((resolve, reject) => {
        return axios
          .put(
            `${process.env.VUE_APP_API_URL}/issue/${customerId}/uploadFiles/${issueId}/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
    /* eslint-disable */
    [UPDATE_THERMOGRAPHY_STATUS]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const email = localStorage.getItem("Email");
      const name = localStorage.getItem("Name");
      const activeLocation = store.getters["location/activeLocation"];
      return axios.post(
        `${process.env.VUE_APP_API_URL}/issue/${customerId}/updateThermographyStatus/${payload.issueId}/${activeLocation.code}`,
        {
          IssueStatus: payload.status,
          InstallationReferenceCode: "",
          Comment: payload.comment,
          Email: email,
          Name: name,
        },
        {} // config
      );
    },
    [UPDATE_ISSUE_REPORT_STATUS]({ commit }, payload) {
      const customerId = store.getters["authentication/customerId"];
      const email = localStorage.getItem("Email");
      const name = localStorage.getItem("Name");
      const issueId = payload.issueId;
      return new Promise((resolve, reject) => {
        return axios
          .post(
            `${process.env.VUE_APP_API_URL}/issue/${customerId}/updateStatus/${issueId}`,
            {
              InstallationReferenceCode: payload.installationReferenceCode,
              IssueStatus: payload.status,
              Comment: payload.comment,
              Email: email,
              Name: name,
            },
            {} // config
          )
          .then(({ data }) => {
            resolve(data);
            commit();
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
  },
  mutations: {
    [SET_ACTIVE_REPORT](state, payload) {
      state.activeReport = payload;
    },
    [SET_REPORT_FILES_URL](state, payload) {
      state.reportFilesUrl = payload;
    },
  },
};
