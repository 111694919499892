<template>
  <div>
    <TransitionFade>
      <TableContainer
        v-if="tasksPlanned"
        :is-dashboard="false"
        :isSimpleTable="true"
        :table-data="tasksPlanned"
        :table-name="getDictionaryEntry('plannedTasks.table.headerText')"
        :table-page-size="10"
        :table-type="'TASKS_PLANNED'"
      />
    </TransitionFade>
  </div>
</template>
<script>
import TableContainer from "../../components/TableContainer.vue";
import TransitionFade from "../../components/TransitionFade.vue";
import { createNamespacedHelpers } from "vuex";
const task = createNamespacedHelpers("task");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");

export default {
  name: "KommendeEftersyn",
  components: {
    TableContainer,
    TransitionFade,
  },
  computed: {
    ...task.mapGetters(["tasksPlanned"]),
    ...location.mapGetters(["activeLocation"]),
    ...table.mapGetters(["getTimeInterval"]),
  },
  created() {
    if (this.activeLocation) {
      this.SET_TASK_STATUS("PLANNED");
      this.GET_TASKS_PLANNED();
    }
  },
  methods: {
    ...task.mapActions(["GET_TASKS_PLANNED", "UPDATE_TASK_STATUS"]),
    ...task.mapMutations(["SET_TASK_STATUS"]),
    ...table.mapActions(["UPDATE_TIME_INTERVAL"]),
  },
  watch: {
    activeLocation: function () {
      if (this.activeLocation) {
        this.GET_TASKS_PLANNED();
      }
    },
    getTimeInterval: function () {
      if (this.activeLocation) {
        this.GET_TASKS_PLANNED();
      }
    },
  },
};
</script>
