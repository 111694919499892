<template>
  <div class="link-task" v-on:click="toggleTask">
    {{ getDictionaryEntry("tableTask.viewTask") }}
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const task = createNamespacedHelpers("task");

export default {
  name: "LinkTask",
  data() {
    return {
      type: "task",
      task: null,
    };
  },
  beforeMount() {
    const data = this.params.data;
    this.task = data;
  },
  methods: {
    ...task.mapActions(["GET_TASK"]),
    toggleTask() {
      this.GET_TASK({ type: this.type, task: this.task });
      this.$store.dispatch("toggleOverlay", this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-task {
  height: 100%;
  cursor: pointer;
  font-size: 1.3rem;

  &:hover {
    color: var(--color-blue);
  }
}
</style>
