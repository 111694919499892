<template>
  <div class="link-download">
    <ul>
      <li
        style="vertical-align: top"
        v-for="(file, index) in files"
        :key="index"
        class="link-download__item"
      >
        <a
          v-if="displayImage(file.url)"
          class="link-download__item-text"
          :href="file.url"
          target="_blank"
          download
        >
          <img :src="file.url" width="60" />
        </a>
        <a
          v-else
          class="link-download__item-text"
          :href="file.url"
          target="_blank"
          download
        >
          <img src="../assets/file.png" width="60" />
        </a>
        <span style="margin-left: 1%"></span>
        <a
          class="link-download__item-text"
          :href="file.url"
          target="_blank"
          download
        >
          {{ file.displayName }}
        </a>
        <span style="margin-left: 1%"></span>
        <div
          v-if="deleteFileButton"
          class="link-download__item-icon"
          @click="$emit('delete-file', file, index)"
        >
          <IconTrash />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import IconTrash from "./icons/IconTrash.vue";

export default {
  name: "LinkDownload",
  components: {
    IconTrash,
  },
  methods: {
    displayImage: function (url) {
      var imageRegExp = /\.(jpeg|jpg|png|gif)/i;
      if (url.toLowerCase().match(imageRegExp)) {
        return true;
      } else {
        return false;
      }
    },
    openUrl: function (url) {
      window.open(url, "_blank").focus();
    },
  },
  props: {
    files: {
      type: Array,
      default: null,
    },
    deleteFileButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  transition: transform 0.2s;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid black;
}

img:hover {
  transform: scale(2);
}
.link-download {
  display: flex;
  flex-direction: column;
}

.link-download__title {
  display: flex;
  align-items: center;
}

.link-download__title-text {
  font-size: 1.6rem;
}

.link-download__title-icon {
  width: 2rem;
  margin-left: 1rem;
}

.link-download__item {
  display: flex;
  font-size: 1.3rem;
  margin-top: 0.3rem;
}

.link-download__item-text {
  color: black;
  font-size: 1.5rem;
}

.link-download__item-icon {
  flex-shrink: 0;
  width: 1.1rem;
  left: 0;
  color: var(--color-red);
  margin-right: 1rem;
  margin-top: 0.2rem;
  &:hover {
    cursor: pointer;
  }
}
</style>
