import { SET_ACTIVE_OVERLAY } from "../mutation-types";
import { UPDATE_ACTIVE_OVERLAY, CLEAR_ACTIVE_OVERLAY } from "../action-types";

export default {
  namespaced: true,
  state: {
    activeOverlay: {
      type: null,
      content: null,
    },
  },
  getters: {
    activeOverlay: (state) => state.activeOverlay,
  },
  actions: {
    [CLEAR_ACTIVE_OVERLAY]({ commit }) {
      setTimeout(() => {
        commit(SET_ACTIVE_OVERLAY, null);
      }, 500);
    },
    [UPDATE_ACTIVE_OVERLAY]({ commit }, payload) {
      commit(SET_ACTIVE_OVERLAY, payload);
    },
  },
  mutations: {
    [SET_ACTIVE_OVERLAY](state, payload) {
      // to prevent racecondition for setting the overlay
      if (payload !== null) {
        if (payload.type !== state.type) {
          return;
        }
      }
      if (payload === null) {
        state.activeOverlay.content = null;
      } else {
        state.activeOverlay = {
          type: payload.type,
          content: payload.content,
        };
      }
    },
  },
};
