<template>
  <div class="installation-description" v-if="files">
    <div
      class="installation-description__item"
      v-for="(file, index) in files"
      v-bind:key="'fileDescription_' + index"
    >
      <div v-if="file.shortDescription">{{ file.shortDescription }}</div>
      <div class="installation-description__item-placeholder" v-else>|</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallationDescription",
  data() {
    return { files: null };
  },
  created() {
    this.installation = this.params.data;

    if (this.installation !== undefined) {
      this.files = this.installation.files;
    }
  },
};
</script>

<style lang="scss" scoped>
.installation-description {
  width: 95%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.7rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.installation-description__item {
  font-size: 1.3rem;
  line-height: 1;
  padding-bottom: 1.3rem;
}

.installation-description__item-placeholder {
  opacity: 0;
}
</style>
