import axios from "../../service/api";
import { GET_LOCATION_STATS } from "@/store/action-types";
import store from "@/store";
export default {
  namespaced: true,
  actions: {
    [GET_LOCATION_STATS](_, payload) {
      const customerId = store.getters["authentication/customerId"];
      const locationCode = payload.locationCode;
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/chart/${customerId}/location/${locationCode}/statistics`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            dataType: "json",
          }
        )
        .then((data) => {
          return data.data;
        });
    },
  },
};
