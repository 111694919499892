<template>
  <div style="min-width: 100px">
    <img :src="CbreLogo" />
  </div>
</template>

<script>
import Logo from "../assets/cbre-logo-green.svg";

export default {
  name: "Logo",
  data() {
    return {
      CbreLogo: Logo,
    };
  },
};
</script>

<style lang="css" scoped>
.logo {
  width: 18.3rem;
}
</style>
