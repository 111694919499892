<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 24.473 27.037"
  >
    <g transform="translate(-3.47 -1.966)">
      <path
        d="M26.913,20.433V10.471a2.49,2.49,0,0,0-1.245-2.154L16.951,3.337a2.49,2.49,0,0,0-2.49,0L5.745,8.317A2.49,2.49,0,0,0,4.5,10.471v9.961a2.49,2.49,0,0,0,1.245,2.154l8.716,4.981a2.49,2.49,0,0,0,2.49,0l8.716-4.981a2.49,2.49,0,0,0,1.245-2.154Z"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M4.905,10.44l10.87,6.288,10.87-6.288"
        transform="translate(-0.069 -1.264)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M18,30.551V18"
        transform="translate(-2.294 -2.548)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconUnit",
};
</script>
