<template>
  <Modal
    :heading="getDictionaryEntry('modalDownloadReports.title')"
    :submit="showSubmit"
    :submitName="getDictionaryEntry('modalDownloadReports.buttonSubmit')"
    :close-modal="closeModal"
    @modalClosed="onModalClosed"
  >
    <template v-slot:body>
      <div v-show="!downloading">
        <div class="download-report__label">
          {{ getDictionaryEntry("modalDownloadReports.selectReportsLabel") }}:
        </div>

        <div class="download-report__reports">
          <div class="download-report__trigger">
            <div
              class="download-report__icon-container"
              v-on:click="issueClick"
            >
              <div class="download-report__icon">
                <IconIssue />
              </div>
            </div>
            <div class="download-report__checkbox">
              <Checkbox
                :checked="issueChecked"
                @checkbox-change="issueClick"
                :checkboxId="'checkbox_download_report_issue'"
              />
              <div class="download-report__checkbox-label">
                {{
                  getDictionaryEntry("modalDownloadReports.reportIssueLabel")
                }}
              </div>
            </div>
          </div>

          <div class="download-report__trigger">
            <div
              class="download-report__icon-container"
              v-on:click="serviceClick"
            >
              <div class="download-report__icon">
                <IconService />
              </div>
            </div>
            <div class="download-report__checkbox">
              <Checkbox
                :checked="serviceChecked"
                @checkbox-change="serviceClick"
                :checkboxId="'checkbox_download_report_service'"
              />
              <div class="download-report__checkbox-label">
                {{
                  getDictionaryEntry("modalDownloadReports.reportServiceLabel")
                }}
              </div>
            </div>
          </div>

          <div class="download-report__trigger">
            <div
              class="download-report__icon-container"
              v-on:click="controlClick"
            >
              <div class="download-report__icon download-report__icon--control">
                <IconControl />
              </div>
            </div>
            <div class="download-report__checkbox">
              <Checkbox
                :checked="controlChecked"
                @checkbox-change="controlClick"
                :checkboxId="'checkbox_download_report_control'"
              />
              <div class="download-report__checkbox-label">
                {{
                  getDictionaryEntry("modalDownloadReports.reportControlLabel")
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="download-report__checkbox download-report__checkbox--files">
          <Checkbox
            :checked="filesChecked"
            @checkbox-change="filesClick"
            :checkboxId="'checkbox_download_report_files'"
          />
          <div class="download-report__checkbox-label">
            {{ getDictionaryEntry("modalDownloadReports.addAttachedFiles") }}
          </div>
        </div>

        <Button @click="getReports">{{
          getDictionaryEntry("modalDownloadReports.buttonDownloadFiles")
        }}</Button>
      </div>

      <div v-show="downloading" class="download-report__spinner">
        <p class="">
          {{
            getDictionaryEntry(
              "modalDownloadReports.downloadingWaitForEmailText"
            )
          }}
        </p>
      </div>
    </template>
    <template v-slot:trigger>
      <Button :disabled="selectedRows.length < 1">{{
        getDictionaryEntry("modalDownloadReports.buttonDownloadFiles")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import Button from "./Button.vue";
import Modal from "./Modal.vue";
import IconIssue from "./icons/IconIssue.vue";
import IconService from "./icons/IconService.vue";
import IconControl from "./icons/IconControl.vue";
import Checkbox from "./Checkbox.vue";
import {
  ISSUE,
  ISSUE_THERMOGRAPHY,
  TASK,
  COMPLETED_TASK,
  INSTALLATION,
  TASKS_PLANNED,
} from "../mixins/columnDefinitions";
import { createNamespacedHelpers } from "vuex";
const table = createNamespacedHelpers("table");
const report = createNamespacedHelpers("report");
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "ModalDownloadReports",
  components: {
    Button,
    Checkbox,
    Modal,
    IconIssue,
    IconService,
    IconControl,
  },
  props: {
    tableType: {
      type: String,
      default: null,
      validator(value) {
        // The value must match one of these strings
        return [
          ISSUE,
          ISSUE_THERMOGRAPHY,
          TASK,
          COMPLETED_TASK,
          INSTALLATION,
          TASKS_PLANNED,
        ].includes(value);
      },
    },
  },
  data() {
    return {
      closeModal: false,
      downloading: false,
      filesChecked: false,
      issueChecked: false,
      serviceChecked: false,
      controlChecked: false,
      selectedRows: [],
      showSubmit: false,
    };
  },
  computed: {
    ...table.mapGetters(["selectedIssueRows", "selectedTaskRows"]),
    ...authentication.mapGetters(["activeCustomer"]),
  },
  methods: {
    ...report.mapActions(["GET_ALL_REPORTS"]),
    ...report.mapMutations(["CLEAR_SELECTED_ISSUE_ROWS"]),
    onModalClosed() {
      this.closeModal = false; // reset bool
    },
    controlClick() {
      this.controlChecked = !this.controlChecked;
    },
    filesClick() {
      this.filesChecked = !this.filesChecked;
    },
    issueClick() {
      this.issueChecked = !this.issueChecked;
    },
    getReports() {
      this.downloading = true;
      var selectedIds;
      var selectedInstallationIds;

      if (this.tableType === "COMPLETED_TASK" || this.tableType === "TASK") {
        selectedIds = this.selectedTaskRows.map((x) => x.id);
        selectedInstallationIds = this.selectedTaskRows.map(
          (x) => x.installationReferenceCode
        );
      } else if (this.tableType === "ISSUE") {
        selectedIds = this.selectedIssueRows.map((x) => x.id);
      }

      this.GET_ALL_REPORTS({
        includeFiles: this.filesChecked,
        includeServiceReport: this.serviceChecked,
        includeIssueReport: this.issueChecked,
        includeControlSchema: this.controlChecked,
        selectedIds: selectedIds,
        selectedInstallationIds: selectedInstallationIds,
        tableType: this.tableType,
        email: this.activeCustomer.email,
        name: this.activeCustomer.name,
      })
        .then((data) => {
          console.log(data);
        })
        .catch(() => {
          this.closeModal = true;
          this.downloading = false;
          this.resetCheckboxes();
        });
      setTimeout(() => {
        this.selectedRows = [];
        this.selectedIds = [];
        selectedInstallationIds = [];
        this.closeModal = true;
        this.downloading = false;
        this.resetCheckboxes();
        this.$emit("resetEvent", 0);
      }, 5000);
    },
    resetCheckboxes() {
      this.filesChecked = false;
      this.serviceChecked = false;
      this.issueChecked = false;
      this.controlChecked = false;
    },
    serviceClick() {
      this.serviceChecked = !this.serviceChecked;
    },
  },
  watch: {
    selectedIssueRows: function (value) {
      this.selectedRows = value;
    },
    selectedTaskRows: function (value) {
      this.selectedRows = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.download-report__label {
  margin-bottom: 1.6rem;
}

.download-report__reports {
  width: 100%;
  display: block;
  padding-bottom: 3.8rem;
  border-bottom: var(--border--sm) solid var(--color-grey);

  @include tablet-portrait {
    display: flex;
  }
}

.download-report__trigger {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 3rem;

  @include tablet-portrait {
    display: flex;
    margin-right: 3rem;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.download-report__icon-container {
  display: flex;
  height: 10rem;
  justify-content: center;
  align-items: center;
  border-width: var(--border--sm) solid var(--color-grey);
  background-color: var(--color-grey--light);
}

.download-report__icon {
  width: 4.5rem;
  color: var(--color-grey);

  &--control {
    width: 3.6rem;
  }
}

.download-report__checkbox {
  display: flex;
  align-items: center;
  margin-top: 1.6rem;

  &--files {
    margin-top: 2.2rem;
    margin-bottom: 3.8rem;
  }
}

.download-report__checkbox-label {
  margin-left: 1.2rem;
}

.download-report__spinner {
  position: relative;
  width: 100%;
  height: 20rem;
}

.download-report__spinner-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0.5rem);
}
</style>
