<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 24.504 27"
  >
    <g transform="translate(-3.5 -2)">
      <path
        d="M23.253,10a7.265,7.265,0,0,0-7.5-7,7.265,7.265,0,0,0-7.5,7c0,8.169-3.751,10.5-3.751,10.5H27S23.253,18.171,23.253,10"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M20.595,31.5a3,3,0,0,1-5.19,0"
        transform="translate(-2.248 -4.995)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconNotification",
};
</script>
