<template>
  <nav class="navigation-header">
    <ul class="navigation-header__list">
      <li class="navigation-header__item">
        <Select
          :defaultSelected="{ text: 'Dansk', value: 'da-dk' }"
          :secondary="true"
          :options="languageSelections"
          @input="handleSelectInput"
        />
      </li>
      <li class="navigation-header__item">
        <Link :isHyperlink="true">
          <a
            style="text-decoration: none; color: var(--color-cbre-green)"
            href="https://cbre-tekniskservicepartner.dk/kundeportal/"
            >{{ getDictionaryEntry("headerItem.gotocustomerportal") }}</a
          >
        </Link>
      </li>
      <li class="navigation-header__item">
        <Link url="/guide">{{ getDictionaryEntry("headerItem.guide") }}</Link>
      </li>
      <li class="navigation-header__item" v-if="activeLocation">
        <LabelLocation />
      </li>
      <li class="navigation-header__item" v-if="showCutomerSelect">
        <ModalCustomer>
          <template v-slot:trigger>
            <Button :secondary="true" :arrow="true">{{
              getDictionaryEntry("headerItem.changeCustomer")
            }}</Button>
          </template>
        </ModalCustomer>
      </li>
      <li class="navigation-header__item">
        <ModalLocation>
          <template v-slot:trigger>
            <Button :secondary="true" :arrow="true">{{
              getDictionaryEntry("headerItem.changeLocation")
            }}</Button>
          </template>
        </ModalLocation>
      </li>
    </ul>
  </nav>
</template>
<script>
import Button from "./Button.vue";
import Link from "./Link.vue";
import Select from "./Select.vue";
import ModalLocation from "./ModalLocation.vue";
import ModalCustomer from "./ModalCustomer.vue";
import LabelLocation from "./LabelLocation.vue";
import { languageOptions } from "../store/modules/dictionary";
import { createNamespacedHelpers } from "vuex";
const dictionary = createNamespacedHelpers("dictionary");
const location = createNamespacedHelpers("location");
const customer = createNamespacedHelpers("customer");
const authentication = createNamespacedHelpers("authentication");

export default {
  name: "NavigationHeader",
  components: {
    Button,
    Link,
    Select,
    ModalLocation,
    ModalCustomer,
    LabelLocation,
  },
  watch: {
    customerId: function () {
      this.setCustomers();
    },
  },
  computed: {
    ...authentication.mapGetters(["customerId"]),
    ...location.mapGetters(["activeLocation"]),
    ...customer.mapGetters(["showCutomerSelect"]),
    languageSelections: function () {
      return languageOptions;
    },
  },
  methods: {
    ...dictionary.mapActions(["GET_DICTIONARY"]),
    ...customer.mapActions(["GET_CUSTOMER_LIST"]),
    handleSelectInput(option) {
      this.GET_DICTIONARY(option);
    },
    setCustomers() {
      this.GET_CUSTOMER_LIST().then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-header__list {
  list-style: none;
  display: flex;
  align-items: center;
}

.navigation-header__item {
  margin-right: 3.5rem;

  &:last-child {
    margin: 0;
  }
}
</style>
