<template>
  <div class="label-status" :class="{ 'label-status--secondary': secondary }">
    <div
      v-if="!secondary"
      class="label-status__icon"
      :class="[
        { 'label-status__icon--red': color === 'red' },
        { 'label-status__icon--yellow': color === 'yellow' },
      ]"
    ></div>
    <div class="label-status__text">
      <slot />
    </div>
    <div
      v-if="secondary"
      class="label-status__icon"
      :class="[
        { 'label-status__icon--red': color === 'red' },
        { 'label-status__icon--yellow': color === 'yellow' },
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "LabelStatus",
  props: {
    color: {
      type: String,
      default: "",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label-status {
  display: flex;
  align-items: center;
}

.label-status__icon {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  margin-right: 1.1rem;

  &--red {
    background-color: var(--color-red);
  }

  &--yellow {
    background-color: var(--color-yellow);
  }

  .label-status--secondary & {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.1rem;
  }
}

.label-status__text {
  font-size: 1.8rem;
  font-weight: var(--font-weight--semibold);

  .label-status--secondary & {
    font-size: 1.6rem;
    font-weight: unset;
  }
}
</style>
