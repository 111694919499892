<template>
  <div>
    <div v-if="label" class="textarea__label">{{ label }}</div>
    <textarea
      rows="4"
      v-on:input="handleInput"
      class="textarea"
      :class="{ 'textarea--disabled': disabled }"
      v-bind:disabled="disabled"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("handleInput", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  border: var(--border--sm) solid var(--color-grey--light);
  box-sizing: border-box;
  padding: 1rem;

  &--disabled {
    background-color: var(--color-grey);
    color: var(--color-white);
    cursor: initial;
  }
}

.textarea__label {
  font-weight: var(--font-weight--semibold);
  margin-bottom: 1.3rem;
}
</style>
