<template>
  <li
    class="accordion-item"
    :class="{
      'accordion-item--disabled': disabled,
      'accordion-item--selected': location.name === activeLocation.name,
    }"
  >
    <div class="accordion-item__header-container">
      <div
        class="accordion-item__header"
        @click="handleLocationClick(location)"
      >
        <div class="accordion-item__header-content">
          <div class="accordion-item__name">{{ location.name }}</div>
          <div class="accordion-item__code">{{ location.code }}</div>
        </div>
        <div class="accordion-item__status-list">
          <div
            class="accordion-item__status accordion-item__status--red"
            :class="{
              'accordion-item__status--disabled': !location.hasIncidents,
            }"
          >
            {{ getDictionaryEntry("modalLocation.locationBlock.labelIssue") }}
          </div>
          <div
            class="accordion-item__status accordion-item__status--yellow"
            :class="{
              'accordion-item__status--disabled': !location.hasOverdue,
            }"
          >
            {{
              getDictionaryEntry(
                "modalLocation.locationBlock.labelTasksOverdue"
              )
            }}
          </div>
        </div>
      </div>
      <div
        class="accordion-item__trigger"
        :class="{ 'accordion-item__trigger--active': visible }"
        @click="open"
      >
        <div class="accordion-item__trigger-icon">
          <svg
            role="img"
            width="100%"
            viewBox="0 0 20 20"
            stroke="currentColor"
            stroke-width="2px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="10" y1="0" x2="10" y2="20" />
            <line
              class="accordion-item__trigger-icon-line"
              x1="0"
              y1="10"
              x2="20"
              y2="10"
            />
          </svg>
        </div>
      </div>
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="visible">
        <ul class="accordion-item__content">
          <li
            v-for="(subLocation, index) in location.children"
            :key="'subLocation_' + index"
          >
            <div v-if="subLocation.children.length > 0">
              <Accordion>
                <AccordionItemLocation
                  @location-click="handleLocationClick"
                  :location="subLocation"
                />
              </Accordion>
            </div>
            <div v-else>
              <LocationBlock
                @location-click="handleLocationClick"
                :location="subLocation"
                :secondary="true"
                :selected="subLocation.name === activeLocation.name"
              />
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
import LocationBlock from "./LocationBlock.vue";
import { createNamespacedHelpers } from "vuex";
import Accordion from "./Accordion.vue";
import AccordionItemLocation from "./AccordionItemLocation.vue";
const location = createNamespacedHelpers("location");

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LocationBlock,
    Accordion,
    AccordionItemLocation,
  },
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    ...location.mapGetters(["activeLocation"]),
    visible() {
      return this.index == this.Accordion.active;
    },
  },
  methods: {
    handleLocationClick(location) {
      this.$emit("location-click", location);
    },
    open() {
      if (!this.disabled) {
        if (this.visible) {
          this.Accordion.active = null;
        } else {
          this.Accordion.active = this.index;
        }
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.accordion-item {
  cursor: pointer;
  position: relative;
  width: 100%;

  &--disabled {
    opacity: 0.1;
  }
}

.accordion-item__header-container {
  display: flex;
  justify-content: space-between;
  height: 5.7rem;
}

.accordion-item__header {
  width: 100%;
  padding: 1rem 2.9rem 1rem 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  background-color: var(--color-grey--light);

  &:hover {
    cursor: pointer;
    background-color: var(--color-grey--extralight);
  }

  .accordion-item--selected & {
    border: 0.2rem solid var(--color-green);
    border-right: 0;
  }
}

.accordion-item__header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion-item__name {
  font-size: 1.4rem;
  font-weight: var(--font-weight--semibold);
  margin-bottom: 0.2rem;

  @include tablet-portrait {
    font-size: 1.6rem;
  }
}

.accordion-item__code {
  font-size: 1.1rem;

  @include tablet-portrait {
    font-size: 1.2rem;
  }
}

.accordion-item__status-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include tablet-portrait {
    flex-direction: row;
    align-items: unset;
  }
}

.accordion-item__status {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  text-align: right;
  margin: 0.5rem 0;

  @include tablet-portrait {
    margin: 0;
    margin-right: 2.7rem;
    font-size: 1.6rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1.1rem;
    border-radius: 50%;

    @include tablet-portrait {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--red::after {
    background-color: var(--color-red);
  }

  &--yellow::after {
    background-color: var(--color-yellow);
  }

  &--disabled {
    opacity: 0.1;
  }
}

.accordion-item__trigger {
  width: var(--accordion-item-trigger--width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background-color: var(--color-accordion--trigger);

  .accordion-item--selected & {
    border: 0.2rem solid var(--color-green);
    border-left: 0;
    height: calc(100% - 0.4rem);
  }
}

.accordion-item__trigger-icon {
  width: 1.5rem;
}

.accordion-item__trigger-icon-line {
  transition: transform 0.4s ease-in-out;
  transform-origin: center;

  .accordion-item__trigger--active & {
    transform: rotate(90deg);
  }
}

.accordion-item__content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.4s ease, opacity 0.4s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
