<template>
  <div>
    <h1>
      {{ news.title }}
    </h1>
    <h3>
      {{ news.teaser }}
    </h3>
    <p>{{ releaseDate }}</p>

    <div style="margin-top: 1em">
      <span class="news-body" v-html="news.body"></span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { dateDanishFormat } from "../../helpers/dateFormatter";

const news = createNamespacedHelpers("newsfeed");

export default {
  name: "OverlayNews",
  computed: {
    ...news.mapGetters({ news: "activeNews" }),
    releaseDate() {
      return dateDanishFormat(this.news.releaseDate);
    },
  },
};
</script>

<style lang="scss" scoped>
// deep affects all generated html in body
.news-body::v-deep li {
  list-style-type: disc !important;
  margin: initial;
}
.news-body::v-deep ul {
  padding: 0 0 0 40px;
}
</style>
