<template>
  <div>
    <Modal ref="modalRef">
      <template v-slot:header>
        <h2 v-if="checked">
          {{
            getDictionaryEntry("modalNewsletterSubscription.title.unsubcribe")
          }}
        </h2>
        <h2 v-else>
          {{ getDictionaryEntry("modalNewsletterSubscription.title.subcribe") }}
        </h2>
      </template>
      <template v-slot:body>
        <p v-if="checked">
          {{
            getDictionaryEntry("modalNewsletterSubscription.body.unsubcribe")
          }}
        </p>
        <p v-else>
          {{ getDictionaryEntry("modalNewsletterSubscription.body.subcribe") }}
        </p>
        {{ activeCustomer.email }}
        <br /><br />
        <div>
          <Button
            v-if="!isRequestBusy"
            @click="handleNewsFeedSubscribeButtonClick"
            style="display: inline; margin-right: 1rem"
            >OK</Button
          >
          <Button @click="toggleModal" style="display: inline">Annuller</Button>
        </div>
      </template>
      <template v-slot:trigger>
        <Button>
          <div v-if="!checked">Tilmeld nyhedsbrev</div>
          <div v-else>Frameld nyhedsbrev</div>
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Modal from "../Modal.vue";
import Button from "../Button.vue";

const authentication = createNamespacedHelpers("authentication");
const employee = createNamespacedHelpers("employee");
// const modal = createNamespacedHelpers("modal");

export default {
  name: "ModalNewsfeedSubscription",
  components: {
    Modal,
    Button,
  },
  computed: {
    ...authentication.mapGetters(["activeCustomer"]),
    ...employee.mapGetters(["isSubscribed", "isRequestBusy"]),
  },
  created() {
    this.checked = this.isSubscribed;
  },
  data() {
    return {
      showDeleting: false,
      checked: false,
    };
  },
  methods: {
    ...employee.mapActions([
      "SUBSCRIBE_TO_NEWSFEED",
      "UNSUBSCRIBE_FROM_NEWSFEED",
    ]),
    toggleModal() {
      this.$refs.modalRef.toggleModal();
    },
    handleNewsFeedSubscribeButtonClick() {
      var promise;
      if (this.checked) {
        promise = this.UNSUBSCRIBE_FROM_NEWSFEED();
      } else {
        promise = this.SUBSCRIBE_TO_NEWSFEED();
      }
      promise.then(() => {
        this.$refs.modalRef.toggleModal();
        this.checked = !this.checked;
      });
    },
  },
  watch: {
    isSubscribed: function () {
      this.checked = this.isSubscribed;
    },
  },
};
</script>
