import moment from "moment";

export const dateAndHoursFormat = (date) => {
  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };

  const d = date,
    dFormat =
      [
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        d.getFullYear(),
      ].join("-") +
      " " +
      [
        d.getHours().padLeft(),
        d.getMinutes().padLeft(),
        d.getSeconds().padLeft(),
      ].join(":");

  return dFormat;
};

export const dateDanishFormat = (date) => {
  if (date === null) return;
  if (date === undefined) {
    return moment().locale("da-dk").format("L");
  }

  // the parameter can contain the date either at "date" and "date.value"
  var result;
  if (date.value === undefined) {
    result = moment(date).locale("da-dk").format("L");
  } else {
    result = moment(date.value).locale("da-dk").format("L");
  }
  return result;
};

export const dateEnglishFormat = (date) => {
  return moment(Date(date.value)).locale("en-gb").format("L");
};

export const dateDanishMonthName = (date) => {
  return moment(Date(date.value)).locale("da-dk").format("LL");
};

export const getInterval = (yearsFromToday) => {
  // timeinterval is before today
  if (yearsFromToday < 0) {
    return {
      dateFrom: (() => {
        const d = new Date();
        d.setFullYear(d.getFullYear() + yearsFromToday);
        return dateAndHoursFormat(d);
      })(),
      dateTo: dateAndHoursFormat(new Date()),
    };
  }

  // timeinterval is after today
  return {
    dateTo: (() => {
      const d = new Date();
      d.setFullYear(d.getFullYear() + yearsFromToday);
      return dateAndHoursFormat(d);
    })(),
    dateFrom: dateAndHoursFormat(new Date()),
  };
};
