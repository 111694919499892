<template>
  <div v-if="details" class="key-numbers">
    <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.units }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.installations") }}
      </div>
    </div>

    <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.currentIssues }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.activeIssues") }}
      </div>
    </div>
    <!-- <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.issuePercentOfTasks }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.issuePercentOfTasks") }}
      </div>
    </div> -->

    <!-- <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.issuesClosed }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.issuesCorrected") }}
      </div>
    </div>
    <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.tasksClosed }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.completedTasksWithoutIssues") }}
      </div>
    </div> -->

    <!-- <div class="key-numbers__item">
      <div class="key-numbers__number">{{ details.upcomingTasks }}</div>
      <div class="key-numbers__text">
        {{ getDictionaryEntry("keyNumbers.tasksNextMonth") }}
      </div>
    </div> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const location = createNamespacedHelpers("location");

export default {
  name: "KeyNumbers",
  data() {
    return {
      keyNumbers: [],
      details: null,
    };
  },
  computed: {
    ...location.mapGetters(["activeLocation", "activeLocationDetails"]),
  },
  watch: {
    activeLocation: function () {
      this.GET_ACTIVE_LOCATION_DETAILS().then((data) => {
        this.details = data;
      });
    },
  },
  beforeMount() {
    if (this.activeLocation) {
      this.GET_ACTIVE_LOCATION_DETAILS().then((data) => {
        this.details = data;
      });
    }
  },
  methods: {
    ...location.mapActions(["GET_ACTIVE_LOCATION_DETAILS"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";
@import "@/styles/typography.scss";

.key-numbers {
  display: grid;
  justify-content: center;
  grid-template-rows: auto auto auto;
  grid-template-columns: repeat(2, minmax(auto, 20.4rem));
  grid-gap: 2rem;
  grid-template-areas:
    "item item"
    "item item"
    "item item";
  padding: 0 var(--spacing-mobile-padding-x);

  @include tablet-portrait {
    justify-content: space-between;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
    grid-template-areas:
      "item item item"
      "item item item";
    padding: 0;
  }

  @include desktop {
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "item item item item";
  }
}

.key-numbers__item {
  background-color: var(--color-white);
  text-align: center;
  align-items: center;
  padding: 3rem 2rem;

  @include tablet-portrait {
    padding: 3rem 2rem;
  }
}

.key-numbers__number {
  font-size: 4.6rem;
  font-weight: var(--font-weight--semibold);
  margin-bottom: 1rem;

  @include tablet-portrait {
    font-size: 5.5rem;
  }
}

.key-numbers__text {
  font-size: 18px;
  color: var(--color-secondary);
  word-break: break-word;

  @include tablet-portrait {
    font-size: 18px;
  }
}
</style>
