<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 19.278 19.278"
    class=""
  >
    <g transform="translate(1.176 1.102)">
      <path
        d="M13.465,5.846a.894.894,0,0,0,0,1.252L14.9,8.529a.894.894,0,0,0,1.252,0l3.372-3.372a5.367,5.367,0,0,1-7.1,7.1L6.238,18.44a1.9,1.9,0,0,1-2.683-2.683L9.735,9.576a5.367,5.367,0,0,1,7.1-7.1L13.474,5.837Z"
        transform="translate(-2.999 -1.996)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconService",
};
</script>
