<template>
  <div v-if="isHyperlink" class="link" :class="{ 'link--arrow': arrow }">
    <slot />
  </div>
  <div v-else>
    <router-link :to="url" class="link" :class="{ 'link--arrow': arrow }">
      <slot />
      <div v-if="arrow" class="link__arrow">
        <IconArrow />
      </div>
    </router-link>
  </div>
</template>

<script>
import IconArrow from "./icons/IconArrow.vue";

export default {
  name: "Link",
  components: {
    IconArrow,
  },
  props: {
    url: {
      type: String,
      default: "\/",
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    isHyperlink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-cbre-green);
  font-weight: var(--font-weight--semibold);
  text-decoration: none;

  &:hover {
    color: var(--color-cbre-green);
    cursor: pointer;
  }

  &--arrow {
    padding-right: 2.2rem;
  }
}

.link__arrow {
  position: absolute;
  right: 0;
  width: 1.6rem;
  transition: right 0.5s ease-out;

  .link:hover & {
    right: -0.3rem;
  }
}
</style>
