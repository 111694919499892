<template>
  <div>
    <input
      type="checkbox"
      class="checkbox"
      v-on:change="handleChange"
      :checked="checked"
      :id="checkboxId"
    />
    <label :for="checkboxId" class="checkbox__label-box"> </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    checkboxId: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit("checkbox-change", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  box-sizing: border-box;
  position: absolute;
  top: -9999px;
  left: -9999px;
  appearance: none;
  opacity: 0;
}

.checkbox__label-box {
  display: flex;
  align-self: center;
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
  border: var(--border--md) solid var(--color-checkbox-border);
  border-radius: 2px;
  cursor: pointer;
}

.checkbox:checked + .checkbox__label-box {
  border: var(--border--md) solid var(--color-checkbox-border--checked);

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.2rem;
    left: 0.4rem;
    width: 0.2rem;
    height: 0.5rem;
    border: solid var(--color-primary);
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
  }
}
</style>
