<template>
  <div>
    <div class="block-simple__header">
      <slot name="header" />
    </div>
    <div class="block-simple__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockSimple",
};
</script>

<style lang="scss" scoped>
.block-simple__header {
  background-color: var(--color-white);
  padding: 3.3rem 2.5rem;
  margin-bottom: 0.3rem;
}

.block-simple__body {
  padding: 3.3rem 2.5rem 10rem;
  background-color: var(--color-white);
}
</style>
