import axios from "../../service/api";
import { GET_CUSTOMER_LIST } from "@/store/action-types";
import { SET_CUSTOMERS } from "@/store/mutation-types";
import store from "@/store";
export default {
  namespaced: true,
  state: {
    customers: null,
    showCutomerSelect: false,
  },
  getters: {
    customers: (state) => state.customers,
    showCutomerSelect: (state) => state.showCutomerSelect,
  },
  actions: {
    [GET_CUSTOMER_LIST]({ commit, state }) {
      const customerId = store.getters["authentication/customerId"];
      return new Promise((resolve, reject) => {
        return axios
          .get(
            `${process.env.VUE_APP_API_URL}/employee/${customerId}/customers`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              dataType: "json",
            }
          )
          .then(({ data }) => {
            commit(SET_CUSTOMERS, data);
            state.customers = data;
            state.showCutomerSelect = false;
            if (data.length > 1) {
              state.showCutomerSelect = true;
            }
            resolve(data);
          })
          .catch((error) => {
            error.handleGlobally && error.handleGlobally();
            reject(error);
          });
      });
    },
  },
  mutations: {
    [SET_CUSTOMERS](state, customers) {
      state.customers = customers;
    },
  },
};
