import axios from "../../service/api";
import {
  SET_DICTIONARY,
  SET_ACTIVE_LANGUAGE,
  GET_DICTIONARY_ACTIVE_LANGUAGE,
} from "../mutation-types";
import { GET_DICTIONARY } from "../action-types";

export const danish = { key: "Dansk", value: "da-dk" };
export const english = { key: "English", value: "en-gb" };
export const german = { key: "Deutsch", value: "de-de" };
export const languageOptions = [
  { text: danish.key },
  { text: english.key },
  { text: german.key },
];

export default {
  namespaced: true,
  state: {
    activeLanguage: null,
    dictionaryEntries: null,
  },
  getters: {
    activeLanguage: (state) => state.activeLanguage,
    dictionaryEntries: (state) => state.dictionaryEntries,
  },
  actions: {
    [GET_DICTIONARY_ACTIVE_LANGUAGE]() {
      return this.state.activeLanguage;
    },
    [GET_DICTIONARY]({ commit }, payload) {
      let culture = "";
      switch (payload.text) {
        case "Dansk":
          culture = danish.value;
          break;
        case "English":
          culture = english.value;
          break;
        case "Deutsch":
          culture = german.value;
          break;
        default: {
          culture = danish.value;
        }
      }

      commit(SET_ACTIVE_LANGUAGE, culture);

      return axios
        .get(`${process.env.VUE_APP_API_CULTURE}${culture}`)
        .then(({ data }) => {
          commit(SET_DICTIONARY, data);
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },
  },
  mutations: {
    [SET_ACTIVE_LANGUAGE](state, payload) {
      state.activeLanguage = payload;
    },
    [SET_DICTIONARY](state, payload) {
      state.dictionaryEntries = payload;
    },
  },
};
