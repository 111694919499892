<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    stroke="currentColor"
    viewBox="0 0 24 24"
    class="className"
  >
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(1 26)">
      <circle
        id="Ellipse_1"
        cx="11.5"
        cy="11.5"
        r="11.5"
        transform="translate(0 -25)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_5"
        y2="4.6"
        transform="translate(11.5 -18.1)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_6"
        x2="0.012"
        transform="translate(11.5 -8.9)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconIssue",
  props: {
    className: {
      type: String,
      default: null,
    },
  },
};
</script>
