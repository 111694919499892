<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 9.353 10.689"
    fill="currentColor"
    :title="title"
  >
    <path
      d="M4.008,0A1.34,1.34,0,0,0,2.672,1.336H1.336A1.34,1.34,0,0,0,0,2.672H9.353A1.34,1.34,0,0,0,8.017,1.336H6.681A1.34,1.34,0,0,0,5.344,0ZM1.336,4.008v6.427a.246.246,0,0,0,.254.254H7.776a.246.246,0,0,0,.254-.254V4.008H6.694V8.685a.668.668,0,0,1-1.336,0V4.008H4.022V8.685a.668.668,0,0,1-1.336,0V4.008H1.349Z"
    />
    <title>{{ title }}</title>
  </svg>
</template>

<script>
export default {
  name: "IconTrash",

  data() {
    return {
      title: "",
    };
  },
};
</script>
