<template>
  <span>
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-file"
    >
      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
      <polyline points="13 2 13 9 20 9" />
      <g>
        <circle r="8" cx="65%" cy="35%" stroke="none" :fill="fillColor" />
        <text
          :x="xValue"
          y="50%"
          :font-size="fontSize"
          font-weight="bold"
          fill="white"
          stroke="none"
        >
          {{ count }}
        </text>
      </g>
    </svg>
  </span>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const task = createNamespacedHelpers("task");
export default {
  name: "IconFile",
  props: {
    taskJobNumber: {
      type: String,
      default: "",
    },
    taskId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fileExists: false,
      count: "",
      type: "files",
      fontSize: 9,
      fillColor: "none",
      xValue: "42%",
    };
  },
  beforeMount() {
    this.activeFiles("file", this.taskId, this.taskJobNumber);
  },
  methods: {
    ...task.mapActions(["GET_TASK_FILES"]),
    activeFiles(type) {
      this.GET_TASK_FILES({
        type,
        taskId: this.taskId,
        jobNumber: this.taskJobNumber,
      }).then((data) => {
        if (data.length > 0) {
          this.filesExists = true;
          this.count = data.length;
          this.fillColor = "green";
          this.fontSize = 12;
          if (this.count < 10) {
            this.fontSize = 12;
            this.xValue = "55%";
          }
        }
      });
    },
  },
};
</script>
