<template>
  <div class="overlay">
    <div class="overlay__wrapper">
      <div class="overlay__header">
        <div v-if="overlayType === 'issue'" class="overlay__heading">
          {{ getDictionaryEntry("reportIssue.header") }}
        </div>
        <div v-if="overlayType === 'service'" class="overlay__heading">
          {{ getDictionaryEntry("reportService.header") }}
        </div>
        <div v-if="overlayType === 'control'" class="overlay__heading">
          {{ getDictionaryEntry("reportControl.header") }}
        </div>
        <div v-if="overlayType === 'task'" class="overlay__heading">
          {{ getDictionaryEntry("overlayTask.header") }}
        </div>
        <div v-if="overlayType === 'installation'" class="overlay__heading">
          {{ getDictionaryEntry("overlayInstallation.header") }}
        </div>
        <div
          v-if="overlayType === 'thermographyDetails'"
          class="overlay__heading"
        >
          {{ getDictionaryEntry("overlayThermography.header") }}
        </div>
        <div class="overlay__close" v-on:click="toggleOverlay">
          <IconClose />
        </div>
      </div>
      <div class="overlay__spinner" v-if="activeOverlay.content === null">
        <Spinner />
      </div>
      <div v-if="activeOverlay.content !== null">
        <div v-if="activeOverlay.type === 'issue'">
          <ReportIssue />
        </div>
        <div v-if="activeOverlay.type === 'service'">
          <ReportService />
        </div>
        <div v-if="activeOverlay.type === 'control'">
          <ReportControl />
        </div>
        <div v-if="activeOverlay.type === 'task'">
          <OverlayTask />
        </div>
        <div v-if="activeOverlay.type === 'installation'">
          <OverlayInstallation />
        </div>
        <div v-if="activeOverlay.type === 'thermographyDetails'">
          <OverlayThermography />
        </div>
        <div v-if="activeOverlay.type === 'file'">
          <OverlayFile @update="refreshGrid()" />
        </div>
        <div v-if="activeOverlay.type === 'news'">
          <OverlayNews />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "./icons/IconClose.vue";
import ReportIssue from "./ReportIssue.vue";
import ReportService from "./ReportService.vue";
import ReportControl from "./ReportControl.vue";
import Spinner from "./Spinner.vue";
import OverlayTask from "./OverlayTask.vue";
import OverlayInstallation from "./OverlayInstallation.vue";
import OverlayThermography from "./overlays/OverlayThermography.vue";
import OverlayNews from "./overlays/OverlayNews.vue";
import OverlayFile from "./overlays/OverlayFile.vue";
import { createNamespacedHelpers } from "vuex";
const overlay = createNamespacedHelpers("overlay");

export default {
  name: "Overlay",
  components: {
    IconClose,
    ReportIssue,
    ReportService,
    ReportControl,
    Spinner,
    OverlayTask,
    OverlayInstallation,
    OverlayThermography,
    OverlayFile,
    OverlayNews,
  },
  computed: {
    ...overlay.mapGetters(["activeOverlay"]),
    overlayType() {
      return this.$store.getters.overlayType;
    },
  },
  methods: {
    toggleOverlay() {
      this.$store.dispatch("toggleOverlay");
      document.getElementById("redrawButton").click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  overflow: scroll;

  @include tablet-portrait {
    width: 90rem;
  }
}

.overlay__wrapper {
  padding: 3.1rem 3.7rem 4.2rem;
}

.overlay__header {
  display: flex;
  justify-content: space-between;
}

.overlay__heading {
  font-size: 2.7rem;
  font-weight: var(--font-weight--semibold);
}

.overlay__close {
  width: 1.5rem;
  color: var(--color-black);

  &:hover {
    cursor: pointer;
  }
}

.overlay__spinner {
  width: 5rem;
  height: 5rem;
}
</style>
