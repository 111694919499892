<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="0.277778in"
    height="0.277778in"
    viewBox="0 0 25 25"
  >
    <path
      id="Imported Path"
      fill="none"
      stroke="black"
      stroke-width="1"
      d="M 14.36,21.81
           C 14.58,21.81 14.76,21.63 14.76,21.41
             14.76,21.41 14.76,4.57 14.76,4.57
             14.76,4.57 17.91,4.57 17.91,4.57
             17.91,4.57 17.91,21.41 17.91,21.41
             17.91,21.63 18.09,21.81 18.31,21.81
             18.53,21.81 18.71,21.63 18.71,21.41
             18.71,21.41 18.71,8.33 18.71,8.33
             18.71,8.33 22.07,8.33 22.07,8.33
             22.07,8.33 22.07,21.41 22.07,21.41
             22.07,21.63 22.25,21.81 22.47,21.81
             22.69,21.81 22.87,21.63 22.87,21.41
             22.87,21.41 22.87,7.93 22.87,7.93
             22.87,7.71 22.69,7.54 22.47,7.54
             22.47,7.54 18.71,7.54 18.71,7.54
             18.71,7.54 18.71,4.17 18.71,4.17
             18.71,3.95 18.53,3.77 18.31,3.77
             18.31,3.77 14.36,3.77 14.36,3.77
             14.14,3.77 13.96,3.95 13.96,4.17
             13.96,4.17 13.96,21.41 13.96,21.41
             13.96,21.63 14.14,21.81 14.36,21.81 Z
           M 4.12,21.81
           C 4.34,21.81 4.52,21.63 4.52,21.41
             4.52,21.41 4.52,12.10 4.52,12.10
             4.52,12.10 7.89,12.10 7.89,12.10
             7.89,12.10 7.89,21.41 7.89,21.41
             7.89,21.63 8.07,21.81 8.29,21.81
             8.51,21.81 8.69,21.63 8.69,21.41
             8.69,21.41 8.69,15.87 8.69,15.87
             8.69,15.87 11.84,15.87 11.84,15.87
             11.84,15.87 11.84,21.41 11.84,21.41
             11.84,21.63 12.01,21.81 12.23,21.81
             12.45,21.81 12.63,21.63 12.63,21.41
             12.63,21.41 12.63,15.47 12.63,15.47
             12.63,15.25 12.45,15.07 12.23,15.07
             12.23,15.07 8.69,15.07 8.69,15.07
             8.69,15.07 8.69,11.70 8.69,11.70
             8.69,11.48 8.51,11.30 8.29,11.30
             8.29,11.30 4.12,11.30 4.12,11.30
             3.90,11.30 3.72,11.48 3.72,11.70
             3.72,11.70 3.72,21.41 3.72,21.41
             3.72,21.63 3.90,21.81 4.12,21.81 Z
           M 24.60,22.61
           C 24.60,22.61 2.39,22.61 2.39,22.61
             2.39,22.61 2.39,0.40 2.39,0.40
             2.39,0.18 2.22,0.00 1.99,0.00
             1.77,0.00 1.60,0.18 1.60,0.40
             1.60,0.40 1.60,3.77 1.60,3.77
             1.60,3.77 0.40,3.77 0.40,3.77
             0.18,3.77 0.00,3.95 0.00,4.17
             0.00,4.39 0.18,4.57 0.40,4.57
             0.40,4.57 1.60,4.57 1.60,4.57
             1.60,4.57 1.60,7.54 1.60,7.54
             1.60,7.54 0.40,7.54 0.40,7.54
             0.18,7.54 0.00,7.71 0.00,7.93
             0.00,8.15 0.18,8.33 0.40,8.33
             0.40,8.33 1.60,8.33 1.60,8.33
             1.60,8.33 1.60,11.30 1.60,11.30
             1.60,11.30 0.40,11.30 0.40,11.30
             0.18,11.30 0.00,11.48 0.00,11.70
             0.00,11.92 0.18,12.10 0.40,12.10
             0.40,12.10 1.60,12.10 1.60,12.10
             1.60,12.10 1.60,15.07 1.60,15.07
             1.60,15.07 0.40,15.07 0.40,15.07
             0.18,15.07 0.00,15.25 0.00,15.47
             0.00,15.69 0.18,15.87 0.40,15.87
             0.40,15.87 1.60,15.87 1.60,15.87
             1.60,15.87 1.60,18.84 1.60,18.84
             1.60,18.84 0.40,18.84 0.40,18.84
             0.18,18.84 0.00,19.02 0.00,19.24
             0.00,19.46 0.18,19.64 0.40,19.64
             0.40,19.64 1.60,19.64 1.60,19.64
             1.60,19.64 1.60,23.01 1.60,23.01
             1.60,23.23 1.77,23.40 1.99,23.40
             1.99,23.40 7.89,23.40 7.89,23.40
             7.89,23.40 7.89,24.60 7.89,24.60
             7.89,24.82 8.07,25.00 8.29,25.00
             8.51,25.00 8.69,24.82 8.69,24.60
             8.69,24.60 8.69,23.40 8.69,23.40
             8.69,23.40 17.91,23.40 17.91,23.40
             17.91,23.40 17.91,24.60 17.91,24.60
             17.91,24.82 18.09,25.00 18.31,25.00
             18.53,25.00 18.71,24.82 18.71,24.60
             18.71,24.60 18.71,23.40 18.71,23.40
             18.71,23.40 24.60,23.40 24.60,23.40
             24.82,23.40 25.00,23.23 25.00,23.01
             25.00,22.79 24.82,22.61 24.60,22.61 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStatistics",
};
</script>
