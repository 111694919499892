<template>
  <div>
    <transition name="backdrop">
      <div v-show="isOpen" class="modal__backdrop" @click="toggleModal">
        <transition name="modal">
          <div v-show="isOpen" class="modal">
            <div class="modal__container" @click.stop="">
              <div class="modal__header">
                <h2 v-if="heading" class="modal__header-heading">
                  {{ heading }}
                </h2>
                <slot name="header" />
                <div ref="modalClose" class="modal__close" @click="toggleModal">
                  <IconClose />
                </div>
              </div>

              <div
                class="modal__body"
                :style="style"
                :class="{ 'modal__body--max-height': maxHeight }"
              >
                <slot name="body" />
                <div @click="handleSubmit">
                  <Button v-if="submit">{{ submitName }}</Button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <div @click="toggleModal">
      <slot name="trigger" />
    </div>
  </div>
</template>

<script>
import IconClose from "./icons/IconClose.vue";
import Button from "./Button.vue";

export default {
  name: "Modal",
  components: {
    IconClose,
    Button,
  },
  props: {
    closeModal: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    trigger: {
      type: String,
      default: "Knap",
    },
    triggerDisabled: {
      type: Boolean,
      default: false,
    },
    triggerSecondary: {
      type: Boolean,
      default: false,
    },
    triggerUpload: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    submitName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    style() {
      return {
        "--modal-body--max-height": `${this.maxHeight}rem`,
      };
    },
  },
  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen;
      this.$emit("toggle", this.isOpen);
    },
    handleSubmit() {
      this.$emit("handleSubmit");
      this.toggleModal();
    },
  },
  watch: {
    closeModal(value) {
      if (value) {
        this.$refs.modalClose.click();
        this.$emit("modalClosed");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  z-index: var(--z-index--modal--backdrop);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: var(--z-index--modal);
}

.modal__container {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);

  @include tablet-portrait {
    width: 90%;
    max-width: 98.4rem;
    margin: 0px auto;
  }
}

.modal__header {
  height: 8.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.8rem;
  border-bottom: var(--border--lg) solid var(--color-grey--light);
}

.modal__header-heading {
  font-size: 1.8rem;
  font-weight: var(--font-weight--normal);
}

.modal__close {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modal__body {
  padding: 2.8rem 1.7rem 3.4rem;

  @include tablet-portrait {
    padding: 2.8rem 7.2rem 5.4rem;
  }

  &--max-height {
    max-height: var(--modal-body--max-height);
    overflow-y: auto;
  }
}

.modal__footer {
  padding: 0 7.2rem;
}

.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.4s ease;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s ease-out;
}

.modal-enter-from,
.modal-leave-to {
  transform: scale(0.9);
  transform: translateY(-40%) translateX(-50%);
}
</style>
