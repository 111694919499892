<template>
  <div :style="style" class="spinner"></div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    size: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    style() {
      return {
        "--spinner-size": `${this.size}rem`,
        "--spinner-size--half": `${this.size * 0.5}rem`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - (var(--spinner-size) * 0.5));
  left: calc(50% - (var(--spinner-size) * 0.5));
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-radius: 50%;
  border-top: var(--border--lg) solid var(--color-primary);
  border-right: var(--border--md) solid transparent;
  animation: spinner 0.6s linear infinite;
}
</style>
