import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dictionary from "./mixins/dictionary";
import columnDefinitions from "./mixins/columnDefinitions";
import "./styles/breakpoints.scss";

const app = createApp(App);

app.mixin(dictionary);
app.mixin(columnDefinitions);

app.use(store).use(router).mount("#app");
