<template>
  <div v-if="activeLocation">
    <ModalDeleteFileInstallation />
    <TransitionFade>
      <TableContainer
        :table-type="'INSTALLATION'"
        :table-page-size="10"
        :table-name="getDictionaryEntry('installations.table.headerText')"
        :is-dashboard="false"
        :table-container-footer="false"
      />
    </TransitionFade>
    <div><ModalUploadFiles :isInstallation="true" /></div>
  </div>
</template>

<script>
import ModalDeleteFileInstallation from "../components/modals/confirms/ModalDeleteFileInstallation.vue";
import ModalUploadFiles from "../components/ModalUploadFiles.vue";
import TableContainer from "../components/TableContainer.vue";
import TransitionFade from "../components/TransitionFade.vue";
import { installationRowModel } from "../helpers/ag-grid/rowmodels/installationRowModel.js";
import { createNamespacedHelpers } from "vuex";
const installation = createNamespacedHelpers("installation");
const location = createNamespacedHelpers("location");
const table = createNamespacedHelpers("table");

export default {
  name: "Enheder",
  components: {
    ModalDeleteFileInstallation,
    ModalUploadFiles,
    TableContainer,
    TransitionFade,
  },
  computed: {
    ...installation.mapGetters(["installations"]),
    ...location.mapGetters(["activeLocation"]),
  },
  created() {
    this.UPDATE_ROWMODEL(installationRowModel);
    if (this.activeLocation) {
      this.SET_ACTIVE_LOCATION(this.activeLocation);
    }
  },
  methods: {
    ...installation.mapActions(["GET_INSTALLATION_LIST"]),
    ...location.mapMutations(["SET_ACTIVE_LOCATION"]),
    ...table.mapActions(["UPDATE_ROWMODEL"]),
  },
};
</script>

<style lang="scss" scoped>
.installation__header {
  height: 4.5rem;
  border-radius: var(--border-radius);
  background-color: var(--color-installation--header);
  display: flex;
  align-items: center;
}

.installation__header-text {
  font-size: 1.8rem;
  font-weight: var(--font-weight--semibold);
  padding: 0 1.7rem;
}
</style>
