<template>
  <div class="overlay-installation">
    <div class="overlay-installation__installation">
      <strong
        >{{ getDictionaryEntry("overlayInstallation.labelInstallation") }}:
      </strong>
      <span v-if="activeInstallation">{{ activeInstallation.name }}</span>
    </div>

    <Accordion>
      <AccordionItem
        v-if="installationIssuesNoAction"
        :disabled="!installationIssuesNoAction.length > 0"
      >
        <template #accordion-header>
          <div class="overlay-installation__accordion-header">
            <div class="overlay-installation__accordion-header-text">
              {{
                getDictionaryEntry(
                  "overlayInstallation.accordionItem.activeIssues.title"
                )
              }}
            </div>
            <div class="overlay-installation__accordion-header-status">
              <LabelStatus :color="'red'" :secondary="true">
                {{
                  getDictionaryEntry(
                    "overlayInstallation.accordionItem.activeIssues.status"
                  )
                }}
              </LabelStatus>
            </div>
          </div>
        </template>
        <template #accordion-content>
          <div class="overlay-installation__table">
            <TableSimple
              v-if="installationIssuesNoAction"
              :table-data="installationIssuesNoAction"
              :table-type="'OVERLAY_INSTALLATION_ISSUE_NO_ACTION'"
              :simple-header="true"
            />
          </div>
        </template>
      </AccordionItem>
      <AccordionItem
        v-if="installationIssuesCompleted"
        :disabled="!installationIssuesCompleted.length > 0"
      >
        <template #accordion-header>
          <div class="overlay-installation__accordion-header-text">
            {{
              getDictionaryEntry(
                "overlayInstallation.accordionItem.completedIssues.title"
              )
            }}
          </div>
        </template>
        <template #accordion-content>
          <div class="overlay-installation__table">
            <TableSimple
              v-if="installationIssuesCompleted"
              :table-data="installationIssuesCompleted"
              :table-type="'OVERLAY_INSTALLATION_ISSUE_COMPLETED'"
              :simple-header="true"
            />
          </div>
        </template>
      </AccordionItem>
      <AccordionItem
        v-if="installationTasksPlanned"
        :disabled="!installationTasksPlanned.length > 0"
      >
        <template #accordion-header>
          <div class="overlay-installation__accordion-header">
            <div class="overlay-installation__accordion-header-text">
              {{
                getDictionaryEntry(
                  "overlayInstallation.accordionItem.activeTasks.title"
                )
              }}
            </div>
            <div class="overlay-installation__accordion-header-status">
              <LabelStatus :color="'yellow'" :secondary="true">
                {{
                  getDictionaryEntry(
                    "overlayInstallation.accordionItem.activeTasks.status"
                  )
                }}
              </LabelStatus>
            </div>
          </div>
        </template>
        <template #accordion-content>
          <div class="overlay-installation__table">
            <TableSimple
              v-if="installationTasksPlanned"
              :table-data="installationTasksPlanned"
              :table-type="'OVERLAY_INSTALLATION_TASK_PLANNED'"
              :simple-header="true"
            />
          </div>
        </template>
      </AccordionItem>
      <AccordionItem
        v-if="installationTasksCompleted"
        :disabled="!installationTasksCompleted.length > 0"
      >
        <template #accordion-header
          ><div class="overlay-installation__accordion-header-text">
            {{
              getDictionaryEntry(
                "overlayInstallation.accordionItem.completedTasks.title"
              )
            }}
          </div>
        </template>
        <template #accordion-content>
          <div class="overlay-installation__table">
            <TableSimple
              v-if="installationTasksCompleted"
              :table-data="installationTasksCompleted"
              :table-type="'OVERLAY_INSTALLATION_TASK_COMPLETED'"
              :simple-header="true"
            />
          </div>
        </template>
      </AccordionItem>
    </Accordion>
  </div>
</template>

<script>
import TableSimple from "./TableSimple.vue";
import Accordion from "./Accordion.vue";
import AccordionItem from "./AccordionItem.vue";
import LabelStatus from "./LabelStatus.vue";
import { createNamespacedHelpers } from "vuex";
const installation = createNamespacedHelpers("installation");

export default {
  name: "OverlayTask",
  components: {
    TableSimple,
    Accordion,
    AccordionItem,
    LabelStatus,
  },
  props: {
    installationName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      issueNoActionColumnDefs: null,
      issueCompletedColumnDefs: null,
      taskPlannedColumnDefs: null,
      taskCompletedColumnDefs: null,
    };
  },
  computed: {
    ...installation.mapGetters([
      "activeInstallation",
      "installationIssuesCompleted",
      "installationIssuesNoAction",
      "installationTasksPlanned",
      "installationTasksCompleted",
    ]),
  },
  beforeMount() {
    this.GET_INSTALLATION_ISSUE_LIST({
      status: "NoAction",
      installationReferenceCode: this.activeInstallation.code,
    });
    this.GET_INSTALLATION_ISSUE_LIST({
      status: "Completed",
      installationReferenceCode: this.activeInstallation.code,
    });
    this.GET_INSTALLATION_TASK_LIST({
      status: "Planned",
      installationReferenceCode: this.activeInstallation.code,
    });
    this.GET_INSTALLATION_TASK_LIST({
      status: "Completed",
      installationReferenceCode: this.activeInstallation.code,
    });
  },
  methods: {
    ...installation.mapActions([
      "GET_INSTALLATION_ISSUE_LIST",
      "GET_INSTALLATION_TASK_LIST",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";

.overlay-installation__installation {
  font-size: 1.3rem;
  padding-top: 0.7rem;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  border-bottom: var(--border-divider);
}

.overlay-installation__table {
  max-height: 54.7rem;
  overflow-y: auto;
}

.overlay-installation__accordion-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.overlay-installation__accordion-header-text {
  font-size: 1.6rem;
  font-weight: var(--font-weight--semibold);
}

.overlay-installation__accordion-header-status {
  display: none;

  @include tablet-portrait {
    display: block;
  }
}
</style>
