<template>
  <a :href="fileUrl">{{ fileName }} </a>
</template>

<script>
export default {
  name: "ThermographyReport",
  data() {
    return {
      fileName: null,
      fileUrl: null,
    };
  },
  beforeMount() {
    this.fileName = this.params.data.fileName;
    this.fileUrl = this.params.data.fileUrl;
  },
};
</script>
